.product-param {
  &__name {
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.55;
    text-transform: uppercase;
  }
}

@include respond-up('large') {
  .product-param {
    padding: 0 var(--spacer-large) 127px 80/1675*100vw;

    &__title {
      padding-bottom: 49px;
    }

    &__item {
      grid-template-columns: 461/1675*100vw 1fr;
    }

    &__name {
      padding-right: var(--spacer-large);
    }
  }
}

@include respond-up('medium') {
  .product-param {
    &__item {
      display: grid;
      padding: 23px 0;
      border-top:  1px solid var(--border-color);

      &:last-of-type {
        border-bottom: 1px solid var(--border-color);
      }
    }

    &__name {
      padding-top: 4px;
    }
  }
}

@include respond('medium') {
  .product-param {
    padding-bottom: 70px;

    &__title {
      padding-bottom: 40px;
    }

    &__item {
      grid-template-columns: 364/768*100vw 1fr;
    }

    &__name {
      padding-right: var(--spacer-medium);
    }
  }
}

@include respond-down('small') {
  .product-param {
    padding: 0 var(--spacer-small) 50px;

    &__title {
      padding-bottom: 22px;
    }

    &__params {
      margin: 0 calc(0px - var(--spacer-small));
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      display: flex;

      &::before, &::after {
        content: '';
        height: 10px;
        flex: 0 0 var(--spacer-small);
      }

      &:last-of-type {
        .product-param__name, .product-param__description {
          border-bottom: 1px solid var(--border-color);
        }
      }
    }

    &__name {
      padding: 22px 35/375*100vw 22px 0;
      display: block;
      flex: 0 0 243/375*100vw;
      border-top:  1px solid var(--border-color);
    }

    &__description {
      display: block;
      flex: 0 0 343/375*100vw;
      padding: 18px 0 19px;
      border-top:  1px solid var(--border-color);
    }
  }
}