.slider {
  position: relative;
  scroll-behavior: revert;

  &__inner {
    vertical-align: top;
    overflow: hidden;
    width: 100%;
  }

  &__slide {
    position: relative;
    overflow: hidden;
    transform: translateX(100%);
    transition-duration: 0s;
    animation-duration: 0s;

    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .slider__image {
        transform: translateX(-90%) scale(1.2);
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 2;
      transition: transform 1.3s;

      .slider__image {
        transform: translateX(0) scale(1);
        transition: transform 1.3s;
      }
    }

    &.animate_out {
      transform: translateX(0);
      z-index: 1;

      .slider__image {
        transform: translateX(-30%);
        transition: transform 1.3s;
      }
    }
  }

  &__filter {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background:linear-gradient(358.73deg, rgba(7, 20, 51, 0.26) 0, rgba(7, 20, 51, 0.12) 20%, rgba(7, 20, 51, 0) 100%);
  }

  &__picture {
    display: block;
    font-size: 0;
  }

  &__image {
    object-fit: cover;
    max-width: none;
    transition: transform 0s;
  }

  &__label {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
  }

  &__strip {
    height: 1px;
    background-color: var(--slide-strip-color);
    overflow: hidden;
  }

  &__strip-filling {
    background-color: #fff;
    height: 100%;
    transition: width .9s;
  }
}

@include respond-up('large') {
  .slider {
    &__label {
      bottom: 40px;
      right: 55px;
    }

    &__image {
      width: 100%;
      height: 699/1675*100vw;
      object-fit: cover;
    }

    &__strip {
      width: 160px;
      margin-right: 30px;
    }
  }
}


@include respond('medium') {
  .slider {
    &__label {
      bottom: 31px;
      right: 24px;
    }

    &__image {
     width: 100%;
     height: 320/768*100vw;
     object-fit: cover;
    }
  }
}


@include respond-down('medium') {
  .slider {
    &__strip {
      width: 100px;
      margin-right: 23px;
    }
  }
}


@include respond-down('small') {
  .slider {
    padding-bottom: 50px;

    &__label {
      bottom: 0;
      right: 0;
    }

    &__image {
      width: 100%;
      height: 159/375*100vw;
      object-fit: cover;
    }

    &__slide {
      position: relative;
    }

    &__strip {
      background-color: var(--slider-line-bg-color);
    }

    &__strip-filling {
      background-color: var(--slider-line-color);
    }
  }
}

@include respond-down('x-small') {
  .slider {
    &__strip {
      width: 80px;
      margin-right: 30px;
    }
  }
}