.cover-info {
  display: flex;
  background-color: white;
  cursor: pointer;

  &__icon {
    display: flex;
  }

  &__text {
    line-height: 1.35;
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .cover-info {
    padding: 85px 164/1675*100vw 86px;
  }
}

@include respond-up('medium') {
  .cover-info {
    flex-direction: column;
    align-items: center;

    &__text {
      text-align: center;
      margin-top: 24px;
      font-size: 13px;

      &_mobile {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .cover-info {
    padding: 53px 124/768*100vw 54px;
  }
}

@include respond-down('small') {
  .cover-info {
    padding: 28px 16px;
    justify-content: flex-end;

    &__icon {
      margin-right: 12px;
    }

    &__text {
      flex: 0 0 156/375*100vw;
      font-size: 12px;
      text-align: left;

      &_desktop {
        display: none;
      }
    }
  }
}