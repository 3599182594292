.error-page {
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
  }

  &__subtitle {
    text-align: center;
  }
}

@include respond-up('large') {
  .error-page {
    padding: 150px var(--spacer-large) 125px;

    &__picture {
      padding-bottom: 100px;
      width: 705/1675*100vw;
    }

    &__title {
      padding-bottom: 20px;
    }

    &__subtitle {
      padding-bottom: 35px;
    }
  }
}

@include respond('medium') {
  .error-page {
    padding: 94px var(--spacer-medium) 90px;

    &__picture {
      padding-bottom: 70px;
      width: 444/768*100vw;
    }

    &__subtitle {
      padding-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .error-page {
    &__title {
      padding-bottom: 15px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    padding: 80px var(--spacer-small) 60px;

    &__picture {
      padding-bottom: 55px;
      width: 316/375*100vw;
    }

    &__subtitle {
      padding-bottom: 25px;
    }
  }
}