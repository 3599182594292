@include respond-up('large') {
  .catalog-index {
    padding-bottom: 125px;
  }
}

@include respond('medium') {
  .catalog-index {
    padding-bottom: 70px;
  }
}