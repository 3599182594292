.print-container {
  margin-left: 8.8mm;
  margin-right: 8.8mm;
  font-family: var(--font);

  &__h {
    font-family: var(--h-font);
  }

  &__bold {
    font-weight: 500;
  }

  &__uppercase {
    text-transform: uppercase;
  }

  &__black {
    color: var(--text-color-dark);
  }

  &__grey {
    color: var(--text-color);
  }

  &__size70 {
    font-size: 35pt;
  }

  &__size30 {
    font-size: 15pt;
  }

  &__size24 {
    font-size: 12pt;
  }

  &__size22 {
    font-size: 11pt;
  }

  &__size19 {
    font-size: 9.5pt;
  }

  &__size18 {
    font-size: 9pt;
  }

  &__size16 {
    font-size: 8px;
  }

  &__size15 {
    font-size: 7.5pt;
  }

  &__size14 {
    font-size: 7pt;
  }

  &__size12 {
    font-size: 6pt;
  }
}