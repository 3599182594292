.projects-item {
  width: 100%;

  &__image-container {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__link-list {
    display: flex;
  }

  &__link {
    text-transform: uppercase;
    color: #fff;
    font-family: var(--h-font);
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
  }

  &__link-text {
    white-space: nowrap;
  }

  &__name-container {
    padding: 10px 0;
    margin: -10px 0;
    display: inline-flex;
    flex-direction: column;
  }

  &__name {
    text-transform: uppercase;
    font-weight: 500;
    font-family: var(--h-font);
    line-height: 1.55;
  }

  &__short-description {
    color: var(--text-color);
    line-height: 1.75;
  }

  &__description {
    display: none;
  }
}

@include respond-up('large') {
  .projects-item {
    &__content-container {
      position: relative;
      margin-bottom: 35px;

      &:hover + .projects-item__offer-container {
        z-index: 1;

        .projects-item {
          &__link-text {
            transform: translateY(0);
          }

          &__link {
            pointer-events: auto;
          }
        }
      }

      &:hover {
        .projects-item {
          &__link-list {
            opacity: 1;
          }

          &__link-text {
            transform: translateY(0);
          }

          &__link-list {
            pointer-events: auto;
          }

          &__content {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &__content {
      position: relative;
      display: flex;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180.8deg, rgba(7, 20, 51, 0.73) 1.79%, rgba(7, 20, 51, 0) 99%);
        transform: matrix(1, 0, 0, -1, 0, 0);
        opacity: 0;
        transition: var(--default-transition);
      }
    }

    &__offer-container {
      position: relative;
    }

    &__link-list {
      pointer-events: none;
      margin: -12px;
      position: absolute;
      z-index: 10;
      left: 40px;
      bottom: 35px;
      right: 40px;
      flex-wrap: wrap;
    }

    &__link {
      padding: 5px 12px;
      display: inline-block;
    }

    &__link-text-animation-wrapper {
      display: inline-block;
      overflow: hidden;
    }

    &__link-text {
      display: inline-block;
      transform: translateY(10px);
      transition: transform .2s .25s var(--default-bezier);
    }

    &__name {
      margin-bottom: 10px;
      font-size: 22px;
    }

    &__short-description {
      min-height: 28px;
    }

    &__description {
      display: block;
      margin-bottom: 75px;
    }
  }
}

@include respond('medium') {
  .projects-item {
    &__content-container {
      position: relative;
      margin-bottom: 10px;
    }

    &__content {
      display: flex;
    }

    &__offer-container {
      min-height: 130px;
    }

    &__link-list {
      flex-wrap: wrap;
      margin: 0 -2px 5px;
      padding-top: 10px;
    }

    &__link {
      margin: 0 2px 5px;
      padding: 5px 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__link-text {
      text-transform: uppercase;
      font-size: 9px;
      color: var(--tag-color);
    }

    &__name {
      margin-bottom: 4px;
      font-size: 15px;
    }

    &__short-description {
      font-size: 14px;
      min-height: 24px;
    }
  }
}

@include respond-down('small') {
  .projects-item {
    &__content-container {
      position: relative;
      margin-bottom: 8px;
    }

    &__content {
      display: flex;
    }

    &__link-list {
      flex-wrap: wrap;
      margin: 0 -2px 5px;
      padding-top: 8px;
    }

    &__link {
      margin: 0 2px 5px;
      padding: 5px 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__link-text {
      text-transform: uppercase;
      font-size: 9px;
      color: var(--tag-color);
    }

    &__name {
      margin-bottom: 4px;
      font-size: 14px;
    }

    &__short-description {
      font-size: 14px;
    }
  }
}