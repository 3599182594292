.policy {
  font-size: 13px;
  line-height: 1.69;
  color: var(--text-color);
  text-align: center;

  &__link {
    color: var(--text-color-dark);
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -3px;
      pointer-events: none;
      width: 100%;
      height: 1px;
      background-color: var(--text-color-dark);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }
  }
}

@include respond-up('large') {
  .policy {
    &__link {
      &:hover {
        &::before {
          left: 0;
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .3s var(--default-bezier);
        }
      }
    }
  }
}