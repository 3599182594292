.arrows {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &_without-count {
    .arrows {
      &__counter {
        display: none !important;
      }
    }
  }

  &_dark {
    .arrows {
      &__link {
        &::before {
          border: 1px solid var(--border-color);
        }

        .arrow-svg {
          &__path {
            stroke: var(--text-color-dark);
          }
        }
      }

      &__counter {
        color: var(--text-color-dark);
      }
    }
  }

  &__link {
    margin-right: 20px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px;

    &::before {
      content: '';
      position: absolute;
      top: -7px;
      border: 1px solid var(--slide-tag-color);
      border-radius: 50%;
      width: 47px;
      height: 47px;
      box-sizing: border-box;
      transform: scale(0);
      transform-origin: center;
      opacity: 0;
      transition: transform .3s, opacity .3s;
    }

    &:hover {
      &::before {
        transition: transform .8s, opacity .8s;
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &__counter {
    display: inline-block !important;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
    min-width: 62px;
  }

  &__delimiter {
    margin-right: 2px;
  }

  &__link {
    .arrow-svg {
      &__path {
        stroke: white;
      }
    }
  }

  &__counter {
      color: #FFFFFF;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


@include respond-up('large') {
  .arrows {
    &__link {
      &:last-of-type {
        margin-right: 0;
        margin-left: 20px;

        &::before {
          left: -5px;
        }
      }

      &:first-of-type {
        &::before {
          left: -7px;
        }
      }

      &:hover {
        &::before {
          transition: transform .8s, opacity .8s;
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    &__counter {
      font-size: 20px;
    }

    &__current-index {
      min-width: 30px;
      display: inline-block;
    }

    &__delimiter {
      font-size: 15px;
    }

    &__counter-all {
      font-size: 15px;
    }
  }
}

@include respond-up('medium') {
  .arrows {
    &_without-count {
      column-gap: 5px;
      .arrows {
        &__link {
          margin: 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .arrows {
    &__link {
    &:last-of-type {
      margin-right: 0;
      margin-left: 20px;

      &::before {
        left: -7px;
      }
    }

    &:first-of-type {
      &::before {
        left: -8px;
      }
    }

    &:hover {
      &::before {
        transition: transform .8s, opacity .8s;
        transform: scale(1);
        opacity: 1;
      }
    }
  }
    &__counter {
      font-size: 17px;
    }

    &__delimiter {
      font-size: 13px;
    }

    &__counter-all {
      font-size: 13px;
    }

    &__icon {
      width: 11px;
    }
  }
}


@include respond-down('small') {
  .arrows {

    &__link {
      .arrow-svg {
        &__path {
          stroke: var(--text-color-dark);
        }
      }

      &::before {
        border-color: var(--slide-strip-filling-color-dark);
      }

      &:last-of-type {
        &::before {
          top: -3px;
          left: -4px;
          width: 40px;
          height: 40px;
        }
      }

      &:first-of-type {
        &::before {
          top: -3px;
          left: -6px;
          width: 40px;
          height: 40px;
        }
      }
    }

    &__counter {
      color: var(--text-color-dark);
    }
  }
}