.project-awards {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__type {
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .project-awards {
    max-width: 430px;
    margin-top: 60px;

    &__item {
      column-gap: 25px;
    }

    &__picture {
      width: 80px;
      height: 80px;
    }
  }
}

@include respond-up('medium') {
  .project-awards {
    &__list {
      row-gap: 6px;
    }

    &__item {
      align-items: center;
    }

    &__text-wrap {
      row-gap: 4px;
    }
  }
}

@include respond('medium') {
  .project-awards {
    max-width: 465px;

    &__item {
      column-gap: 20px;
    }

    &__picture {
      width: 70px;
      height: 70px;
    }
  }
}

@include respond-down('medium') {
  .project-awards {
    margin-top: 30px;
  }
}

@include respond-down('small') {
  .project-awards {
    &__list {
      row-gap: 12px;
    }

    &__item {
      column-gap: 16px;
      align-items: flex-start;
    }

    &__picture {
      width: 52px;
      height: 52px;
    }

    &__text-wrap {
      row-gap: 2px;
    }
  }
}