.feedback {
  display: flex;
  background-color: var(--background-color);
  position: relative;

  &__title-line-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__text {
    color: var(--text-color);
  }

  &__form {
    position: static;
  }
}

@include respond-up('large') {
  .feedback {
    padding: 107px 321/1675*100vw 125px 157/1675*100vw;
    justify-content: space-between;

    &__text-block {
      width: 457/1675*100vw;
    }

    &__title {
      line-height: 1.38;
      padding-bottom: 21px;
      padding-top: 18px;
    }

    &__text {
      font-size: 19px;
      line-height: 1.84;
    }

    &__form-block {
      width: 551/1675*100vw;
    }

    &__form {
      .recall-form__fields {
        margin-bottom: 60px;
      }

      .recall-form__field {
        &:first-of-type {
          margin-bottom: 52px;
        }
      }

      .recall-form__button {
        margin-bottom: 30px;
        margin-right: 10px;
      }
    }
  }
}

@include respond('medium') {
  .feedback {
    padding: 54px var(--spacer-medium) 70px;
    justify-content: space-between;

    &__text-block {
      width: 281/768*100vw;
    }

    &__title {
      padding-bottom: 15px;
      padding-top: 16px;
    }

    &__text {
      font-size: 15px;
    }

    &__form-block {
      width: 346/768*100vw;
    }


    &__form {
      .recall-form__fields {
        margin-bottom: 40px;
      }

      .recall-form__field {
        &:first-of-type {
          margin-bottom: 34px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .feedback {
    &__title {
      line-height: 1.55;
    }

    &__text {
      line-height: 1.75;
    }

    &__form {
      .recall-form__button {
        margin-bottom: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .feedback {
    padding: 50px var(--spacer-small);
    flex-direction: column;

    &__text-block {
      padding-bottom: 14px;
    }

    &__title {
      padding-bottom: 15px;
    }

    &__text {
      font-size: 15px;
    }

    &__form-block {
    }

    &__form {
      .recall-form__fields {
        margin-bottom: 30px;
      }

      .recall-form__field {
        &:first-of-type {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@include respond-down('x-small') {
  .feedback {
    padding: 50px var(--spacer-smaller);
  }
}

@media print{
  .feedback {
    display: none;
  }
}
