.history-hero {
  border-top: 1px solid var(--border-color);

  &__wrap {
    display: flex;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__quote {
    display: flex;
    align-items: flex-start;
  }

  &__quote-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__quote-text {
    border-left: 1px solid var(--border-color);
  }

  &__position {
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .history-hero {
    margin-top: 75px;
    padding-top: 80px;
    padding-bottom: 100px;

    &__wrap {
      column-gap: calc(160/1675 * 100vw);
    }

    &__picture {
      width: calc(413/1675 * 100vw);
      height: calc((413/1675 * 100vw) * 430/413);
    }

    &__quote {
      column-gap: 30px;
      padding-top: 25px;
    }

    &__quote-icon {
      flex: 0 0 30px;
      height: 21px;
    }

    &__quote-text {
      padding-left: 40px;
      width: calc(695/1675 * 100vw);
    }

    &__person-block {
      margin-top: 65px;
      padding-left: 100px;
    }

    &__position {
      margin-top: 8px;
    }
  }
}

@include respond('medium') {
  .history-hero {
    margin-top: 30px;
    padding-top: 45px;
    padding-bottom: 50px;

    &__wrap {
      column-gap: 35px;
    }

    &__picture {
      width: calc(190/768 * 100vw);
      height: calc((190/768 * 100vw) * 198/190);
    }

    &__quote {
      column-gap: 27px;
    }

    &__quote-icon {
      flex: 0 0 27px;
      height: 19px;
    }

    &__quote-text {
      padding-left: 28px;
    }

    &__person-block {
      margin-top: 35px;
      padding-left: 77px;
    }
  }
}

@include respond-down('medium') {
  .history-hero {
    &__position {
      margin-top: 6px;
    }
  }
}

@include respond-down('small') {
  .history-hero {
    margin-top: 20px;
    padding-top: 30px;
    padding-bottom: 30px;

    &__wrap {
      flex-direction: column;
      row-gap: 35px;
    }

    &__picture {
      width: calc(152/375 * 100vw);
      height: calc((152/375 * 100vw) * 158/152);
    }

    &__quote {
      column-gap: 21px;
    }

    &__quote-icon {
      flex: 0 0 27px;
      height: 18px;
    }

    &__quote-text {
      padding-left: 20px;
    }

    &__person-block {
      margin-top: 25px;
      padding-left: 68px;
    }
  }
}