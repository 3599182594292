.photos-input {
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
  }

  &__text {
    line-height: 1.35;
    text-align: center;
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .photos-input {
    &__info {
      padding: 40px 120px;
    }

    &__files {
      margin-top: 50px;

      ul.files-list {
        grid-gap: 25px;
      }
    }
  }
}

@include respond-up('medium') {
  .photos-input {
    &__text {
      margin-top: 20px;
      font-size: 13px;

      &_mobile {
        display: none;
      }
    }

    &__files {
      ul.files-list {
        grid-template-columns: repeat(auto-fill, 185px);

        li {
          img {
            height: 127px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .photos-input {
    &__info {
      padding: 24px 64px;
    }

    &__files {
      margin-top: 40px;

      ul.files-list {
        grid-gap: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .photos-input {
    &__info {
      padding: 14px 24px 13px;
    }

    &__text {
      margin-top: 16px;
      font-size: 12px;

      &_desktop {
        display: none;
      }
    }

    &__files {
      margin-top: 30px;

      ul.files-list {
        grid-gap: 15px;
      }
    }
  }
}