.reviews-form {
  background-color: var(--background-color);

  &__columns {
    width: 100%;
  }

  &__column {
    width: 100%;
  }

  &__answer-field {
    display: flex;

    .radio-list {
      & + label {
        padding: 0;
      }
    }
  }

  &__success {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
  }

  &__success-title {
    text-align: center;
  }

  &__success-subtitle {
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .reviews-form {
    &__data {
      padding: 125px 212/1675*100vw 125px 157/1675*100vw;
    }

    &__text-block {
      margin-bottom: 62px;
    }

    &__title {
      line-height: 1.5;
    }

    &__text {
      font-size: 19px;
    }

    &__field {
      margin-bottom: 52px;
    }

    &__columns {
      display: grid;
      grid-column-gap: 103/1675*100vw;
    }

    &__review-field {
      margin-bottom: 76px;
    }

    &__answer-field {
      .radio-list {
        li {
          label {
            margin: 0 20/1675*100vw;
          }

          &:first-of-type {
            label {
              margin-left: 71/1675*100vw;
            }
          }
        }
      }
    }

    &__button {
      margin-bottom: 28px;
    }

    &__success {
      padding: 125px 0 189px;
    }

    &__success-title {
      max-width: 589px;
      padding: 35px 0 20px;
    }

    &__success-subtitle {
      font-size: 19px;
      line-height: 1.84;
    }
  }
}

@include respond-up('medium') {
  .reviews-form {
    &__columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__success-icon {
      svg {
        width: 65px;
      }
    }
  }
}

@include respond('medium') {
  .reviews-form {
    &__data {
      padding: 70px var(--spacer-medium);
    }

    &__text-block {
      margin-bottom: 32px;
    }

    &__field {
      margin-bottom: 32px;
    }

    &__columns {
      grid-column-gap: 31/768*100vw;
    }

    &__review-field {
      margin-bottom: 50px;
    }

    &__answer-field {
      .reviews-form__field {
        margin-bottom: 40px;
      }
    }

    &__button {
      margin-bottom: 20px;
    }

    &__bottom-block {
      grid-template-columns: 1fr;
    }

    .radio-list {
      li {
        label {
          margin: 0 20/768*100vw;
        }

        &:first-of-type {
          label {
            margin-left: 74/768*100vw;
          }
        }
      }
    }

    &__success {
      padding: 70px 0 96px;
    }

    &__success-title {
      max-width: 429px;
      padding: 30px 0 15px;
    }
  }
}

@include respond-down('medium') {
  .reviews-form {
    &__columns {
      display: grid;
    }

    &__text {
      font-size: 15px;
    }

    &__success-subtitle {
      font-size: 15px;
      line-height: 1.75;
    }
  }
}

@include respond-down('small') {
  .reviews-form {
    &__data {
      padding: 50px var(--spacer-small);
    }

    &__title {
      margin-bottom: 15px;
    }

    &__text {
      margin-bottom: 14px;
    }

    &__columns {
      display: flex;
      flex-direction: column;
    }

    &__field {
      margin-bottom: 24px;
    }

    &__review-field {
      margin-bottom: 40px;
    }

    &__answer-field {
      flex-direction: column;
      margin-bottom: 30px;

      & > label {
        margin-bottom: 18px;
      }

      .floating-field {
        flex-direction: column-reverse;
      }
    }

    &__button {
      margin-bottom: 20px;
    }

    .radio-list {
      li {
        &:first-of-type {
          label {
            margin-right: 40/375*100vw;
          }
        }
      }
    }

    &__success {
      padding: 40px 0;
    }

    &__success-title {
      max-width: 320px;
      padding: 30px 0 15px;
      box-sizing: content-box;
    }
  }
}