.product {
  &__price {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 16px;

    &::before {
      content: '';
      height: 1px;
      background-color: var(--text-color-dark);
    }
  }

  &__main-slider {
    background-color: var(--background-color);
  }

  &__body {
    display: flex;
  }

  &__sticky-item {
    cursor: pointer;

    &._active {
      .product__sticky-text {
        color: var(--text-color-dark);
      }
    }
  }

  &__sticky-text {
    line-height: 22px;
    color: var(--text-color);
    transition: color var(--default-transition);
    display: block;
    user-select: none;
    -webkit-user-drag: none;
  }

  &__slider {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);

    .slider-images__slide {
      cursor: pointer;
    }
  }

  &__bottom-container {
    border-top: 1px solid var(--border-color);
  }

  &__card {
    .product-card__description {
      min-height: auto;
    }

    .product-card__item-title {
      line-height: 1.55;
      display: block;
    }
  }
}

@include respond-up('large') {
  .product {
    &__print-wrapper {
      display: flex;
      margin-top: 53px;
      padding-left: 39px;
      position: relative;

      svg {
        display: flex;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__price {
      font-size: 18px;

      &::before {
        margin-right: 28px;
      }
    }

    &__main-slider {
      padding-top: 53px;
    }

    &__bar {
      flex: 0 0 calc(100% / 3 + 1px);
    }

    &__sticky-block {
      position: sticky;
      top: 0;
      left: 0;
      padding: 80px var(--spacer-large) 145px;
    }

    &__sticky-nav {
      position: relative;
      transition: opacity 0.1s;

      &._hide {
        opacity: 0;
      }
    }

    &__sticky-circle {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      top: 7px;
      left: 0;
      border-radius: 50%;
      background-color: var(--primary-color);

      &:nth-of-type(2) {
        opacity: 0;
      }
    }

    &__sticky-item {
      &:not(:last-of-type) {
        margin-bottom: 17px;
      }

      &._active {
        .product__sticky-text {
          position: relative;
          display: block;
        }
      }
    }

    &__sticky-text {
      line-height: 1.6;
      padding-left: 30px;
    }

    &__share-text {
      font-size: 22px;
      margin-top: 138px;
      margin-bottom: 32px;
      max-width: 360px;
    }

    &__content {
      background-color: var(--background-color);
      padding: 80px 0 145px;
      z-index: 1;
    }

    &__image {
      margin-bottom: 100px;

      img {
        width: 100%;
      }
    }

    &__picture {
      display: flex;
      width: calc(100vw - 2 * var(--spacer-large) - 5px);
      margin-left: -493/1675*100vw;
    }

    &__slider-wrapper {
    }

    &__slider {
      margin-left: calc(-100vw / 3);
      width: calc(100vw - 7px);
      padding: 105px 0 90px;
      margin-bottom: 100px;

      .slider-images__slide {
        max-width: 876/1675*100vw;
        padding-right: var(--spacer-large) !important;
      }

      .tns-inner {
        margin: 0 0 0 var(--spacer-large) !important;
      }
    }

    &__bottom-container {
      padding-bottom: 125px;
    }

    &__used-product-title {
      padding-top: 180px;
    }

    &__card {
      .product-card__description {
        display: none;
      }

      .product-card__item-title {
        padding-bottom: 0;
        font-size: 22px;
      }
    }

    &__used-list {
      grid-gap: calc((60 / 1675) * 100vw) calc((123 / 1675) * 100vw);
      padding-top: 69px;
    }
  }
}

@include respond-up('medium') {
  .product {
    &__price {
      padding-top: 50px;

      &::before {
        width: 64px;
      }
    }

    &__sticky-text {
      font-size: 15px;
    }

    &__bottom-container {
      border-bottom: 1px solid var(--border-color);
    }

    &__share {
      transition: opacity 0.1s;

      &._hide {
        opacity: 0;
      }
    }
  }
}

@include respond('medium') {
  .product {
    &__main-slider {
      padding-top: 40px;
    }

    &__bar {
      top: 35px;
    }

    &__sticky-list {
      padding: 33px 0 6px;

      &::before,
      &::after {
        flex: 0 0 calc(var(--spacer-medium) - 15px);
      }
    }

    &__sticky-text {
      padding: 0 15px;

      span {
        font-size: 16px;
      }
    }

    &__content {
      padding: 40px 0 90px;
    }

    &__content-container {
      padding: 0 var(--spacer-medium);
    }

    &__image {
      margin-bottom: 70px;
    }

    &__slider {
      padding: 50px 0 42px;
      margin: 0 calc(0px - var(--spacer-medium)) 70px;

      .slider-images__slide {
        max-width: 473/768*100vw;
        padding-right: var(--spacer-medium) !important;
      }

      .tns-inner {
        margin: 0 0 0 var(--spacer-medium) !important;
      }
    }

    &__bottom-container {
      padding-bottom: 70px;

      .projects__list {
        grid-row-gap: 84px;
      }

      .projects-item__offer-container {
        min-height: 0;
      }
    }

    &__used-product-title {
      padding-top: 100px;
    }

    &__card {
      .product-card__description {
        font-size: 13px;
        line-height: 1.75;
        padding-bottom: 0;
      }

      .product-card__item-title {
        font-size: 15px;
      }
    }

    &__used-list {
      grid-gap: calc((50 / 768) * 100vw) calc((56 / 768) * 100vw);
      padding-top: 40px;
    }

    &__price {
      &::before {
        margin-right: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .product {
    &__print-wrapper {
      display: none;
    }

    &__price {
      font-size: 16px;
    }

    &__body {
      background-color: var(--background-color);
      flex-direction: column;
    }

    &__bar {
      position: sticky;
      left: 0;
      z-index: 20;
    }

    &__sticky-block {
      background-color: var(--background-color);
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__sticky-circle {
      display: none;
    }

    &__sticky-list {
      display: flex;
      flex-wrap: nowrap;

      &::before,
      &::after {
        content: '';
        height: 10px;
      }
    }

    &__sticky-item {
      &._active {
        .product__sticky-text span {
          color: var(--text-color-dark);

          &::before {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
    }

    &__sticky-text {
      display: block;

      span {
        position: relative;
        display: block;
        line-height: 1.55;
        padding-bottom: 4px;
        font-weight: 400;
        white-space: nowrap;
        transition: color var(--default-transition);

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: var(--text-color-dark);
          transform: scaleX(0);
          transform-origin: right;
          transition: transform .3s ease-out;
        }
      }
    }

    &__share {
      display: none;
    }
  }
}

@include respond-down('small') {
  .product {
    &__price {
      padding-top: 35px;

      &::before {
        width: 35px;
        margin-right: 25px;
      }
    }

    &__main-slider {
      padding-top: 30px;
    }

    &__bar {
      top: 40px;
    }

    &__sticky-list {
      padding: 23px 0 6px;

      &::before, &::after {
        flex: 0 0 calc(var(--spacer-small) - 10px);
      }
    }

    &__sticky-text {
      font-size: 14px;
      padding: 0 7.5px;
    }

    &__content {
      padding: 30px 0 50px;
    }

    &__description {
      padding: 0 var(--spacer-small) 40px;
    }

    &__image {
      display: none;
    }

    &__slider {
      padding: 40px 0 20px;
      margin-bottom: 50px;

      .slider-images__slide {
        max-width: 432/375*100vw;
        padding-right: var(--spacer-small) !important;
      }

      .tns-inner {
        margin: 0 0 0 var(--spacer-small) !important;
      }
    }

    &__bottom-container {
    }

    &__used-list {
      padding-top: 22px;
      margin-bottom: 0;

      .product-card__item {
        &:nth-child(n+3) {
          display: none;
        }
      }
    }

    &__used-product-title {
      padding-top: 50px;
    }

    &__card {
      .product-card__item-title {
        font-size: 14px;
      }
    }
  }
}

@media print {
  .product {
    display: none;
  }
}
