.callback {
  &__title {
    text-align: center;
  }

  &__subtitle {
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__fields {
    width: 100%;
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    button {
      pointer-events: none;
    }
  }

  &__success-title {
   text-align: center;
  }

  &__success-subtitle {
    text-align: center;
  }

  &__link {
    border-bottom: 1px solid var(--border-color);
    transition: border-bottom-color var(--default-transition);
  }
}

@include respond-up('large') {
  .callback {
    margin: 80px 0;

    &__title {
      padding-bottom: 25px;
    }

    &__subtitle {
      line-height: 1.84;
      padding-bottom: 52px;
    }

    &__fields {
      width: 677/1675*100vw;
      padding-bottom: 60px;

      .floating-field {
        &:not(:last-of-type){
          margin-bottom: 52px;
        }
      }
    }

    &__button {
      padding-left: 85px;
      padding-right: 85px;
      margin-bottom: 30px;
    }

    &__success-title {
      max-width: 589px;
      padding: 35px 0 20px;
    }

    &__success-subtitle {
      font-size: 19px;
      line-height: 1.84;
    }

    &__link {
      &:hover {
        border-color: var(--text-color-dark);
      }
    }
  }
}

@include respond-up('medium') {
  .callback {
    &__success-icon {
      svg {
        width: 65px;
      }
    }
  }
}

@include respond('medium') {
  .callback {
    padding: 40px 0;

    &__title {
      font-size: 26px;
      padding-bottom: 20px;
    }

    &__subtitle {
      padding-bottom: 32px;
      width: 388/768*100vw;
    }

    &__fields {
      width: 424/768*100vw;
      padding-bottom: 40px;

      .floating-field {
        &:not(:last-of-type){
          margin-bottom: 32px;
        }
      }
    }

    &__success {
      padding: 70px 0 96px;
    }

    &__success-title {
      max-width: 429px;
      padding: 30px 0 15px;
    }
  }
}

@include respond-down('medium') {
  .callback {
    &__button {
      margin-bottom: 20px;
    }

    &__success-subtitle {
      font-size: 15px;
      line-height: 1.75;
    }
  }
}

@include respond-down('small') {
  .callback {
    padding: 20px 0;

    &__title {
      font-size: 18px;
      padding-bottom: 15px;
    }

    &__subtitle {
      padding-bottom: 14px;
      width: 281/375*100vw;
    }

    &__fields {
      width: 310/375*100vw;
      padding-bottom: 30px;

      .floating-field {
        &:not(:last-of-type){
          margin-bottom: 22px;
        }
      }
    }

    &__success {
      padding: 40px 0;
    }

    &__success-title {
      max-width: 320px;
      padding: 30px 0 15px;
      box-sizing: content-box;
    }
  }
}

