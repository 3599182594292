.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15000;
  overscroll-behavior: none;
  background-color: var(--background-color);

  &__badge-image {
    width: 100%;
    height: 100%;
  }

  &__badge-picture {
    display: flex;
    height: 100%;
  }

  &__badge {
    position: absolute;
    top: 0;
  }

  &__plate {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--background-color);
    z-index: 20000;
    transform: translateX(100%);

    &._active {
      transform: translateX(0);
    }
  }

  &.header__surface {
    z-index: 20001;
    position: fixed;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0s, opacity 0.25s 0.8s;

    &._active {
      transform: translateX(0);
      opacity: 1;

      .header__hamburger {
        transition: opacity 0.9s;
        opacity: 1;
      }

      .header__nav-button {
        .header__line:nth-of-type(2n) {
          animation: scale-to-null forwards, scale-top-line forwards .2s 0.9s;
        }

        .header__line:nth-of-type(2n-1) {
          animation: scale-to-null forwards, scale-bottom-line forwards .2s 1.1s;
        }
      }
    }

    .header__hamburger {
      opacity: 0;
      transition: opacity 0s;
    }
  }

  &_light {
    color: #fff;

    .header__nav-search {
      border-bottom: 1px solid var(--border-color-light);
      transition: var(--default-transition);
    }

    .header__nav-search-form {
      background-color: var(--t);

      input[type=text] {
        border-bottom: 1px solid #fff;
        color: #fff;

        &:focus {
          border-bottom: 1px solid #fff;
        }
      }

      input::-webkit-input-placeholder {
        color: #fff;
      }
    }

    .header__nav-search-button {
      svg {
        path {
          fill: #fff;
          opacity: 1;
          transition: var(--default-transition);
        }

        line {
          transition: var(--default-transition);
        }
      }

      &:hover {
        svg {
          path {
            opacity: 0.5;
          }

          line {
            opacity: 0.5;
          }
        }
      }
    }

    .header__contacts, .header__logo, .header__phone-block, .header__nav {
      border-color: var(--border-color-light);
    }

    .header__line {
      background-color: #fff;
    }

    .header__contact-name {
      &::before {
        background-color: #fff;
      }
    }

    .header-phone__callback-inner {
      &.header-phone__callback-inner_desktop {
        &::before {
          background-color: #fff;
        }
      }
    }

    .header__contact-icon {
      svg {
        path, rect {
          stroke: rgba(255, 255, 255, 0.35);
        }

        circle {
          fill: rgba(255, 255, 255, 0.35);
        }
      }
    }

    .header__menu {
      border-color: var(--border-color-light);
    }

    .header-phone__callback-inner_mobile {
      svg {
        path {
          stroke: rgba(255, 255, 255, 0.35);
        }
      }
    }

    .header__logo-wrap {
      path {
        &:nth-of-type(n + 4) {
          fill: white;
        }
      }
    }

    .nav__name {
      &::before {
        background-color: #fff;
      }
    }

    .nav__child-name {
      &::before {
        background-color: #fff;
      }
    }

    .nav__children {
      background-color: var(--nav-cildren-link-color-light);
    }
  }

  &__main {
    display: grid;
    position: relative;
    z-index: 20;
  }

  &__logo {
    position: relative;
    grid-area: logo;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &__logo-wrap {
    display: flex;
  }

  &__contacts {
    grid-area: contacts;
    display: flex;
    //grid-auto-flow: column;
    //grid-template-columns: min-content;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    box-sizing: border-box;
  }

  &__contact {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    svg {
      margin-right: 10px;
    }
  }

  &__contact-icon {
    display: flex;
  }

  &__phone-block {
    grid-area: phone;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .header-phone {
      &__number {
        white-space: nowrap;
        font-weight: 400;
      }

      &__delimiter {
        color: var(--border-color);
        cursor: pointer;
      }

      &__callback-inner {
        white-space: nowrap;
        font-weight: 500;
      }
    }
  }

  &__nav {
    grid-area: nav;
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    align-items: center;
    box-sizing: border-box;
  }

  &__nav-link {
    display: flex;
    align-items: center;
  }

  &__nav-button {
    display: inline-flex;
    align-items: center;

    &:hover {
      .header__line {
        &:nth-of-type(1) {
          animation: scale-from-line .2s .2s forwards;
        }

        &:nth-of-type(2) {
          animation: scale-from-line .2s forwards;
        }

        &:nth-of-type(3) {
          animation: scale-to-line .2s .6s forwards;
        }

        &:nth-of-type(4) {
          animation: scale-to-line .2s .4s forwards;
        }
      }
    }
  }

  &__hamburger-wrap {
    display: flex;
  }

  &__hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 14px;
    position: relative;
    width: 23px;
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: var(--text-color-dark);
    position: absolute;

    &:nth-of-type(2n) {
      top: 2px;
      left: 0;
    }

    &:nth-of-type(2n-1) {
      bottom: 2px;
      left: 0;
    }

    &:nth-of-type(1) {
      animation: scale-to-line forwards;
      transform-origin: right;
    }

    &:nth-of-type(2) {
      animation: scale-to-line forwards;
      transform-origin: right;
    }

    &:nth-of-type(3) {
      transform: scaleX(0);
      transform-origin: left;
    }

    &:nth-of-type(4) {
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  &__nav-text {
    margin-left: 15px;
    font-weight: 400;
  }

  .nav-phone {
    display: none;
  }

  &__nav-search {
    grid-area: search;
    border-bottom: 1px solid var(--border-color);
    transition: var(--default-transition);
  }

  &__nav-search-form {
    display: block;
    position: absolute;
    background-color: var(--background-color);
    z-index: 10;
    transition: var(--default-transition);
    width: 0;

    input[type=text] {
      border-bottom: 1px solid black;
      padding: 10px 0;
      font-weight: normal;
      font-size: 15px;
      line-height: 15px;

      &:focus {
        border-bottom: 1px solid black;
      }
    }

    input::-webkit-input-placeholder {
      color: black;
    }

    &._active {

    }
  }

  &__nav-search-button {
    display: block;

    svg {
      path {
        fill: black;
      }
    }
  }

  &__nav-list {
    z-index: 5;
    transition: var(--default-transition);
    opacity: 0;

    &._visible {
      opacity: 1;
    }
  }

  &__nav-search-results {
    display: block;
    background-color: #fff;
    color: black;
    position: absolute;
    top: 63px;
    right: 120px;
    width: 919px;
    height: fit-content;
    z-index: 10;
    transition: var(--default-transition);
    opacity: 1;

    &._hidden {
      opacity: 0;
    }
  }

  &__nav-search-close {
    display: none;
  }

  &__nav-search-icon {
    display: flex;
  }

  &__socials-mobile-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__contacts-wrap {
    &_right {
      .header {
        &__contact {
          svg {
            path {
              transition: fill var(--default-transition);
              fill: #C8C8C8;
            }
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  $header-font-size: 15px;
  $header-line-height: 15px;

  .header {
    position: relative;
    transition: transform var(--default-transition);

    &__badge {
      left: 261px;
      width: 34px;
      height: 64px;
    }

    &_light {
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;

      .header__nav-search {
        border-top: 1px solid var(--border-color-light);

        &._hidden {
          border-color: var(--border-color-light);
        }
      }

      .header {
        &__contacts-wrap {
          &_right {
            .header {
              &__contact {
                &:hover {
                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &_sticky {
      .header__nav {
        padding: 0 43px 0 73px;
      }

      .header__nav-search-form {
        input[type=text] {
          border-bottom: 1px solid black;
        }
      }
    }

    &__main {
      grid-template-columns: min-content 1fr min-content 158px;
      grid-template-areas:
              "logo contacts phone phone"
              "nav nav nav search";
    }

    &__logo {
      padding: 0 73px;
    }

    &__logo-wrap {
      width: 163.66px;
    }

    &__contacts {
      padding: 0 50px;
    }

    &__contact {
      &:hover {
        .header__contact-name {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s var(--default-bezier);
          }
        }
      }
    }

    &__contact-name {
      font-weight: 400;
      font-size: $header-font-size;
      line-height: $header-line-height;
      position: relative;
      padding: 4px 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: var(--text-color-dark);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s ease-out;
      }
    }

    &__telegram {
      &_mobile {
        display: none;
      }
    }

    &__phone-block {
      justify-content: center;
      .header-phone {
        &__number {
          padding: 27px clamp(10px, calc(30 / 1675 * 100vw), 30px) 28px clamp(15px, calc(50 / 1675 * 100vw), 50px);
          font-size: $header-font-size;
          line-height: $header-line-height;
        }

        &__callback {
          padding-left: clamp(10px, calc(30 / 1675 * 100vw), 30px);
          padding-right: clamp(10px, calc(85 / 1675 * 100vw), 85px);

          &:hover {
            .header-phone__callback-inner_desktop {
              &::before {
                left: 0;
                transform-origin: left;
                transform: scaleX(1);
                transition: transform .3s var(--default-bezier);
              }
            }
          }
        }

        &__callback-inner {
          font-size: 14px;
          line-height: 14px;

          &_desktop {
            position: relative;
            padding: 4px 0;

            &::before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background-color: var(--text-color-dark);
              transform: scaleX(0);
              transform-origin: right;
              transition: transform .3s ease-out;
            }
          }

          &_mobile {
            display: none;
          }
        }
      }
    }

    &__nav {
      padding: 0 60px 0 73px;
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
    }

    &__nav-link {
      margin-left: -27px;
    }

    &__nav-button {
      padding: 27px 27px 28px;
    }

    &__nav-text {
      font-size: $header-font-size;
      line-height: $header-line-height;
    }

    &_sticky {
      position: sticky;
      transform: translateY(-100%);

      .nav-phone {
        display: block;
        grid-area: auto;
      }

      .menu {
        height: calc(100vh - 50%);
      }
    }

    &_visible {
      transform: translateY(-50%);
    }

    &__nav-search {
      position: relative;
      border-top: 1px solid var(--border-color);
    }

    &__nav-search-form {
      right: 115px;
      bottom: 17px;

      &._active {
        width: 919px;
      }
    }

    &__nav-search-results {
      top: 57px;
      right: 115px;
      width: 919px;
    }

    &__nav-search-button {
      padding: 35px 86px 35px 50px;
      position: relative;
    }

    &__socials-mobile-wrap {
      display: none;
    }

    &__contacts {
      justify-content: space-between;
    }

    &__contacts-wrap {
      display: flex;
      align-items: center;
      column-gap: 20px;

      &_left {
        column-gap: calc(72/1675 * 100vw);
      }

      &_right {
        column-gap: 15px;

        .header {
          &__contact {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;

            svg {
              margin: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1452px) {
  .header {
    &__contact {
      &_houzz {
        display: none;
      }
    }
  }
}

@media (max-width: 1352px) {
  .header {
    &__contact {
      &_dzen {
        display: none;
      }
    }
  }
}

@media (max-width: 1400px) {
  .header {
    &_sticky {
      .nav-phone {
        display: none;
      }
    }

  }
}

@include respond-up('medium') {
  .header {
    &_light {
      .header__nav-search {
        .header__nav-search-button {
          border-left: 1px solid var(--t);
        }

        &._hidden {
          .header__nav-search-button {
            border-left: 1px solid var(--border-color-light);
          }
        }
      }

      .header__nav-search-icon {
        &_closer {
          svg {
            line {
              stroke: #fff;
            }
          }
        }
      }

      .header__telegram {
        border-left: 1px solid var(--border-color-light);
        border-right: 1px solid var(--border-color-light);
      }

      .header__nav-search-button {
        border-left: 1px solid var(--border-color-light);
      }
    }

    &__nav-search {
      .header__nav-search-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-10px);
        transition: var(--default-transition);
        opacity: 0;
        z-index: 0;

        &_closer {
          transition: var(--default-transition);
          opacity: 1;
          z-index: 1;
        }
      }

      .header__nav-search-button {
        border-left: 1px solid var(--t);
        transition: var(--default-transition);
      }

      &._hidden {
        .header__nav-search-icon {
          opacity: 1;
          z-index: 1;

          &_closer {
            opacity: 0;
            z-index: 0;
          }
        }

        .header__nav-search-button {
          border-left: 1px solid var(--border-color);
        }
      }
    }
  }
}

@include respond('medium') {
  .header {

    &__badge {
      left: 176px;
      width: 24px;
      height: 44px;
      transition: var(--default-transition);
      opacity: 1;

      &._hidden {
        opacity: 0;
      }
    }

    &_light {
      .header__nav-search {
        border-right: 1px solid var(--border-color-light);
      }
    }

    &__logo-wrap {
      width: 126px;
    }

    &__logo {
      justify-content: start;
      padding: 0 30px;

      &._hidden {
        .header {
          &__logo-wrap {
            opacity: 0;
          }

          &__socials-mobile-wrap {
            opacity: 0;
            pointer-events: none;
          }

          &__badge {
            opacity: 0;
          }
        }
      }
    }

    &__phone-block {
      .header-phone {
        &__number {
          padding: 19px 30px;
        }

        &__callback-inner {
          padding: 19px 30px;
        }
      }
    }

    &__logo-wrap {
      transition: var(--default-transition);
      opacity: 1;
    }

    &__socials-mobile-wrap {
      transition: var(--default-transition);
      opacity: 1;
    }

    &__nav-search {
      position: relative;
      border-right: 1px solid var(--border-color);
    }

    &__nav-search-button, {
      padding: 26px 32px 26px 32px;
    }

    .header__nav-search-icon {
      top: 50%;
      left: 50%;
      transform: translate(-10px, -10px);
    }

    &__nav-search-form {
      input[type=text] {
        padding: 10px 0 9px;
      }
    }
  }
}

@include respond-down('medium') {
  $header-font-size: 14px;
  $header-line-height: 14px;
  .header {
    position: sticky;
    transition: transform 0s, opacity 0.25s 0.8s, background-color .2s ease-in-out !important;

    .header__line {
      transition: background-color .2s ease-in-out;
    }

    .header__phone-number {
      transition: color .2s ease-in-out;
    }

    .header__logo path {
      transition: fill .2s ease-in-out;
    }

    .header-phone__callback-inner path {
      transition: stroke .2s ease-in-out;
    }

    &_light {
      position: fixed !important;
      top: 0;
      left: 0;
      background: transparent;

      .header__telegram {
        border-right: 1px solid var(--border-color-light);
        border-bottom: 1px solid var(--border-color-light);
      }

      .header__telegram-button {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &_sticky {
    }

    &__main {
      grid-template-columns: min-content 1fr min-content min-content;
      grid-template-areas: "nav logo search tg phone";
    }

    &__logo {
      border-left: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      box-sizing: border-box;
      justify-content: space-between;
    }

    &__contacts {
      display: none;
    }

    &__phone-block {
      border-bottom: 1px solid var(--border-color);
      box-sizing: border-box;

      .header-phone {
        &__number {
          font-size: $header-font-size;
          line-height: $header-line-height;
        }

        &__callback-inner {
          &_desktop {
            display: none;
          }

          &_mobile {
            display: flex;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    &__nav {
      border-bottom: 1px solid var(--border-color);
    }

    &__nav-button {
      padding: 19px 30px 20px;
    }

    &__nav-text {
      display: none;
    }

    &__nav-list {
      display: none;
    }

    &__nav-search-form {
      right: 52px;
      bottom: 14px;

      &._active {
        width: calc(100vw - 500px);
      }
    }

    &__nav-search-results {
      top: 50px;
      right: 53px;
      width: calc(100vw - 500px);
    }

    &__telegram {
      display: flex;
      border-right: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
    }

    &__telegram-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 22px 14px;
    }

    &__socials-mobile {
      padding: 0 12px;
      height: 100%;
    }
  }
}

@include respond-down('small') {
  .header {

    &__badge {
      left: 125px;
      width: 24px;
      height: 44px;
    }

    &__main {
      grid-template-areas: "nav logo tg search phone";
    }

    &_light {
      .header__nav-search {
        border-right: 1px solid var(--border-color-light);
        border-left: 1px solid var(--border-color-light);
      }

      .header__nav-search-form {
        background-color: var(--background-color);

        input[type=text] {
          border-bottom: 1px solid black;
          color: black;

          &:focus {
            border-bottom: 1px solid black;
          }
        }

        input::-webkit-input-placeholder {
          color: black;
        }
      }

      .header__telegram {
        border-left: 1px solid var(--border-color-light);
        border-right: none;
      }
    }

    &__logo-wrap {
      width: 100px;
    }

    &__logo {
      padding: 0 15px;
    }

    &__phone-block {
      .header-phone {
        &__number {
          display: none;
        }

        &__delimiter {
          display: none;
        }

        &__callback-inner {
          padding: 17px 16px 18px;
        }
      }
    }

    &__nav-button {
      padding: 18px 15px;
    }

    &__nav-text {
      display: none;
    }

    &__nav-list {
      display: none;
    }


    &__nav-search-form {
      height: 62px;
      top: 52px;
      right: 0;
      background-color: var(--background-color);

      &._active {
        width: 100%;
      }

      input[type=text] {
        padding: 25px 0 10px 0;
        width: calc((286 / 375) * 100%);
        margin-left: var(--spacer-small);
      }

      &._active {
        .header__nav-search-form-line {
          display: block;

          &:nth-of-type(1) {
            animation: scale-to-null forwards, scale-top-line forwards .2s 0.9s;
          }

          &:nth-of-type(2) {
            animation: scale-to-null forwards, scale-bottom-line forwards .2s 1.1s;
          }
        }
      }
    }

    &__nav-search-results {
      top: 110px;
      right: 0;
      width: 100%;
    }

    &__nav-search {
      border-right: 1px solid var(--border-color);
      border-left: 1px solid var(--border-color);
    }

    &__nav-search-form-close-wrap {
      position: absolute;
      right: 28px;
      bottom: 14px;
    }

    &__nav-search-form-close {
      display: flex;
      position: relative;
      height: 22px;
      width: 22px;
    }

    &__nav-search-form-line {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #C8C8C8;
      transition: var(--default-transition);
      display: none;

      &:nth-of-type(1) {
        top: 6px;
      }

      &:nth-of-type(2) {
        top: 13px;
      }
    }

    .header__nav-search-icon {
      &_closer {
        display: none;
      }
    }

    &__nav-search-button {
      padding: 16px 15px 14px;
    }

    &__telegram-button {
      padding: 16px 18px 14px;
    }

    &__telegram {
      display: flex;
      border-left: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      border-right: none;
    }
  }
}

@keyframes scale-from-line {
  to {
    transform: scaleX(0);
  }
}

@keyframes scale-to-line {
  to {
    transform: scaleX(1);
  }
}

@keyframes transform-top-line {
  to {
    transform: translateY(3.5px) rotate(45deg);
    transform-origin: center;
  }
}

@keyframes transform-bottom-line {
  to {
    transform: translateY(-3.5px) rotate(-45deg);
    transform-origin: center;
  }
}

@keyframes scale-top-line {
  from {
    transform: translate(-4px, -4px) rotate(-45deg) scaleX(0);
    transform-origin: top right;
  }
  to {
    transform: translate(-4px, -4px) rotate(-45deg) scaleX(1);
    transform-origin: top right;

  }
}

@keyframes scale-bottom-line {
  from {
    transform: translate(-4px, 4px) rotate(45deg) scaleX(0);
    transform-origin: bottom right;
  }
  to {
    transform: translate(-4px, 4px) rotate(45deg) scaleX(1);
    transform-origin: bottom right;
  }
}

@keyframes scale-to-null {
  to {
    transform: scaleX(0);
  }
}

@media print {
  .header {
    display: none;
  }
}