.category-item {
  &__link {
    display: grid;
    align-items: center;
    &._active {
      .category-item {
        &__plus-wrap {
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  &__title {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__count {
    font-family: var(--h-font);
    font-weight: 400;
    line-height: 155%;
    color: var(--text-color);
  }

  &__plus-wrap {
    display: flex;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      height: 100%;
      border-left: 2px solid #161A22;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      transition: opacity var(--default-transition);
    }

    &::after {
      width: 100%;
      border-bottom: 2px solid #161A22;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }

  &__block {
    height: 0;
    transition: height var(--default-transition);
    overflow: hidden;
  }

  &__documents-items {
    &:first-child {
      border-bottom: 1px solid var(--border-color);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

@include respond-up('large') {
  .category-item {
    padding: 0 var(--spacer-large);
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-column-gap: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      border-left: 1px solid var(--border-color);
      grid-column: 5/6;
    }

    &__link {
      padding: 40px var(--spacer-large);
      margin: 0 calc(-1 * var(--spacer-large));
    }

    &__link,
    &__block {
      grid-column: 1/15;
      grid-template-columns: repeat(14, 1fr);
      grid-gap: 20px;
    }

    &__title {
      font-size: 22px;
      grid-column: 1/5;
    }

    &__count {
      font-size: 16px;
      grid-column: 6/9;
      padding-left: 14px;
    }

    &__plus-wrap {
      width: 34px;
      height: 34px;
      grid-column: 14/15;
    }

    &__block {
      display: grid;
    }

    &__documents-list {
      padding-bottom: 60px;
      grid-column: 6/15;
      padding-left: 14px;
    }
  }
}

@include respond-up('medium') {
  .category-item {
    &__plus-wrap {
      justify-self: flex-end;
    }
  }
}

@include respond('medium') {
  .category-item {
    &__link {
      padding: 30px var(--spacer-medium);
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 22px;
    }

    &__title {
      font-size: 18px;
      grid-column: 1/9;
    }

    &__count {
      grid-column: 9/12;
      padding-left: 2px;
    }

    &__plus-wrap {
      grid-column: 12/13;
    }

    &__documents-list {
      padding: 5px var(--spacer-medium) 50px;
    }
  }
}

@include respond-down('medium') {
  .category-item {
    &__count {
      font-size: 14px;
    }

    &__plus-wrap {
      width: 24px;
      height: 24px;
    }
  }
}

@include respond-down('small') {
  .category-item {
    &__link {
      padding: 18px var(--spacer-small);
      grid-template-areas: 'title plus'
                           'count plus';
      grid-template-columns: 1fr 24px;
      grid-column-gap: 22px;
      grid-row-gap: 7px;
    }

    &__title {
      font-size: 15px;
      grid-area: title;
    }

    &__count {
      grid-area: count;
    }

    &__plus-wrap {
      grid-area: plus;
    }

    &__documents-list {
      padding: 7px var(--spacer-small) 30px;
    }
  }
}

@include respond-down('x-small') {
  .category-item {
    &__link {
      padding: 18px var(--spacer-smaller);
    }

    &__documents-list {
      padding: 7px var(--spacer-smaller) 30px;
    }
  }
}