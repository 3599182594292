.socials {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.developers-info-social {
    gap: 28px;
  }

  &__item {
    list-style: none;
    border: 1px solid var(--border-color);
    transition: background-color var(--default-transition), border-color var(--default-transition);
    margin-right: -1px;
    margin-bottom: -1px;

    &.developers-info-social {
      border: none;

      &:hover {
        background-color: unset;
        border-color: unset;

        .social-icon {
          &__path {
            fill: black;
          }
        }

        svg {
          path {
            fill: var(--text-color-dark);
          }
        }
      }
    }

    svg {
      path {
        fill: var(--text-color-dark);
        transition: fill var(--default-transition);
      }
    }

    &:hover {
      background-color: var(--text-color-dark);
      border-color: var(--text-color-dark);

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &__link {
    width: 48px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &.developers-info-social {
      width: 100%;
      height: 28px;
      display: flex;
      align-items: center;
      gap: 7px;

      span {
        display: flex;
        height: 100%;
        align-items: center;
      }
    }
  }
}

@include respond-down('small') {
  .socials {
    &.developers-info-social {
      row-gap: 12px;
    }
  }
}