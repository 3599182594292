.product-card {
  &_spacer {
    overflow: hidden;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__picture-inner {
    position: relative;
  }

  &__images {
    display: flex;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__badge {
    position: absolute;
    white-space: nowrap;
    bottom: 0;
    left: 0;
  }

  &__border {
    position: absolute;
    display: none;
  }

  &__unused-text {
    display: none;
  }

  &__item-title {
    font-family: var(--h-font);
    line-height: 1.55em;
  }

  &__description {
    &_none {
      display: none;
    }
  }
}

@include respond-up('large') {
  .product-card {
    &__list {
      grid-gap: 122px calc(var(--spacer-large) * 2);
      padding-top: 74px;
    }

    &__item {
      align-self: end;

      &:nth-child(10n+5) {
        grid-column-start: 2;
        grid-column-end: 4;
        background-color: var(--background-color);

        .product-card__description {
          max-width: calc((550/1675) * 100vw);
        }
        .product-card__border {
          display: block;
          bottom: 0;
          border-top: 1px solid var(--border-color);
          left: calc(var(--spacer-large) * -1);
          width: calc(100% + var(--spacer-large) * 2);
        }
      }

      &:nth-child(10n+9) {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: var(--background-color);

        .product-card__description {
          max-width: calc((550/1675) * 100vw);
        }
        .product-card__border {
          display: block;
          bottom: 0;
          border-top: 1px solid var(--border-color);
          right: calc(var(--spacer-large) * -1);
          width: calc(100% + var(--spacer-large) * 2);
        }
      }
    }

    &__item-title {
      margin: 30px 0 12px;
      font-size: 22px;
      line-height: 34/22*1em;
    }

    &__description {
      min-height: 140px;
    }
  }
}

@include respond('medium') {
  .product-card {
    &__item:last-of-type {
      .product-card__border {
        display: none !important;
      }
    }
  }
}

@include respond('medium') {
  .product-card {
    &__list {
      grid-gap: 71px calc(var(--spacer-medium) * 2);
      padding-top: 50px;
    }

    &__item {
      align-self: end;

      &:nth-child(10n+5) {
        grid-column-start: 2;
        grid-column-end: 4;
        background-color: var(--background-color);

        .product-card__description {
          max-width: calc((350/768) * 100vw);
        }
        .product-card__border {
          display: block;
          bottom: 0;
          border-top: 1px solid var(--border-color);
          left: calc(var(--spacer-medium) * -1);
          width: calc(100% + var(--spacer-medium) * 2);
        }
      }

      &:nth-child(7n+9) {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: var(--background-color);

        .product-card__description {
          max-width: calc((350/768) * 100vw);
        }

        .product-card__border {
          display: block;
          bottom: 0;
          border-top: 1px solid var(--border-color);
          right: calc(var(--spacer-medium) * -1);
          width: calc(100% + var(--spacer-medium) * 2);
        }
      }
    }

    &__images {
      display: flex;
    }

    &__item-title {
      margin: 15px 0 10px;
      font-size: 15px;
    }

    &__description {
      min-height: 220px;
      padding-bottom: calc((20/768) * 100vw);
    }
  }
}

@include respond-down('small') {
  .product-card {
    &__list {
      padding: 30px 0 50px;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--spacer-small);
    }

    &__item {
      align-items: end;

      &:nth-child(3n+3) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    &__wrapper {
      height: 100%;
      justify-content: flex-end;
    }

    &__item-title {
      padding-top: 12px;
      font-size: 14px;
      line-height: 1.55em;
    }

    &__description {
      display: none;
    }
  }
}