.project {
  &__view-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__picture {
    display: flex;
  }

  &__head-inner {
    background-color: var(--background-color);
  }

  &__button-wrapper {
    background-color: var(--background-color);
  }

  &__body {
    display: flex;
  }

  &__name {
    color: var(--text-color);
    flex: 0 0 33%;
  }

  &__year, &__price, &__location {
    padding: 19px 0;
    display: flex;
  }

  &__nav-label {
    text-align: center;

    &:hover {
      .project {
        &__nav-label-inner {
          border-bottom-color: white;
        }
      }
    }
  }

  &__nav-label-inner {
    color: white;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color var(--default-transition);
  }

  &__nav-text {
    color: white;
    white-space: nowrap;
  }

  &__next-project {
    position: relative;
  }

  &__nav-link {
    display: inline-block;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;

    &::before {
      height: 100%;
      width: 100%;
      position: absolute;
      content: '';
      background-color: #000000;
      opacity: 0.4;
      z-index: 2;
    }
  }

  &__nav-picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    picture {
      display: flex;
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  &__image {
    position: relative;
    object-fit: cover;
    width: 100%;
  }

  &__nav-wrapper {
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__next-wrapper {
    background-color: var(--background-color);
    border-top: 1px solid var(--border-color);
  }
}

@include respond-up('large') {
  .project {
    &__view-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: -50px;
      margin-bottom: calc((100/1675) * 100vw);
    }

    &__next-project {
      position: relative;
    }

    &__product-card {
      .product-card__item-title {
        padding-bottom: 120px;
      }
    }

    &__nav-link {
      position: relative;
    }

    &__nav-picture {
      position: absolute;
      width: 100%;
    }

    &__next-wrapper {
      padding: 100px var(--spacer-large);
      margin: 0 calc(var(--spacer-large) * -1);
    }

    &__nav-text {
      padding-top: 135px;
      margin-bottom: 48px;
      font-size: calc((20/1675) * 100vw);
      line-height: calc((26/1675) * 100vw);
    }

    &__nav-label {
      font-size: calc((42/1675) * 100vw);
      font-weight: 500;
      margin: 0 100px 135px;

    }

    &__view-item {
      display: flex;

      &:nth-child(2) {
        background: var(--gradient-to-bot);
      }

      &:nth-child(n + 3) {
        margin-top: 70px;
      }

      &:nth-child(2n+2) {
        flex: 0 0 calc((1004/1675) * 100vw);
      }

      &:nth-child(3n+3) {
        flex: 1 1 100%;
        display: flex;
        justify-content: center;

        .project__view-inner {
          width: calc((1024/1675) * 100vw);
        }
      }

      &:nth-child(4n+1) {
        flex: 0 0 calc((445/1675) * 100vw);
      }
    }

    &__view-image {
      width: 100%;
    }

    &__view-inner {
      justify-content: flex-end;
    }

    &__view-description {
      min-height: 100px;
    }

    &__head {
      border-bottom: 1px solid var(--border-color);
      margin: 0 calc(var(--spacer-large) * -1);
      padding: 0 var(--spacer-large);
    }

    &__head-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: calc((49 / 1675) * 100vw);
    }

    &__button-wrapper {
      display: flex;
      align-items: center;
      padding: calc((68 / 1675) * 100vw) 0 calc((54 / 1675) * 100vw) 0;
    }

    &__empty-block {
      flex: 0 0 35%;
    }

    &__author-container {
      display: flex;
      align-items: center;
    }

    &__author-avatar {
      margin-right: 13px;
      border-radius: 100%;
      width: calc((72/1675) * 100vw);
      height: calc((72/1675) * 100vw);
    }

    &__author-without-avatar {
      display: none;
    }

    &__who-wrapper {
      padding-right: calc((100/1675) * 100vw);
    }

    &__slider-post-text {
      padding-right: calc((58 / 1675) * 100vw);
    }

    &__option {
      flex: 0 0 35%;
      padding: calc((68 / 1675) * 100vw) 0 calc((54 / 1675) * 100vw) 0;
      display: flex;
      flex-direction: column;
    }

    &__location {
      padding-bottom: 19px;
    }

    &__location, &__year, &__price {
      max-width: 430px;
      display: flex;
    }

    &__year {
      padding: 19px 0;
      border-top: 1px solid rgba(208, 208, 209, 0.8);
      border-bottom: 1px solid rgba(208, 208, 209, 0.8);
    }

    &__price {
      padding-top: 19px;
    }

    &__description {
      width: 100%;
      padding: calc((68 / 1675) * 100vw) 0 calc((54 / 1675) * 100vw) 0;
      background-color: var(--background-color);
    }

    &__description-title {
      padding-bottom: 40px;
    }

    &__description-subtitle {
      padding-bottom: 80px;
    }

    &__characteristics-title {
      padding-bottom: 40px;
    }

    &__param-container {
      padding: 23px 0;
      border-top: 1px solid rgba(208, 208, 209, 0.8);
      max-width: 700px;
      display: flex;

      &:last-child {
        border-bottom: 1px solid rgba(208, 208, 209, 0.8);
      }
    }

    &__characteristics-list {
      display: flex;
      flex-direction: column;
    }

    &__param-title {
      flex: 0 0 55%;
      font-size: 12px;
    }

    &__picture {
      padding-bottom: 20px;
    }
  }
}

@include respond('medium') {
  .project {
    &__view-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: calc((100/1675) * 100vw);
      margin-top: -50px;
    }

    &__nav-text {
      padding-top: 55px;
      font-size: calc((16/768) * 100vw);
      line-height: calc((20/768) * 100vw);
      margin-bottom: calc((20/768) * 100vw);
    }

    &__nav-label {
      margin: 0 20px 55px;
      font-size: calc((22/768) * 100vw);
      font-weight: 500;
    }

    &__next-wrapper {
      padding: calc((50/768) * 100vw)  var(--spacer-medium);
      margin: 0 calc(var(--spacer-medium) * -1);
    }

    &__next-project {
      &::before {
        height: 98%;
      }
    }

    &__body {
      flex-direction: column;
      background-color: var(--background-color);
      overflow: hidden;
      margin: 0 calc(var(--spacer-large) * -1);
    }

    &__absolut {
      border-bottom: 1px solid var(--border-color);
      z-index: 10;
    }

    &__view-item {
      align-self: flex-end;

      &:nth-child(4n+1) {
        width: calc((200/768) * 100vw);
      }

      &:nth-child(2) {
        background: var(--gradient-to-bot);
        padding-top: 140px;
      }

      &:nth-child(2n+2) {
        width: calc((454/768) * 100vw);
      }

      &:nth-child(3n+3) {
        flex: 1 1 100%;
        display: flex;
        justify-content: center;
        padding-top: 40px;

        .project__view-inner {
          width: calc((454/768) * 100vw);
        }
      }
    }

    &__view-inner {
      display: flex;
      flex-direction: column;
    }

    &__view-image {
      width: 100%;
    }

    &__view-description {
      min-height: 75px;
    }

    &__head {
      border-bottom: 1px solid var(--border-color);
      margin: 0 calc(var(--spacer-medium) * -1);
      padding: 0 var(--spacer-medium);
    }

    &__head-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc((30 / 768) * 100vw) 0 calc((40 / 768) * 100vw);
    }

    &__button-wrapper {
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding: calc((40 / 768) * 100vw) 0 calc((40 / 768) * 100vw) 0;
    }

    &__empty-block {
      display: none;
    }

    &__author-container {
      display: none;
    }

    &__option {
      padding: calc((40 / 768) * 100vw) var(--spacer-medium) calc((60 / 768) * 100vw);
      display: flex;
      flex-direction: column;
    }

    &__location {
      padding-bottom: 19px;
    }

    &__name {
      color: var(--text-color);
      flex: 0 0 35%;
    }

    &__location, &__year, &__price {
      max-width: 465px;
      display: flex;
    }

    &__year {
      padding: 19px 0;
      border-top: 1px solid rgba(208, 208, 209, 0.8);
      border-bottom: 1px solid rgba(208, 208, 209, 0.8);
    }

    &__price {
      padding-top: 19px;
    }

    &__description {
      padding: 0 var(--spacer-medium) calc((20 / 768) * 100vw);
      background-color: var(--background-color);
    }

    &__description-title {
      padding-bottom: 20px;
    }

    &__description-subtitle {
      padding-bottom: 70px;
    }

    &__characteristics-title {
      padding-bottom: 20px;
    }

    &__param-container {
      padding: 23px 0;
      border-top: 1px solid rgba(208, 208, 209, 0.8);
      max-width: 500px;
      display: flex;
    }

    &__characteristics-list {
      display: flex;
      flex-direction: column;
    }

    &__param-title {
      flex: 0 0 50%;
    }

    &__product-card {
      .product-card__item-title {
        padding-bottom: 50px;
      }
    }

    &__picture {
      padding-bottom: 20px;
    }
  }
}

@include respond('small') {
  .project {
    &__next-wrapper {
      margin: 0 calc(var(--spacer-small) * -1);
      padding: 30px var(--spacer-small);
    }

    &__view-wrapper {
      margin: -15px calc(var(--spacer-small) * -1);
      padding: 30px var(--spacer-small);
    }

    &__button-wrapper {
      margin: 0 calc(var(--spacer-small) * -1);
      padding: 30px var(--spacer-small);
    }

    &__param-container {
      margin-right: calc(var(--spacer-small) * -1);
    }
  }
}

@include respond-down('small') {
  .project {
    &__view-list {
      margin-bottom: 10px;
    }

    &__slider {
      margin-bottom: 25px;
    }

    &__nav-text {
      padding-top: 40px;
      font-size: calc((16/375) * 100vw);
      line-height: calc((20/375) * 100vw);
      margin-bottom: calc((20/375) * 100vw);
    }

    &__next-project {
      &::before {
        height: 97%;
      }
    }

    &__nav-label {
      font-size: calc((22/375) * 100vw);
      font-weight: 500;
      margin: 0 20px 40px;
      padding: 0 35px;
      text-align: center;
    }

    &__body {
      flex-direction: column;
    }

    &__slider-post-text {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__view-item {
      margin-bottom: 27px;

      &:nth-child(4n+1) {
        max-width: calc((250/375) * 100vw);
        margin: 0 auto 27px;
      }
    }

    &__view-inner {
      align-items: center;
    }

    &__view-image {
      width: 100%;
    }

    &__view-description {
      width: 100%;
    }

    &__view-wrapper {
      border-top: 1px solid rgba(208, 208, 209, 0.8);
    }

    &__head-inner {
      padding: 20px 0;
    }

    &__button-wrapper {
      border: 1px solid var(--border-color);
    }

    &__author-container {
      display: none;
    }

    &__option {
      padding: 20px 0;
    }

    &__year {
      border-top: 1px solid rgba(208, 208, 209, 0.8);
      border-bottom: 1px solid rgba(208, 208, 209, 0.8);
    }

    &__description-title {
      margin-bottom: 20px;
    }

    &__description-subtitle {
      margin-bottom: 50px;
    }

    &__characteristics-title {
      margin-bottom: 25px;
    }

    &__param-container {
      padding: 22px 0 29px 0;
      display: flex;
      border-top: 1px solid rgba(208, 208, 209, 0.8);

      &:last-child {
        border-bottom: 1px solid rgba(208, 208, 209, 0.8);
      }
    }

    &__characteristics-list {
      margin-bottom: 50px;
    }

    &__param-title {
      padding-right: 10px;
      display: block;
      flex: 0 0 70%;
    }

    &__picture {
      padding-bottom: 7px;
      width: 100%;
    }

    &__product-card {
      .product-card__item-title {
        padding-bottom: 20px;
      }
    }
  }
}

@include respond-down('x-small') {
  .project {
    &__next-wrapper {
      margin: 0 calc(var(--spacer-smaller) * -1);
      padding: 30px var(--spacer-smaller);
    }

    &__view-wrapper {
      margin: -15px calc(var(--spacer-smaller) * -1);
      padding: 30px var(--spacer-smaller);
    }

    &__head-inner {
      padding: 20px 0;
    }

    &__button-wrapper {
      margin: 0 calc(var(--spacer-smaller) * -1);
      padding: 30px var(--spacer-smaller);
    }

    &__param-container {
      margin-right: calc(var(--spacer-smaller) * -1);
    }
  }
}