.articles-item {
  &._first {
    .articles-item__image {
      width: auto;
      margin-left: 0;
    }

    .articles-item__offer-container {
      justify-content: flex-end;
    }
  }

  &__content {
    display: flex;
    align-items: start;
  }

  &__content-container {
    position: relative;
    display: flex;
  }

  &__offer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__link-list {
    display: flex;
    flex-wrap: wrap;
    pointer-events: all;
  }

  &__name-container {
    display: block;
  }

  &__name {
    display: block;
  }

  &__short-description {
     display: block;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .articles-item {
    background-color: var(--background-color);
    padding: 80px 0 80px calc(var(--spacer-large) - 13px);

    &._first {
      padding: 80px 0 80px var(--spacer-large);

      .articles-item__content {
        flex: auto;
        margin-left: 0;
      }

      .articles-item__offer-container {
        padding: 70px calc(var(--spacer-large) - 13px);
      }

      .articles-item__name {
        width: 561/1675*100vw;
      }

      .articles-item__short-description {
        width: 523/1675*100vw;
      }
    }

    &__content-container {
      flex-direction: row-reverse;
      position: relative;
    }

    &__content {
      flex: 0 0 498/1675*100vw;
      margin-left: 68/1675*100vw;
    }

    &__image {
      width: 498/1675*100vw;
    }

    &__offer-container {
      padding-top: 43px;
    }

    &__link {
      padding: 13px;
    }

    &__name-container {
      padding: 27px 13px 0;
    }

    &__name {
      font-size: 18/1675*100vw;
    }

    &__short-description {
      margin-top: 20px;
    }

    &__details {
      margin-top: 40px;
      padding-top: 10px;
    }
  }
}

@include respond-up('medium') {
  .articles-item {
    &._first {
      .articles-item__offer-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background:
              linear-gradient(179.69deg,
                      rgba(7, 20, 51, 0) 1.34%,
                      rgba(7, 20, 51, 0.362592) 40.22%,
                      rgba(7, 20, 51, 0.73) 99.4%);
      }

      .articles-item__link-text {
        color: var(--slide-tag-color);
      }

      .articles-item__content-container {
        flex-direction: row-reverse;
        position: relative;
      }

      .articles-item__name {
        color: #ffffff;
      }

      .articles-item__details {
        display: none;
      }

      .articles-item__offer-container {
        pointer-events: none;
      }
    }

    &__content-container {
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .articles-item {
    padding: 50px 0 50px calc(var(--spacer-medium));

    &._first {
      padding: 50px 0 50px var(--spacer-medium);

      .articles-item__content {
        flex: auto;
        margin-left: 0;
      }

      .articles-item__link-text {
        background-color: transparent;

        &:not(:last-of-type) {
          margin-right: 15px;
        }
      }

      .articles-item__offer-container {
        padding: 50px 27/768*100vw;
      }

      .articles-item__name-container {
        padding-left: 13px;
      }

      .articles-item__name {
        width: 486/768*100vw;
      }

      .articles-item__short-description {
        width: 480/768*100vw;
      }
    }

    &__content-container {
      flex-direction: row-reverse;
    }

    &__content {
      flex: 0 0 312/768*100vw;
      width: 312/768*100vw;
      margin-left: 49/768*100vw;
    }

    &__offer-container {
    }

    &__link-item {
      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }

    &__name-container {
      padding-top: 20px;
    }

    &__short-description {
      margin-top: 18px;
    }
  }
}

@include respond-down('medium') {
  .articles-item {
    background-color: var(--background-color);

    &__content-container {
      flex-direction: row-reverse;
    }

    &__name {
      font-size: 15px;
    }

    &__details {
      margin-top: 20px;
      padding-top: 10px;
    }

    &__link-item {
      margin-bottom: 5px;
    }
  }
}

@include respond-down('small') {
  .articles-item {
    background-color: var(--background-color);
    padding: 30px calc(var(--spacer-small));

    &__content-container {
      flex-direction: column;
    }

    &__link-list {
      margin-top: 8px;
    }

    &__link-item {
      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }

    &__name-container {
      padding-top: 15px;
    }

    &__short-description {
      margin-top: 15px;
      color: var(--text-color);
    }
  }
}