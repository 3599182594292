.contacts-tabs {
  &__item {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      border-bottom: 2px solid var(--search-button-border);
      transition: transform var(--default-transition);
      transform-origin: right;
      transform: scale(0);
    }

    &._active {
      &::before {
        transform-origin: left;
        transform: scale(1);
      }

      .contacts-tabs {
        &__link {
          color: var(--text-color-dark);
        }
      }
    }
  }

  &__delimiter {
    color: var(--tag-color);
  }

  &__link {
    transition: color var(--default-transition);
    color: var(--tag-color);
  }
}

@include respond-up('large') {
  .contacts-tabs {
    &__item {
      &:hover {
        &::before {
          transform-origin: left;
          transform: scale(1);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-tabs {
    column-gap: 15px;

    &__item {
      &::before {
        bottom: -6px;
      }
    }
  }
}

@include respond-down('small') {
  .contacts-tabs {
    column-gap: 12px;

    &__item {
      &::before {
        bottom: -5px;
      }
    }

    &__link,
    &__delimiter {
      &.texts_form {
        font-size: 14px;
      }
    }
  }
}