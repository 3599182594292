.footer-print {
  border: 1px solid var(--text-color);
  //position: fixed;
  //bottom: 10.6mm;
  //width: -webkit-fill-available;
  display: none;
  margin-bottom: 10.6mm;
  align-items: center;
  position: relative;
  z-index: 150;

  &__text {
    margin-top: 1.4mm;
  }

  &__link {
    position: relative;
    flex: 0 1 auto;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      border-bottom: .2mm dashed var(--text-color-dark);
      left: 0;
      bottom: 0;
      height: 1px;
    }
  }

  &__print-bottom-qr {
    height: 14.5mm;
    width: 14.5mm;
    //margin: 1.4mm;
  }

  &__text-container {
    padding-top: 2.5mm;
    padding-bottom: 2.5mm;
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
  }

  &__left {
    padding-left: 3.9mm;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__right {
    border-left: 1px solid var(--text-color);
    padding-left: 4.4mm;
    padding-right: 13.1mm;
  }
}

@media print {
  .footer-print {
    display: flex;
  }
}