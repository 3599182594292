.years {
  position: relative;
  border-bottom: 1px solid var(--border-color);

  &__item {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: var(--background-color);
      border: 1px solid #000;
      z-index: 5;
    }
  }
}

@include respond-up('large') {
  .years {
    padding: 100px var(--spacer-large) 125px;

    &::before {
      top: 130px;
      left: calc((706/1675*100vw) + var(--spacer-large) + (65/1675*100vw));
    }

    &__title {
      top: 100px;
      left: var(--spacer-large);
    }

    &__list {
      grid-template-columns: repeat(2, calc(706/1675*100vw));
      grid-column-gap: calc(130/1675*100vw);
      grid-row-gap: calc(96/1675*100vw);
    }

    &__item {
      grid-row: span 2;

      &::before {
        top: 28px;
        left: calc(-1 *(65/1675*100vw));
        transform: translate3d(-50%, 0, 0);
      }

      &:nth-child(2n) {
        &::before {
          left: unset;
          right: calc(-1 *(65/1675*100vw));
          transform: translate3d(50%, 0, 0);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .years {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      border-left: 1px solid var(--border-color);
    }

    &__title {
      position: absolute;
    }

    &__list {
      display: grid;
    }

    &__item {
      grid-column: 2/3;

      &:nth-child(2n) {
        grid-column: 1/2;
      }

      &::before {

      }
    }
  }
}

@include respond('medium') {
  .years {
    padding: 50px var(--spacer-medium);

    &::before {
      top: 70px;
      left: calc((305/768*100vw) + var(--spacer-medium) + (50/768*100vw));
    }

    &__title {
      top: 50px;
      left: var(--spacer-medium);
    }

    &__list {
      grid-template-columns: repeat(2, calc(305/768*100vw));
      grid-column-gap: calc(100/768*100vw);
    }

    &__item {
      grid-row: span 4;
      margin-top: 122px;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

      &::before {
        top: 16px;
        left: calc(-1 *(50/768*100vw));
        transform: translate3d(-50%, 0, 0);
      }

      &:nth-child(2n) {
        &::before {
          left: unset;
          right: calc(-1 *(50/768*100vw));
          transform: translate3d(50%, 0, 0);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .years {

  }
}

@include respond-down('small') {
  .years {
    padding: 30px var(--spacer-small);

    &__list {
      margin-top: 20px;
      row-gap: 22px;
      display: flex;
      flex-direction: column;
    }

    &__item {
      padding-left: 35px;

      &::before {
        top: 12px;
        left: 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: 15px;
        left: 7px;
        bottom: -40px;
        border-left: 1px solid var(--border-color);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}