.project-cover {
  &__main {
    position: relative;
  }
}
@include respond-up('large') {
  .project-cover {
    &__main {
      margin-top: 10px;
    }

    &__cover {
      .cover-info {
        padding-top: 165px;
        padding-bottom: 165px;
      }
    }
  }
}

@include respond('medium') {
  .project-cover {
    &__cover {
      .cover-info {
        padding-top: 112px;
        padding-bottom: 112px;
      }
    }
  }
}

@include respond-down('medium') {
  .project-cover {
    &__main {
      margin-top: 8px;
    }
  }
}

@include respond-down('small') {
  .project-cover {
    &__cover {
      .cover-info {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 18px;

        &__text {
          flex: 0;
          margin-top: 16px;
          text-align: center;
        }
      }
    }
  }
}