.project-media {
  &__title {

  }

  &__main {

  }
}

@include respond-up('medium') {
  .project-media {
    &__main {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .project-media {
    &__main {
      margin-top: 8px;
    }
  }
}