.quiz-slide {
  flex-direction: column;
  height: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
  }

  &__prev-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
  }

  &__prev-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 17px;
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__question-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__question-image {

  }

  &__bottom-wrap {
    display: flex;
    align-items: flex-end;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background-color);
    z-index: 100;
  }

  &__progress-bar-wrap {
    flex: 0 1 100%;
  }
}

@include respond-up('large') {
  .quiz-slide {
    &__content-wrap {
      margin-top: 40px;
      padding-bottom: 80px;
      height: 100%;

      &:has(.quiz-slide__question-image-wrap) {
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        grid-column-gap: 20px;

        .quiz-slide {
          &__answer-list {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
          }
        }
      }

      &:has(.quiz-slide__color-answer) {
        .quiz-slide {
          &__question-image-wrap {
            grid-column: 1/11;
          }

          &__color-answer {
            grid-column: 11/17;
          }

          &__question-picture {
            //height: calc((958/1675 * 100vw) * 541/958);
            height: calc(541/945 * 100vh);
          }
        }
      }
    }

    &__question-image-wrap {
      grid-column: 1/12;
    }

    &__answer-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;

      &_grid {
        &:has(.quiz-slide__image-answer) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    &__bottom-padding {
      padding-bottom: 140px;
    }

    &__answer-list,
    &__text-answer,
    &__drop-down {
      grid-column: 12/17;
    }

    &__answer-item {
      grid-column: span 1;
    }

    &__buttons-wrap {
      column-gap: 21px;
    }

    &__question-picture {
      //height: calc((1056/1675 * 100vw) * 596/1056);
      height: calc(596/945 * 100vh);
    }

    &__bottom-wrap {
      column-gap: 20px;
      padding: 20px var(--spacer-large) 60px;
    }
  }
}

@include respond-up('medium') {
  .quiz-slide {
    &__prev-button {
      padding: 20px 25px 20px 30px;
    }
  }
}

@include respond('medium') {
  .quiz-slide {
    &__content-wrap {
      margin-top: 30px;
    }

    &__buttons-wrap {
      column-gap: 22px;
    }

    &__answer-list {
      row-gap: 8px;
      margin-top: 30px;

      &:has(.quiz-slide__image-answer) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 22px;
      }
    }

    &__bottom-wrap {
      column-gap: 45px;
    }

    &__question-picture {
      height: calc((710/768 * 100vw) * 400/710);
    }

    &__text-answer {
      margin-top: 35px;
    }

    &__content-wrap {
      padding-bottom: 100px;
      &:has(.quiz-slide__drop-down) {
        row-gap: 30px;
      }
    }

    &__bottom-wrap {
      padding: 12px var(--spacer-medium) 32px;
    }
  }
}

@include respond-down('medium') {
  .quiz-slide {
    &__content-wrap {
      &:has(.quiz-slide__question-image-wrap) {
        display: flex;
        flex-direction: column;
      }

      &:has(.quiz-slide__drop-down) {
        flex-direction: column-reverse;
      }
    }

    &__answer-list {
      display: flex;
      flex-direction: column;
    }

    &__bottom-wrap {
      border-top: 1px solid var(--border-color);
    }
  }
}

@include respond-down('small') {
  .quiz-slide {
    &__content-wrap {
      margin-top: 25px;
    }

    &__buttons-wrap {
      column-gap: 8px;
    }

    &__prev-button {
      padding: 14px 19px;
    }

    &__answer-list {
      row-gap: 6px;
      margin-top: 20px;

      &:has(.quiz-slide__image-answer) {
        row-gap: 10px;
      }
    }

    &__bottom-wrap {
      column-gap: 12px;
      padding: 10px var(--spacer-small) 20px;
    }

    &__question-picture {
      height: calc((335/375 * 100vw) * 189/335);
    }

    &__text-answer {
      margin-top: 20px;
    }

    &__content-wrap {
      padding-bottom: 74px;
      &:has(.quiz-slide__drop-down) {
        row-gap: 20px;
      }
    }
  }
}