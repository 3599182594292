.subtitle {
  font-family: var(--h-font);
  font-weight: 500;
  line-height: 1.85;
  text-transform: uppercase;
  color: var(--text-color-dark);
}

@include respond-up('large') {
  .subtitle {
    font-size: 15px;
  }
}

@include respond('medium') {
  .subtitle {
    font-size: 14px;
  }
}

@include respond-down('small') {
  .subtitle {
    font-size: 13px;
  }
}