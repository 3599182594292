/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:20 AM */
@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-MdCn.eot');
	src: local('Akzidenz-Grotesk Pro Med Cnd'), local('AkzidenzGroteskPro-MdCn'),
		url('AkzidenzGroteskPro-MdCn.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-MdCn.woff2') format('woff2'),
		url('AkzidenzGroteskPro-MdCn.woff') format('woff'),
		url('AkzidenzGroteskPro-MdCn.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-Bold.eot');
	src: local('Akzidenz-Grotesk Pro Bold'), local('AkzidenzGroteskPro-Bold'),
		url('AkzidenzGroteskPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-Bold.woff2') format('woff2'),
		url('AkzidenzGroteskPro-Bold.woff') format('woff'),
		url('AkzidenzGroteskPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-Md.eot');
	src: local('Akzidenz-Grotesk Pro Med'), local('AkzidenzGroteskPro-Md'),
		url('AkzidenzGroteskPro-Md.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-Md.woff2') format('woff2'),
		url('AkzidenzGroteskPro-Md.woff') format('woff'),
		url('AkzidenzGroteskPro-Md.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-MdEx.eot');
	src: local('Akzidenz-Grotesk Pro Med Ext'), local('AkzidenzGroteskPro-MdEx'),
		url('AkzidenzGroteskPro-MdEx.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-MdEx.woff2') format('woff2'),
		url('AkzidenzGroteskPro-MdEx.woff') format('woff'),
		url('AkzidenzGroteskPro-MdEx.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-LightCn.eot');
	src: local('Akzidenz-Grotesk Pro Light Cnd'), local('AkzidenzGroteskPro-LightCn'),
		url('AkzidenzGroteskPro-LightCn.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-LightCn.woff2') format('woff2'),
		url('AkzidenzGroteskPro-LightCn.woff') format('woff'),
		url('AkzidenzGroteskPro-LightCn.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-XBdCn.eot');
	src: local('Akzidenz-Grotesk Pro XBd Cnd'), local('AkzidenzGroteskPro-XBdCn'),
		url('AkzidenzGroteskPro-XBdCn.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-XBdCn.woff2') format('woff2'),
		url('AkzidenzGroteskPro-XBdCn.woff') format('woff'),
		url('AkzidenzGroteskPro-XBdCn.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-Ext.eot');
	src: local('Akzidenz-Grotesk Pro Ext'), local('AkzidenzGroteskPro-Ext'),
		url('AkzidenzGroteskPro-Ext.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-Ext.woff2') format('woff2'),
		url('AkzidenzGroteskPro-Ext.woff') format('woff'),
		url('AkzidenzGroteskPro-Ext.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-Light.eot');
	src: local('Akzidenz-Grotesk Pro Light'), local('AkzidenzGroteskPro-Light'),
		url('AkzidenzGroteskPro-Light.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-Light.woff2') format('woff2'),
		url('AkzidenzGroteskPro-Light.woff') format('woff'),
		url('AkzidenzGroteskPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-BoldEx.eot');
	src: local('Akzidenz-Grotesk Pro Bold Ext'), local('AkzidenzGroteskPro-BoldEx'),
		url('AkzidenzGroteskPro-BoldEx.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-BoldEx.woff2') format('woff2'),
		url('AkzidenzGroteskPro-BoldEx.woff') format('woff'),
		url('AkzidenzGroteskPro-BoldEx.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-XBd.eot');
	src: local('Akzidenz-Grotesk Pro ExtraBold'), local('AkzidenzGroteskPro-XBd'),
		url('AkzidenzGroteskPro-XBd.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-XBd.woff2') format('woff2'),
		url('AkzidenzGroteskPro-XBd.woff') format('woff'),
		url('AkzidenzGroteskPro-XBd.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Akzidenz-Grotesk';
	src: url('AkzidenzGroteskPro-LightEx.eot');
	src: local('Akzidenz-Grotesk Pro Light Ext'), local('AkzidenzGroteskPro-LightEx'),
		url('AkzidenzGroteskPro-LightEx.eot?#iefix') format('embedded-opentype'),
		url('AkzidenzGroteskPro-LightEx.woff2') format('woff2'),
		url('AkzidenzGroteskPro-LightEx.woff') format('woff'),
		url('AkzidenzGroteskPro-LightEx.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
