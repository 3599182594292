.product-features {
  overflow: hidden;

  &__items {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    cursor: pointer;
    grid-gap: 10px;

    &::after {
      content: '';
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    position: relative;
    counter-increment: counter;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    background-color: #fff;

    a, img, picture {
      user-select: none;
      -webkit-user-drag: none
    }
  }

  &__counter {
    position: absolute;
    left: 0;
    display: flex;
    overflow: hidden;
  }

  &__count {
    transform: translateX(-28px);
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 70px;
    line-height: 50/70*1em;
    text-transform: uppercase;
    color: var(--opacity-numbers-color);
  }

  &__name {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.85;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__strip {
    height: 1px;
    background-color: var(--border-color);
    position: relative;
  }

  &__strip-filling {
    height: 2px;
    background-color: var(--text-color-dark);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@include respond-up('large') {
  .product-features {
    padding: 0 0 125px 80/1675*100vw;
    width: calc(1112/1675*100vw - 2px);

    &__items {
      padding-top: 80px;
      padding-bottom: 47px;

      &::after {
        width: calc(var(--spacer-large) - 10px);
      }
    }

    &__item {
      padding: 57px 53px 52px 102px;
      width: 473px;
    }

    &__name {
      font-size: 15px;
      padding-bottom: 16px;
    }

    &__strip {
      margin-right: var(--spacer-large);
    }
  }
}

@include respond-up('medium') {
  .product-features {
    &__strip-filling {
      width: 283px;
    }
  }
}

@include respond('medium') {
  .product-features {
    padding-bottom: 70px;
    margin-right: calc(0px - var(--spacer-medium));

    &__items {
      padding-top: 40px;
      padding-bottom: 52px;

      &::after {
        width: calc(var(--spacer-large) - 10px);
      }
    }

    &__item {
      padding: 50px 50px 49px 102px;
      width: 426px;
    }

    &__name {
      font-size: 14px;
    }

    &__strip {
      margin-right: var(--spacer-medium);
    }
  }
}

@include respond-down('medium') {
  .product-features {
    &__name {
      padding-bottom: 14px;
    }
  }
}

@include respond-down('small') {
  .product-features {
    padding: 0 0 50px var(--spacer-small);

    &__items {
      padding-top: 22px;
      padding-bottom: 35px;

      &::after {
        width: calc(var(--spacer-small) - 10px);
      }
    }

    &__item {
      padding: 103px 24px 45px 25px;
      width: 305px;
    }

    &__counter {
      top: 19px;
    }

    &__count {
      font-size: 50px;
      line-height: 1;
      transform: translateX(-16px);
    }

    &__name {
      font-size: 13px;
    }

    &__strip {
      margin-right: var(--spacer-small);
    }

    &__strip-filling {
      width: 115px;
    }
  }
}