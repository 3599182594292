.history-contacts {
  &__addresses-wrap {
    display: flex;
  }

  &__address {
    margin-top: 16px;
  }
}

@include respond-up('large') {
  .history-contacts {
    position: relative;
    display: grid;
    grid-template-columns: calc(100%/3 + 1px) calc(2*100%/3 - 1px);
    padding: 80px 0 125px;

    &::before {
      content: '';
      height: 100%;
      border-left: 1px solid var(--border-color);
      position: absolute;
      top: 0;
      bottom: 0;
      grid-column: 2;
    }

    &__title,
    &__modal-wrap {
      grid-column: 1/2;
    }

    &__title {
      padding: 0 var(--spacer-large);
      grid-row: 1;
    }

    &__modal-wrap {
      padding: 0 var(--spacer-large);
      margin-top: 81px;
      grid-row: 2;
    }

    &__modal-button-wrap {
      margin-top: 35px;
    }

    &__contacts-wrap {
      grid-column: 2/3;
      grid-row: 1/3;
      padding: 0 var(--spacer-large) 0 80px;
    }

    &__contacts-button-wrap {
      margin-top: 65px;
    }

    &__addresses-wrap {
      column-gap: 28px;
    }

    &__address-item {
      flex: 0 0 calc(413/1675*100vw);
    }
  }
}

@include respond('medium') {
  .history-contacts {
    padding-top: 50px;

    &__contacts-button-wrap {
      margin-top: 45px;
    }
    &__title,
    &__contacts-wrap {
      padding: 0 var(--spacer-medium);
    }

    &__modal-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      padding: 30px 47px 30px var(--spacer-medium);
    }

    &__addresses-wrap {
      column-gap: calc(83/768*100vw);
    }

    &__address-item {
      flex: 0 0 calc(283/768*100vw);
    }
  }
}

@include respond-down('medium') {
  .history-contacts {
    &__addresses-wrap {
      margin-top: 22px;
    }

    &__modal-wrap {
      border-top: 1px solid var(--border-color);
    }
  }
}

@include respond-down('small') {
  .history-contacts {
    padding-top: 35px;

    &__addresses-wrap {
      flex-direction: column;
      row-gap: 30px;
    }

    &__title,
    &__contacts-wrap {
      padding: 0 var(--spacer-small);
    }

    &__contacts-button-wrap {
      margin-top: 25px;
    }

    &__modal-wrap {
      margin-top: 35px;
      padding: 30px var(--spacer-small);
    }

    &__modal-button-wrap {
      margin-top: 20px;
    }
  }
}