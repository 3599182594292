.nav {
  &__link {
    font-weight: normal;
    font-size: 15px;
  }

  &__name {
    white-space: nowrap;
  }
}

@media (max-width: 1452px) {
  .nav{
    &__item{
      &_desktop-first{
        display: none;
      }
    }
  }
}

@include respond-up('large') {
  .nav {
    &__main {
      margin-right: -17.5px;
    }

    &__items {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: 100%;
    }

    &__item {
      position: relative;

      &_mobile {
        display: none;
      }
    }

    &__link {
      position: relative;
      display: flex;
      padding: 23px 17.5px 24px;
      line-height: 15px;
      z-index: 20;

      &:hover {
        .nav__name {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s var(--default-bezier);
          }
        }

        & ~ .nav__children {
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
          visibility: visible;
        }
      }
    }

    &__name {
      position: relative;
      padding: 4px 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: var(--text-color-dark);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s ease-out;
      }
    }

    &__children {
      left: 17.5px;
      top: 100%;
      position: absolute;
      background-color: var(--nav-cildren-link-color);
      transform: translateY(20px);
      pointer-events: none;
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      transition: transform .15s, opacity .15s, visibility .15s;

      &:hover {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
        visibility: visible;
      }
    }

    &__child-link {
      display: flex;
      padding: 17px 18px 16px;
      min-width: 155px;
      &:hover {
        .nav__child-name {
          &::before {
            bottom: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s var(--default-bezier);
          }
        }
      }
    }

    &__child-name {
      white-space: nowrap;
      position: relative;
      padding: 4px 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: var(--text-color-dark);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s ease-out;
      }
    }

    &__socials-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .nav {
    &__main {
      padding: 42.5px 68px;
    }

    &__items {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    &__socials-wrap {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .nav {
    &__items {
      display: grid;
      grid-auto-flow: column;
    }

    &__link {
      display: flex;
      padding: 7.5px 0;
      line-height: 1.55;
    }

    &__item {
      &_mobile {
        display: list-item;
      }

      &_desktop {
        display: none;
      }
    }

    &__socials-wrap {
      display: flex;
      align-items: center;
      column-gap: 15px;
    }

    &__contact {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: var(--text-color-dark);
        }
      }
    }
  }
}

@include respond-down('small') {
  .nav {
    &__main {
      padding: 32.5px var(--spacer-small) 38.5px;
    }

    &__items {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    }

    &__socials-wrap {
      margin-top: 45px;
    }
  }
}