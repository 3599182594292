.profile-cover {
  &__main {
    position: relative;
  }

  &__avatar {
    position: absolute;
    display: inline-block;
  }
}

@include respond-up('large') {
  .profile-cover {
    padding-bottom: 60px;

    &__main {
      margin-top: 10px;
    }

    &__avatar {
      left: 45px;
      bottom: -56px;
    }
  }
}

@include respond('medium') {
  .profile-cover {
    padding-bottom: 40px;

    &__avatar {
      left: 30px;
      bottom: -46px;
    }
  }
}

@include respond-down('medium') {
  .profile-cover {
    &__main {
      margin-top: 8px;
    }
  }
}

@include respond-down('small') {
  .profile-cover {
    padding-bottom: 50px;

    &__avatar {
      left: 20px;
      bottom: -50px;
    }
  }
}