.reviews-page{
  &__list{
    display: grid;
    box-shadow: inset 0 -1px 0 0 var(--border-color);
  }

  &__item{
    box-shadow: inset 0 -1px 0 0 var(--border-color);
  }
}

@include respond-up('large'){
  .reviews-page{
    &__list{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@include respond('medium'){
  .reviews-page{
    &__list{
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include respond-down('small'){
  .reviews-page{
    &__list{
      grid-template-columns: 1fr;
    }
  }
}