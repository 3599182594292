.partners {
  &__title {
    display: block;
  }

  &__filter-form {
    display: flex;

    .form-field {
      label {
        display: none;
      }

      select {
        border-bottom-width: 1px;
        background-position: calc(100% - 4px) calc(50% + -3px);
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    border-top: 1px solid var(--border-color);
  }

  &__data {
    display: flex;
    align-items: center;
  }

  &__avatar-image {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  &__avatar-picture, &__char {
    display: flex;
    border-radius: 50%;
    overflow: hidden;
  }

  &__char {
    background-color: var(--none-avatar-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 28px;
    line-height: 1.21;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  &__name {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
    text-transform: uppercase;
    color: #161A22;
    display: block;
  }

  &__specialization {
    display: block;
  }

  &__city {
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
  }

  &__city-icon {
    margin-right: 15px;
  }

  &__projects {
    display: flex;
    flex-wrap: nowrap;
  }

  &__project-image {
    object-fit: cover;
    object-position: center;
  }

  &__project-picture {
    overflow: hidden;
    display: flex;
  }

  &__null {
    height: 100px;
    display: flex;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-color);
  }
}

@include respond-up('large') {
  .partners {
    &__title {
      padding-bottom: 58px;
    }

    &__filter-form {
      .form-field {
        &:not(:last-of-type) {
          margin-right: 79px;
        }

        select {
          padding: 5px 0 10px;
          font-size: 18px;
        }
      }
    }

    &__info-wrapper {
      flex: 0 0 calc(100% / 3 + 1px);
      padding: 77px var(--spacer-large);
    }

    &__data {
      padding-bottom: 46px;
    }

    &__avatar-picture, &__char {
      flex: 0 0 95/1675*100vw;
      height: 95/1675*100vw;
    }

    &__info {
      margin-left: 35/1675*100vw;
    }

    &__name {
      font-size: 18px;
      padding-bottom: 1px;
      padding-top: 5px;
    }

    &__projects {
      padding: 77px var(--spacer-large) 80px;
      background-color: var(--background-color);
    }

    &__project {
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    &__project-picture {
      flex: 0 0 316/1675*100vw;
      height: 228/1675*100vw;
    }

    &__null {
      padding: var(--spacer-large);
    }
  }
}

@include respond-up('medium') {
  .partners {
    &__filter-form {
      .form-field {
        width: 300px;
      }
    }
  }
}

@include respond('medium') {
  .partners {
    &__title {
      padding-bottom: 34px;
    }

    &__filter-form {
      .form-field {
        &:not(:last-of-type) {
          margin-right: 65px;
        }

        select {
          padding: 5px 0 9px;
        }
      }
    }

    &__item {
      flex-direction: column;
    }

    &__info-wrapper {
      justify-content: space-between;
      align-items: flex-end;
      padding: 50px 102/768*100vw 0 var(--spacer-medium);
    }

    &__projects {
      padding: 40px var(--spacer-medium) 50px;
    }

    &__project {
      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }

    &__project-picture {
      flex: 0 0 226/768*100vw;
      height: 164/786*100vw;
    }

    &__null {
      padding: var(--spacer-medium);
    }
  }
}

@include respond-down('medium') {
  .partners {
    &__filter-form {
      .form-field {
        select {
          font-size: 15px;
        }
      }
    }

    &__item {
      background-color: var(--background-color);
    }

    &__info-wrapper {
      display: flex;
    }

    &__avatar-picture, &__char {
      flex: 0 0 75px;
      height: 75px;
    }

    &__info {
      margin-left: 30px;
    }

    &__name {
      font-size: 16px;
      padding-bottom: 6px;
    }
  }
}

@include respond-down('small') {
  .partners {
    &__title {
      padding-bottom: 21px;
    }

    &__filter-form {
      flex-direction: column;

      .form-field {
        margin-bottom: 21px;

        &:last-of-type {
          margin-bottom: 30px;
        }

        select {
          padding: 5px 0 9px;
        }
      }
    }

    &__item {
      flex-direction: column;
    }

    &__info-wrapper {
      flex-direction: column;
      padding: 30px var(--spacer-small) 0;
    }

    &__data {
      padding-bottom: 20px;
    }

    &__projects {
      overflow: hidden;
      padding: 30px 0;
      margin-left: var(--spacer-small);
      cursor: pointer;

      &::after {
        content: '';
        flex: 0 0 var(--spacer-small);
        height: 10px;
      }
    }

    &__project {
      flex: 0 0 186px;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }

    &__project-picture {
      flex: 0 0 186px;
      height: 134px;
    }

    &__null {
      padding: var(--spacer-small);
    }
  }
}