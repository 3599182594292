.contacts-main-block {
  width: 100%;
}

@include respond-up('large') {
  .contacts-main-block {
    &__content {
      margin-top: 56px;
    }

    &__info-wrap {
      padding: 0 calc(183/1675*100vw) 0 80px;
    }

    &__managers-wrap {
      padding: 0 var(--spacer-large) 0 80px;
    }

    &__tabs {
      padding: 0 var(--spacer-large) 0 80px;
    }
  }
}

@include respond-up('medium') {
  .contacts-main-block {

  }
}

@include respond('medium') {
  .contacts-main-block {
    &__content {
      margin-top: 46px;
    }
  }
}

@include respond-down('medium') {
  .contacts-main-block {

  }
}

@include respond-down('small') {
  .contacts-main-block {
    &__content {
      margin-top: 36px;
    }
  }
}