.manager-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    border: 1px solid var(--border-color);
    border-top: unset;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__position {
    color: var(--text-color);
    margin-top: 6px;
  }

  &__line {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
  }

  &__email {
    margin-top: 8px;
  }

  &__socials-wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .manager-card {
    &__picture {
      height: calc((311/1675 * 100vw) * 322/311);
    }

    &__text-wrap {
      padding: 18px 20px 20px;
    }

    &__line {
      margin: 16px 0;
    }
  }
}

@include respond('medium') {
  .manager-card {
    &__picture {
      height: calc((227/768 * 100vw) * 235/227);
    }

    &__text-wrap {
      padding: 14px 12px 16px;
    }

    &__line {
      margin: 14px 0;
    }
  }
}

@include respond-down('small') {
  .manager-card {
    &__picture {
      height: calc((152/375 * 100vw) * 157/152);
    }

    &__text-wrap {
      padding: 12px 10px 14px;
    }

    &__line {
      margin: 10px 0;
    }
  }
}