.review-item {
  display: grid;
  grid-template-columns: 72px auto;
  grid-template-rows: 24px repeat(5, auto);
  align-items: start;
  align-content: start;
  grid-template-areas:
    "avatar date"
    "avatar name"
    "text-block text-block"
    "original original"
    "project project";

  &__avatar {
    grid-area: avatar;
  }

  &__photo {
    width: 100%;
    height: 72px;
    border-radius: 100px;
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    };
  }

  &__char {
    width: 100%;
    height: 72px;
    background-color: var(--none-avatar-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 34/28*1em;
    text-transform: uppercase;
    color: white;
    border-radius: 100px;
  }

  &__date {
    grid-area: date;
    font-weight: 500;
    font-size: 12px;
    line-height: 10/12*1em;
    color: var(--text-color);
    font-family: var(--h-font);
  }

  &__name {
    grid-area: name;
    color: var(--text-color-dark);
    font-weight: 500;
    font-size: 15px;
    line-height: 1.75em;
    text-transform: uppercase;
    font-family: var(--h-font);
  }

  &__text {
    grid-area: text-block;
  }

  &__original {
    grid-area: original;
  }

  &__project {
    grid-area: project;
    background-color: white;
    display: flex;
    padding: 8px;
    align-items: center;
    width: 251px;
    margin-top: 30px;
  }

  &__project-picture {
    flex: 0 0 35px;
    height: 35px;
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
      color: black;
    };
  }

  &__project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__project-label {
    flex: 1 1 auto;
    text-align: center;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33em;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }
}

@include respond-up('large') {
  .review-item{
    padding: 80px var(--spacer-large);

    &__date{
      padding-left: 35px;
    }

    &__name{
      padding-left: 35px;
    }

    &__text{
      padding-top: 36px;
    }

    &__original{
      margin-top: 50px;
    }
  }
}

@include respond-up('medium'){
  .review-item{
    height: 100%;
  }
}

@include respond('medium'){
  .review-item{
    padding: 50px var(--spacer-medium);
  }
}

@include respond-down('medium'){
  .review-item{
    &__date{
      padding-left: 25px;
    }

    &__name{
      padding-left: 25px;
    }

    &__text{
      padding-top: 30px;
    }

    &__original{
      margin-top: 40px;
    }
  }
}

@include respond('small'){
  .review-item {
    padding: 30px var(--spacer-small);
  }
}

@include respond-down('x-small'){
  .review-item {
    padding: 30px var(--spacer-smaller);
  }
}