.document {
  &__link {
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 100%;

    picture {
      height: 100%;
      display: flex;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name-wrap {

  }

  &__name {
    font-size: 14px;
    line-height: 24/14*1em;
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .document {
    &__link {
      width: 208px;

      &:hover {
        .document {
          &__name {
            border-bottom-color: var(--text-color-border);
          }
        }
      }
    }

    &__image {
      height: 295px;
    }

    &__name-wrap {
      margin-top: 18px;
    }

    &__name {
      border-bottom: 1px solid var(--t);
      transition: border-bottom-color var(--default-transition);
    }
  }
}

@include respond-down('medium') {
  .document {
    &__link {
      width: 176px;
    }

    &__image {
      height: 249px;
    }

    &__name-wrap {
      margin-top: 16px;
    }
  }
}