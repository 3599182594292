.tag {
  display: inline-block;
  color: var(--tag-color);
  font-family: var(--h-font);
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
}

@include respond-up('large') {
  .tag {
    font-size: 11px;
    line-height: 10/11*1em;
    transition: color .25s ease-in-out;

    &:hover {
      color: var(--text-color-dark);
    }

    &_white {
      color: var(--text-color-light);

      &:hover {
        color: #fff;
      }
    }
  }
}

@include respond-down('medium') {
  .tag {
    background-color: #fff;
    padding: 5px 10px;
    font-size: 9px;
    line-height: 10/9*1em;
    letter-spacing: 0.02em;
  }
}