.title, h1, .h1, h2, .h2 {
  font-weight: 500;
  text-transform: uppercase;
}

.title, h1, .h1 {
  font-family: var(--h-font);
  line-height: 155%;
  color: var(--main-text-color);
}

.title_small, h3, .h3 {
  font-weight: 400;
  text-transform: none;
  line-height: 175%;
}

.title_smallest, h4, .h4 {
  font-weight: 500;
}

.title_thin {
  font-weight: 400;
  text-transform: none;
}

@include respond-up('large') {
  .title, h1, .h1 {
    font-size: 42/1675*100vw;
  }

  .title_medium, h2, .h2 {
    font-size: 30/1675*100vw;
  }

  .title_smallest, h4, .h4 {
    font-size: 22/1675*100vw;
  }
}

@include respond('medium') {
  .title, h1, .h1 {
    font-size: 30/768*100vw;
  }

  .title_medium, h2, .h2 {
    font-size: 22/768*100vw;
  }

  .title_small, h3, .h3 {
    font-size: 18/768*100vw;
  }

  .title_smallest, h4, .h4 {
    font-size: 14/768*100vw;
  }
}

@include respond('small') {
  .title, h1, .h1 {
    font-size: calc((100vw - 375px)/(767 - 375) * (30 - 22) + 22px);
  }

  .title_medium, h2, .h2 {
    font-size: 18/375*100vw;
  }

  .title_small, h3, .h3 {
    font-size: 16/375*100vw;
  }

  .title_smallest, h4, .h4 {
    font-size: 14/375*100vw;
  }
}

@include respond-down('x-small') {
  .title, h1, .h1 {
    font-size: 22px;
  }

  .title_medium, h2, .h2 {
    font-size: 18px;
  }

  .title_small, h3, .h3 {
    font-size: 16px;
  }

  .title_smallest, h4, .h4 {
    font-size: 14px;
  }
}