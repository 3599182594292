.materials {
  &__title {
    display: block;
  }

  &__inner {
    display: grid;
    border-top: 1px solid var(--border-color);
    margin-bottom: -1px;
  }

  &__item {
    display: flex;
    border-bottom: 1px solid var(--border-color);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__name {
    display: block;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
    text-transform: uppercase;
  }

  &__description {
    display: block;
  }
}

@include respond-up('large') {
  .materials {
    &__title {
      padding-bottom: 75px;
    }

    &__inner {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__item {
      flex-direction: column;
      padding: 80px 65/1675*100vw;
    }

    &__picture {
      margin-bottom: 35px;
    }

    &__name {
      font-size: 18px;
      padding-bottom: 15px;
    }
  }
}

@include respond('medium') {
  .materials {
    &__title {
      padding-bottom: 30px;
    }

    &__item {
      padding: 40px var(--spacer-medium) 50px;
      align-items: center;
    }

    &__link {
      flex: 0 0 283/768*100vw;
    }

    &__picture {
      width: 100%;
    }

    &__text-wrapper {
      margin-left: 62/768*100vw;
    }

    &__name {
      padding-bottom: 12px;
    }
  }
}

@include respond-down('medium') {
  .materials {
    &__inner {
      grid-template-columns: 1fr;
    }

    &__item {
      background-color: var(--background-color);
    }

    &__name {
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .materials {
    &__title {
      padding-bottom: 20px;
    }

    &__item {
      flex-direction: column;
      padding: 30px var(--spacer-small);
    }

    &__picture {
      width: 283px;
      margin-bottom: 25px;
    }
  }
}