.profile-form {
  &__main {

  }

  &__block {
    &:not(:first-child) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__bottom {
    position: sticky;
    bottom: 0;
    z-index: 10;

    .flash {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
    }
  }

  &__save {
    background-color: var(--background-color);
  }

  &__save-button {
    background-color: var(--t);
  }
}