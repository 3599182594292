.question-heading {
  display: flex;

  &__counter-wrap {
    background: var(--button-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  &__title-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    color: var(--button-color);
  }
}

@include respond-up('large') {
  .question-heading {
    &__title-wrap {
      padding-left: 30px;
    }
  }
}

@include respond-up('medium') {
  .question-heading {
    &__counter-wrap {
      flex: 0 0 60px;
      height: 60px;
    }

    &__title-wrap {
      row-gap: 12px;
      padding-top: 16px;
      border-top: 1px solid var(--button-color);
    }
  }
}

@include respond('medium') {
  .question-heading {
    &__title-wrap {
      padding-left: 25px;
    }
  }
}

@include respond-down('medium') {
  .question-heading {

  }
}

@include respond-down('small') {
  .question-heading {
    flex-direction: column;
    row-gap: 15px;
    position: relative;

    &__counter-wrap {
      width: 62px;
      height: 35px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-top: 1px solid var(--button-color);
        width: 100%;
      }
    }

    &__title-wrap {
      row-gap: 10px;
    }
  }
}
