.catalog-brand {
  &__list-container {
    border-top: 1px solid var(--border-color);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item {
    flex: 0 0 33.33333%;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@include respond-up('large') {
  .catalog-brand {
    &__info {
      display: flex;
      justify-content: space-between;
    }

    &__offer-brand-container {
      flex: 0 0 calc(495/1545 * 100%);
      width: calc(493/1545 * 100%);
      padding-top: 80px;
    }

    &__offer-brand-image {
      max-width: calc(200/1675 * 100vw);
    }

    &__offer-description-container {
      padding: 0 263px 0 83px;
      flex: 0 0 calc(1050/1545 * 100%);
      width: calc(1051/1545 * 100%);
      background-color: var(--background-color);
    }

    &__offer-description {
      margin-top: 70px;
    }

    &__offer-description-wrapper {
      padding: 80px 0 100px 0;
      min-width: 670px;
    }

    &__offer-text {
      margin-bottom: 70px;
    }

    &__list {
      margin: 80px calc(65/1675 * -100vw) 70px;
    }

    &__list-item {
      padding: 0 calc(65/1675 * 100vw) 83px;
    }
  }
}

@include respond('medium') {
  .catalog-brand {
    &__info {
      background-color: var(--background-color);
      padding-top: 50px;
      padding-bottom: 70px;
    }

    &__offer-brand-link {
      margin-bottom: 30px;
      display: inline-block;
    }

    &__offer-brand-image {
      max-width: calc(139/765 * 100vw);
    }

    &__offer-description {
      margin-top: 30px;
    }

    &__offer-text {
      margin-bottom: 30px;
    }

    &__list {
      margin: 40px calc(30/765 * -100vw) 20px;
    }

    &__list-item {
      padding: 0 calc(30/765 * 100vw) 40px;
    }
  }
}

@include respond-down('small') {
  .catalog-brand {
    &__info {
      background-color: var(--background-color);
      padding-top: 40px;
      padding-bottom: 10px;
    }

    &__offer-brand-link {
      margin-bottom: 20px;
      display: inline-block;
    }

    &__offer-brand-image {
      max-width: calc(139/375 * 100vw);
    }

    &__offer-text {
      margin-bottom: 40px;
    }

    &__list {
      margin: 40px calc(30/765 * -100vw) 20px;
    }

    &__list-item {
      flex: 0 0 50%;
      padding: 0 calc(14/375 * 100vw) 40px;
    }
  }
}