.recall-form {
  &__field {
    position: relative;
  }

  &__success {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
  }

  &__success-title {
    text-align: center;
  }

  &__success-subtitle {
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .recall-form {
    &__success {
      padding: 125px 0 189px;
    }

    &__success-title {
      max-width: 589px;
      padding: 35px 0 20px;
    }

    &__success-subtitle {
      font-size: 19px;
      line-height: 1.84;
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &__success-icon {
      svg {
        width: 65px;
      }
    }
  }
}

@include respond('medium') {
  .recall-form {
    &__success {
      padding: 70px 0 96px;
    }



    &__success-title {
      max-width: 429px;
      padding: 30px 0 15px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    &__success-subtitle {
      font-size: 15px;
      line-height: 1.75;
    }

    &__button-presentation {
      margin-bottom: 20px;
      min-width: 271px;
    }
  }
}

@include respond-down('small') {
  .recall-form {
    &__success {
      padding: 40px 0;
    }

    &__success-title {
      max-width: 320px;
      padding: 30px 0 15px;
      box-sizing: content-box;
    }
  }
}

