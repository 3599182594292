.projects-main {
  border-bottom: 1px solid var(--border-color);

  &__title {
    overflow: hidden;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__list-item {
    &:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-child(2) {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  &__link-wrapper {
    display: flex;
  }
}

@include respond-up('large') {
  .projects-main {
    &__title {
      padding-top: 100px;
      margin-bottom: 65px;
    }

    &__list {
      grid-column-gap: calc(var(--spacer-large) * 2);
      align-items: end;
    }

    &__list-item {
      margin-bottom: 137px;

      &:first-child {
        align-self: stretch;
        display: flex;
        align-items: flex-end;
      }

      &:nth-child(2) {
        margin-top: -290px;

        .projects-item__description {
          display: none;
        }
      }

      &:nth-child(3) {
        margin: 0 auto 139px;
      }
    }

    &__link-wrapper {
      justify-content: center;
    }
  }
}

@include respond('medium') {
  .projects-main {
    &__title {
      padding: 50px 0 41px;
    }

    &__list-wrapper {
      margin: 0 calc(var(--spacer-medium) * -1);
      padding: 0 var(--spacer-medium);
    }

    &__list {
      grid-column-gap: calc(var(--spacer-medium) * 2);
      align-items: end;
      margin-bottom: 20px;
    }

    &__list-item {
      margin-bottom: 20px;
      align-self: stretch;
      display: flex;
      align-items: flex-end;

      //&:nth-child(1) {
      //  width: calc((452/765) * 100vw);
      //}
      //
      //&:nth-child(2) {
      //  width: calc((196/765) * 100vw);
      //}

      &:nth-child(3) {
        //width: calc((505/765) * 100vw);
        margin: 0 auto 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .projects-main {
    &__link-wrapper {
      flex-direction: column;
      background-color: var(--background-color);
    }
  }
}

@include respond-down('small') {
  .projects-main {
    &__title {
      padding: 30px 0 20px;
    }

    &__list {
      grid-column-gap: calc(var(--spacer-small) * 2);
      align-items: end;
      margin-bottom: 20px;
    }

    &__list-item {
      grid-column-start: 1 !important;
      grid-column-end: 4 !important;
      margin-bottom: 40px;

      //&:nth-child(2n+1) {
      //  width: calc((320/375) * 100vw);
      //}

      &:nth-child(2n) {
        width: calc((245/375) * 100vw);
        margin: 0 auto 40px;
      }
    }
  }
}