.articles-main {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    overflow: hidden;
  }

  &__container {
    display: flex;
  }

  &__main-article {
    width: 100%;
    object-fit: cover;
    background-color: var(--background-color);
  }

  &__bar {
    display: flex;
    flex-direction: column;
  }

  &__articles {
    display: flex;
  }

  &__link-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__link-text {
    white-space: nowrap;
    color: var(--main-tag-color);
  }

  &__name-container {
    display: flex;
    align-items: center;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__picture {
    display: flex;
  }
}

@include respond-up('large') {
  .articles-main {
    &__title {
      padding: 100px var(--spacer-large) 0;
      margin-bottom: 65px;
    }

    &__main-article._first {
      flex: 0 0 calc(2 * 100% / 3 - 1px);
      padding: 0 var(--spacer-large) 145px ;

      .articles-item__picture {
        width: 100%;
        height: 650/1675*100vw;
      }

      .articles-item__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__bar {
      margin-top: 9px;
      width: 100%;
      justify-content: space-between;
    }

    &__articles {
      flex-direction: column;
      padding-bottom: calc((100vw - 1200px)/(1675 - 1200) * (144 - 90) + 90px);
    }

    &__article {
      padding: calc((100vw - 1200px)/(1675 - 1200) * (36 - 25) + 25px) var(--spacer-large) calc((100vw - 1200px)/(1675 - 1200) * (39 - 25) + 25px);
      border-bottom: 1px solid var(--border-color);

      &:first-of-type {
        border-top: 1px solid var(--border-color);
      }
    }

    &__link-list {
      padding-bottom: calc((100vw - 1200px)/(1675 - 1200) * (21 - 17) + 17px);
    }

    &__link-item {
      &:first-of-type {
        margin-right: 25px;
      }
    }

    &__link {
      flex: 0 0 auto;
    }

    &__name-container {
      &:hover {
        .articles-main__name {
          background-size: 100% 100%;
          background-position: left;
        }
      }
    }

    &__picture {
      flex: 0 0 calc((100vw - 1200px)/(1675 - 1200) * (96 - 81) + 81px);
      height: calc((100vw - 1200px)/(1675 - 1200) * (96 - 81) + 81px);
      margin-right: 35/1675*100vw;
    }

    &__name {
      font-size: 16/1675*100vw;
      line-height: 1.87;
      background: {
        image: linear-gradient(transparent calc(100% - 1px), var(--text-color-dark) 1px);
        repeat:no-repeat;
        size: 0 100%;
        position: right;
      };
      padding-bottom: 2px;
      transition: background-size var(--default-transition);
    }
  }
}

@include respond('medium') {
  .articles-main {
    &__title {
      padding: 70px var(--spacer-medium) 40px;
    }

    &__main-article._first {
      flex: 0 0 calc(2 * 100% / 3 - 1px);
      padding: 0 var(--spacer-medium) 50px;

      .articles-item__picture {
        width: 100%;
      }
    }

    &__article {
      flex: 0 0 50%;
      padding: 31px var(--spacer-medium) 70px;
      background-color: var(--background-color);

      &:first-of-type {
        border-right: 1px solid var(--border-color);
      }
    }
  }
}

@include respond-down('medium') {
  .articles-main {
    &__container {
      flex-direction: column;
    }

    &__main-article {
      border-bottom: 1px solid var(--border-color);
    }

    &__articles {
      transform: translate3d(0, 0, 0) !important;
    }

    &__article {
      &:nth-of-type(3) {
        display: none;
      }
    }

    &__link-item {
      &:first-of-type {
        margin-right: 5px;
      }
    }

    &__name-container {
      margin-top: 20px;
    }

    &__picture {
      margin-right: 20px;
      flex: 0 0 81px;
      height: 81px;
    }

    &__name {
      font-size: 14px;
      line-height: 1.75;
    }
  }
}

@include respond-down('small') {
  .articles-main {
    &__title {
      padding: 40px var(--spacer-small) 20px;
    }

    &__main-article._first {
      padding: 0 var(--spacer-small) 30px;
    }

    &__articles {
      flex-direction: column;
    }

    &__article {
      padding: 30px var(--spacer-small);

      &:first-of-type {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }
}