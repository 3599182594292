.simple-search {
  &__picture {
    display: flex;
  }

  &__list {

  }

  &__item {
    &_with-icon {

    }

    &_product {

    }
  }

  &__icon {

  }

  &__link {
    display: flex;
    align-items: center;
    padding: 0 40px;

    &:hover {
      .search-name-link {
        &::after {
          transition: transform .3s var(--default-bezier) .33s;
          transform: scaleX(1);
        }

        &::before {
          transition: transform .3s var(--default-bezier);
          transform: scaleX(0);
        }
      }
    }
  }

  &__image {

  }

  &__name {
    font-weight: normal;
    line-height: 1.2em;
  }

  &__show-all-button {
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33em;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    display: block;
    margin-top: 15px;
    text-align: center;
    border-top: 1px solid #D0D0D1;
    color: black;
    transition: var(--default-transition);
    z-index: 1;
  }

  &__not-found {
    font-weight: normal;
    line-height: 1.2em;
  }
}

@include respond-up('large') {
  .simple-search {
    &__show-all-button-wrapper {
      position: relative;
      transition: var(--default-transition);

      &:hover {
        .simple-search__show-all-button {
          color: #fff;
          z-index: 1;
        }
        .simple-search__show-all-button-filter {
          transform: scaleY(1);
        }
      }
    }

    &__show-all-button {
      position: relative;
      padding: 20px 0;
    }

    &__show-all-button-filter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      height: 100%;
      width: 100%;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform 0.3s ease-in-out;
    }

    &__list {
      margin: -15px 0;
      padding: 40px 0 25px;
    }

    &__item {
      &_with-icon {
        padding: 11px 0;
      }

      &_product {
        padding: 15px 0;
      }
    }

    &__link {
      padding: 0 40px;
    }

    &__show-all-button {
      margin-top: 15px;
    }

    &__image {
      max-height: 42px;
      max-width: 93px;
      margin-right: 24px;
    }

    &__icon {
      margin-right: 19px;

      &_article {
        padding-left: 3px;
        margin-right: 23px;
      }
    }

    &__name {
      font-size: 15px;
    }

    &__not-found {
      font-size: 15px;
      padding: 40px;
    }
  }
}

@include respond-down('medium') {
  .simple-search {
    &__show-all-button {
      padding: 18px 0;
    }

    &__list {
      margin: -10px 0;
      padding: 15px 0 10px;
    }

    &__item {
      &_with-icon {
        padding: 10px 0;
      }

      &_product {
        padding: 10px 0;
      }
    }

    &__link {
      padding: 0 20px;
    }

    &__show-all-button {
      margin-top: 10px;
    }

    &__image {
      max-height: 34px;
      max-width: 75px;
      margin-right: 15px;
    }

    &__icon {
      margin-right: 17px;
    }

    &__name {
      font-size: 14px;
    }

    &__not-found {
      font-size: 14px;
      padding: 20px;
    }
  }
}

@include respond-down('small') {
  .simple-search {

  }
}