.producers {
  &__title {
    display: block;
  }

  &__item {
    display: flex;
    border-top: 1px solid var(--border-color);
  }

  &__info-wrapper {
    display: flex;
  }

  &__logo-picture {
    display: flex;
  }

  &__logo-image {
    object-fit: contain;
    width: auto;
    height: 100%;
  }

  &__subcategories {
    overflow-x: scroll;
    display: flex;
    cursor: pointer;

    &::after {
      content: '';
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__subcategories-list {
    display: flex;
    flex-wrap: nowrap;
  }

  &__subcategories-link, &__subcategories-picture, &__subcategories-image {
    user-select: none;
    -webkit-user-drag: none;
    display: block;
  }

  &__subcategories-image {
    object-fit: cover;
    pointer-events: none;
  }

  &__subcategories-picture {
    display: flex;
    pointer-events: none;
  }

  &__subcategories-name {
    font-size: 15px;
    line-height: 1.55;
  }

  &__link-wrapper {
    margin-top: 24px;
  }
}

@include respond-up('large') {
  .producers {
    &__title {
      padding-bottom: 75px;
    }

    &__info-wrapper {
      padding: 80px var(--spacer-large) 88px;
      flex: 0 0 calc(100%/3 + 1px);
      flex-direction: column;
    }



    &__logo-picture {
      margin-bottom: 40px;
      //width: 143/1675*100vw;
      height: 80px;
      width: 200px;
      //min-width: 139px;
      //min-height: 55px;
    }

    &__name {
      padding-bottom: 15px;
    }

    &__subcategories {
      background-color: var(--background-color);
      padding: 80px var(--spacer-large) 83px 0;
      margin-left: var(--spacer-large);
      width: 100%;

      &::after {
        flex: 0 0 var(--spacer-large);
        height: 100%;
      }
    }

    &__subcategories-item {
      flex: 0 0 290px;

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    &__subcategories-picture {
      width: 290px;
      height: 175px;
      margin-bottom: 18px;
    }
  }
}

@include respond('medium') {
  .producers {
    &__title {
      padding-bottom: 30px;
    }

    &__info-wrapper {
      padding: 50px var(--spacer-medium) 41px;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__text {
      flex: 0 0 466/768*100vw;
    }

    &__subcategories {
      margin-left: var(--spacer-medium);
      padding-bottom: 50px;

      &::after {
        flex: 0 0 var(--spacer-medium);
      }
    }

    &__subcategories-item {
      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
}

@include respond-down('medium') {
  .producers {
    &__list {
      background-color: var(--background-color);
    }

    &__item {
      flex-direction: column;
    }

    &__logo-picture {
      width: 139px;
      height: 55px;
    }

    &__name {
      padding-bottom: 10px;
    }

    &__subcategories {
      overflow: scroll hidden;
    }

    &__subcategories-item {
      flex: 0 0 208px;
    }

    &__subcategories-picture {
      width: 208px;
      height: 125px;
      margin-bottom: 10px;
    }
  }
}

@include respond-down('small') {
  .producers {
    &__title {
      padding-bottom: 20px;
    }

    &__info-wrapper {
      flex-direction: column;
      padding: 30px var(--spacer-small);
    }

    &__logo-picture {
      margin-bottom: 20px;
    }

    &__subcategories {
      margin-left: var(--spacer-small);
      padding-bottom: 30px;

      &:after {
        flex: 0 0 var(--spacer-small);
      }
    }

    &__subcategories-item {
      &:not(:last-of-type) {
        margin-right: 11px;
      }
    }
  }
}