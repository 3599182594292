.round-slider-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .36);
  width: 45px;
  height: 45px;

  &_right {
    transform: rotate(180deg);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 17px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.swiper-button-disabled {
    opacity: .5;
    pointer-events: none;
  }
}

@include respond-up('large') {
  .round-slider-arrow {

  }
}

@include respond-up('medium') {
  .round-slider-arrow {

  }
}

@include respond('medium') {
  .round-slider-arrow {

  }
}

@include respond-down('medium') {
  .round-slider-arrow {

  }
}

@include respond-down('small') {
  .round-slider-arrow {

  }
}