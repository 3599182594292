/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$spacer-smaller: 22/320*100;
$spacer-small: 28/375*100;
$spacer-medium: 30/768*100;
$spacer-large: 65/1675*100;
$spacer-large-profile: 80/1675*100;

$grid: (
        x-small: (
                from: 0,
                to: 374,
                spacer: $spacer-smaller
        ),
        small: (
                from: 375,
                to: 767,
                spacer: $spacer-small
        ),
        medium: (
                from: 768,
                to: 1199,
                spacer: $spacer-medium
        ),
        large: (
                from: 1200,
                to: 1920,
                spacer: $spacer-large
        ),
        x-large: (
                from: 1921,
                spacer: $spacer-large
        )
);

:root {
  --max-row-width: 1280;

  --spacer-smaller: #{$spacer-smaller}vw;
  --spacer-small: #{$spacer-small}vw;
  --spacer-medium: #{$spacer-medium}vw;
  --spacer-large: #{$spacer-large}vw;
  --spacer-large-profile: #{$spacer-large-profile}vw;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Muller", sans-serif;
  --h-font: "Akzidenz-Grotesk", sans-serif;

  --t: transparent;

  --text-content-width: calc(800 / 1675 * 100vw);

  --primary-color: #D32020;
  --alert-color: #E07575;
  --alert-bg-color: #F0E0E0;
  --success-color: #649A20;
  --success-bg-color: #E5F0E0;
  --default-transition: .4s ease-in-out;
  --default-bezier: cubic-bezier(.25, .1, .25, 1);
  --primary-color-opacity: rgba(1, 199, 103, .85);

  --background-color: #F4F4F4;

  --search-button-border: #161A22;

  --text-color: #787A7D;
  --text-color-dark: #161A22;
  --text-color-light: rgba(255, 255, 255, .65);
  --text-color-border: #CFCFCF;
  --tag-color: #9C9C9E;
  --slide-text-color: rgba(255, 255, 255, .67);
  --slide-tag-color: rgba(255, 255, 255, .53);
  --slide-title-color: #FFFFFF;
  --slide-strip-color: rgba(255, 255, 255, .45);
  --slide-strip-filling-color-dark: rgba(22, 26, 24, .5);

  --button-color: #ED6B23;
  --border-light-white-color: rgba(255, 255, 255, .3);

  --hot-color: #C4BB9B;
  --cold-color: #84A2B2;

  --border-color: #D0D0D1;
  --border-color-light: rgba(255, 255, 255, .4);

  --slider-line-bg-color: var(--border-color);
  --slider-line-color: #161A22;

  --menu-link-color: rgba(22, 26, 34, .31);
  --menu-numbers-color: #E6E6E6;
  --menu-line-color: #D02C23;
  --nav-cildren-link-color: #E3E3E3;

  --opacity-numbers-color: rgba(230, 230, 230, 0.75);

  --nav-cildren-link-color-light: rgba(255, 255, 255, .1);

  --form-border-color: #C8CBCF;
  --form-radio-border-color: #C1C1C1;
  --form-radio-background-color: #1F2129;

  --footer-text-color: #989A9C;

  --circle-color: #DDD;

  --none-avatar-color: #DADADA;

  --main-tag-color: rgba(120, 122, 125, 0.5);

  --gradient-to-top: linear-gradient(var(--background-color) 0, var(--background-color) 50%, var(--t) 50%, var(--t) 100%);
  --gradient-to-bot: linear-gradient(to bottom, var(--background-color) 0, var(--background-color) 50%, var(--t) 50%, var(--t) 100%);

  --status-error-bg-color: #FFF1F1;
  --status-error-text-color: #CB5353;
  --status-draft-bg-color: #ECF4F5;
  --status-draft-text-color: #7398A0;
  --status-acceptance-bg-color: #FFF9EF;
  --status-acceptance-text-color: #D0A461;
  --status-publication-bg-color: #F2F9E9;
  --status-publication-text-color: #88AE58;
}

.no-screen {
  display: none;
}

@media print {
  .no-screen {
    display: block;
  }

  @page {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    size: A4;
    size: portrait;
    margin: 0; /* this affects the margin in the printer settings */

    a[href]:after {
      content: none !important;
    }

    #Header, #Footer {
      display: none !important;
    }
  }

  body {
    display: table;
    table-layout: fixed;
    padding-top: 2.5cm;
    padding-bottom: 2.5cm;
    height: auto;
  }

  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }
}
