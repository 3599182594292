.add-field {
  position: relative;

  &__field {

  }

  &__remover {
    position: absolute;
    right: 4px;
    bottom: 28px;
    display: flex;
    background-color: var(--t);
  }
}