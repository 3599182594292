@font-face {
    font-display: swap;
    font-family: 'Muller';
    src: url('MullerRegular.eot');
    src: url('MullerRegular.eot?#iefix') format('embedded-opentype'),
        url('MullerRegular.woff2') format('woff2'),
        url('MullerRegular.woff') format('woff'),
        url('MullerRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Muller';
    src: url('MullerMedium.eot');
    src: url('MullerMedium.eot?#iefix') format('embedded-opentype'),
        url('MullerMedium.woff2') format('woff2'),
        url('MullerMedium.woff') format('woff'),
        url('MullerMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

