.production-contacts {
  &__main{
    background-color: var(--background-color);
  }
}

@include respond-up('large') {
  .production-contacts {
    padding-bottom: 125px;
    border-bottom: 1px solid var(--border-color);
    &__map{
      height: 604px;
      margin: 0 65px;
    }

    &__main{
      padding-top: 100px;
      padding-left: 80px;
      display: flex;
    }

    &__main-item{
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-up('medium') {
  .production-contacts {

  }
}

@include respond('medium') {
  .production-contacts {
    background-color: var(--background-color);
    border-bottom: 1px solid var(--border-color);
    padding: 50px var(--spacer-medium);
    &__title{
      padding-bottom: 30px;
    }

    &__main{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-gap: 76px;
    }

    &__map {
      height: 277px;
    }
  }
}

@include respond-down('medium') {
  .production-contacts {

  }
}

@include respond-down('small') {
  .production-contacts {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-color);
    &__wrap{
      padding: 30px var(--spacer-small);
    }

    &__title{
      padding-bottom: 22px;
    }

    &__map{
      height: 328px;
      width: 100%;
    }
  }
}