.developers {
  &__our-values-slider-wrap {
    border-top: 1px solid var(--border-color);
  }

  &__links-wrap {
    background: var(--background-color);
  }

  &__quote-wrap {
    background: var(--background-color);
  }

  &__picture {
    display: flex;
  }

  &__image {
    height: 604/1675*100vw;
    width: 100%;
    object-fit: cover;
  }

  &__try-vizio-wrap {
    border-bottom: 1px solid var(--border-color);
  }
}

@include respond-up('large') {
  .developers {
    &__links-wrap {
      padding-top: 80px;
    }

    &__image-block {
      padding: 0 var(--spacer-large) 126px;
    }

    &__reviews-wrap {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        border-left: 1px solid var(--border-color);
        right: 1/3*100%;
      }
    }
  }
}

@include respond-up('medium') {
  .developers {

  }
}

@include respond('medium') {
  .developers {
    &__image-block {
      padding: 0 var(--spacer-medium) 48px;
      background: var(--background-color);
    }

    &__links-wrap {
      padding-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .developers {
  }
}

@include respond-down('small') {
  .developers {
    &__image-block {
      padding: 0 var(--spacer-small) 30px;
    }

    &__links-wrap {
      padding-top: 30px;
    }
  }
}