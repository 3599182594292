.avatar-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #727272;
  border: 6px solid var(--background-color);
  border-radius: 100px;
  cursor: pointer;
  overflow: hidden;

  &__icon {
    display: flex;
  }

  &__text {
    line-height: 1.35;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
}

@include respond-up('large') {
  .avatar-info {
    height: 136px;
    width: 136px;
    padding: 32px 28px;

    &__text {
      margin-top: 10px;
    }
  }
}

@include respond-up('medium') {
  .avatar-info {
    &__text {
      font-size: 13px;
    }
  }
}

@include respond-down('medium') {
  .avatar-info {
    height: 110px;
    width: 110px;
    padding: 18px 14px;

    &__text {
      margin-top: 8px;
    }
  }
}

@include respond-down('small') {
  .avatar-info {
    &__text {
      font-size: 12px;
    }
  }
}