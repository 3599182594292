.history-page {

}

@include respond-up('large') {
  .history-page {

  }
}

@include respond-up('medium') {
  .history-page {
    &__sticky-link-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .history-page {

  }
}

@include respond-down('medium') {
  .history-page {

  }
}

@include respond-down('small') {
  .history-page {
    &__sticky-link-wrap {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      transform: translate3d(0, 100%, 0);
      transition: transform var(--default-transition);

      &._sticky {
        transform: translate3d(0, 0, 0);
      }
    }

    &__sticky-link {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--background-color);
      border-top: 1px solid var(--border-color);
      padding: 12px;
      width: 100%;
    }

    &__sticky-link-text {
      font-family: var(--h-font);
      font-size: 11px;
      font-weight: 500;
      line-height: 133%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }

    &__sticky-link-icon {
      margin-left: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 11px;
      height: 15px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}