.contacts-managers {
  &__list {
    display: grid;
  }

  &__item {
    grid-column: span 1;
  }
}

@include respond-up('large') {
  .contacts-managers {
    &__list {
      grid-column-gap: 19px;
      grid-row-gap: 50px;
    }
  }
}

@include respond-up('medium') {
  .contacts-managers {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include respond('medium') {
  .contacts-managers {
    &__list {
      grid-column-gap: 15px;
      grid-row-gap: 30px;
    }
  }
}

@include respond-down('small') {
  .contacts-managers {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }
  }
}