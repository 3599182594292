.product-design {
  display: flex;
  flex-wrap: wrap;

  &__subtext {
    line-height: 1.71;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
  }
}

@include respond-up('large') {
  .product-design {
    padding-bottom: 125px;

    &__column {
      padding-bottom: 63px;

      &:first-of-type {
        flex: 0 0 609/1675*100vw;
      }

      &:nth-of-type(2) {
        flex: 0 0 500/1675*100vw;
      }
    }

    &__title {
      padding: 0 72/1675*100vw 26px 86/1675*100vw;
    }

    &__text {
      padding: 17px 88/1675*100vw 0 0;

      &_short {
        padding: 0 72/1675*100vw 0 90/1675*100vw;
      }
    }

    &__image-block {
      flex: 0 0 100%;
      padding-left: 80/1675*100vw;
    }

    &__picture {
      margin-bottom: 20px;
    }
  }
}

@include respond-up('medium') {
  .product-design {
    justify-content: space-between;
  }
}

@include respond('medium') {
  .product-design {
    padding-bottom: 70px;

    &__column {
      padding-bottom: 40px;

      &:first-of-type {
        flex: 0 0 367/768*100vw;
        padding-right: 48/768*100vw;
      }

      &:nth-of-type(2) {
        flex: 0 0 334/768*100vw;
      }
    }

    &__image-block {
      flex: 0 0 100%;
    }
  }
}

@include respond-down('medium') {
  .product-design {
    &__title {
      padding-bottom: 22px;
    }

    &__picture {
      margin-bottom: 14px;
    }
  }
}

@include respond-down('small') {
  .product-design {
    flex-direction: column;
    padding: 0 var(--spacer-small) 50px;

    &__title {
      padding-bottom: 22px;
    }

    &__text {
      padding-bottom: 35px;

      &_short {
        padding-bottom: 20px;
      }
    }
  }
}