.tg-widget {
  position: fixed;
  left: var(--spacer);
  bottom: var(--spacer);
  z-index: 1100;

  &__closer-wrap {
    position: absolute;
    z-index: 1000;
    right: 35px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: none;

    &._visible {
      display: block;
    }
  }

  &__closer {
    position: relative;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background: var(--border-color);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@include respond-up('large') {
  .tg-widget {
    --spacer: var(--spacer-large);

    &__closer-wrap {
      top: 35px;
    }
  }
}

@include respond-up('medium') {
  .tg-widget {

  }
}

@include respond('medium') {
  .tg-widget {
    --spacer: var(--spacer-medium);
  }
}

@include respond-down('medium') {
  .tg-widget {
    &__closer-wrap {
      top: 25px;
    }
  }
}

@include respond('small') {
  .tg-widget {
    --spacer: var(--spacer-small);
  }
}

@include respond-down('x-small') {
  .tg-widget {
    --spacer: var(--spacer-smaller);
  }
}