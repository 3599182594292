.workers {
  border-top: 1px solid var(--border-color);
  &__text-block {
    border-right: 1px solid var(--border-color);
  }

  &__list {
    overflow: hidden;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 175%;
  }

  &__description {
    line-height: 24px;
  }

  &__items {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    cursor: pointer;

    &::-webkit-scrollbar {
      display: none;
    }
    &::after {
      content: '';
    }
  }

  &__item {
    position: relative;
    counter-increment: counter;
    display: flex;
    flex-direction: column;
    pointer-events: none;

    a, img, picture {
      user-select: none;
      -webkit-user-drag: none
    }
  }

  &__name {
    line-height: 24px;
  }

  &__picture {
    width: 100%;
    padding-bottom: calc(322 / 311 * 100%);
    display: block;
    position: relative;
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__strip {
    height: 1px;
    background-color: var(--border-color);
    position: relative;
  }

  &__strip-filling {
    height: 2px;
    background-color: var(--text-color-dark);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@include respond-up('large') {
  .workers {
    display: flex;
    &__text-block {
      flex: 0 0 33.333%;
      padding: 100px 65px 0 var(--spacer-large);
    }

    &__title {
      margin-bottom: 27px;
    }


    &__description {
      font-size: 14px;

    }

    &__list {
      width: calc(1112/1675*100vw - 2px);
      overflow: hidden;
    }

    &__photo {
      margin-bottom: 20px;
    }

    &__list {
      flex: 0 0 66.666%;
      padding: 100px 0 125px 65px;
    }

    &__items {
      grid-template-columns: auto;
      margin-bottom: 60px;
      grid-gap: 20px;
      &::after {
        width: calc(var(--spacer-large) - 10px);
      }
    }

    &__item {
      width: 311px;
      height: 100%;
    }

    &__name {
      font-size: 17px;
      margin-bottom: 4px;
    }

    &__strip {
      margin-right: var(--spacer-large);

    }
  }
}
@include respond-up('medium') {
  .workers {
    &__strip-filling {
      width: 283px;
    }
  }
}

@include respond('medium') {
  .workers {
    flex-direction: column;
    &__text-block {
      padding: 50px var(--spacer-medium) 0;
      border: none;
    }

    &__title {
      margin-bottom: 22px;
    }

    &__subtitle {
      margin-bottom: 40px;
    }

    &__description {
      font-size: 14px
    }

    &__items {
      padding-left: var(--spacer-medium);
      grid-column-gap: 15px;
      margin-bottom: 45px;
      &::after {
        width: calc(var(--spacer-large) - 10px);
      }
    }

    &__item {
      width: 225px;
    }

    &__name {
      font-size: 15px;
      margin-bottom: 8px;
    }

    &__photo {
      margin-bottom: 14px;
    }

    &__strip {
      margin: 0 var(--spacer-medium) 50px;
    }
  }
}
@include respond-down('small') {
  .workers {
    &__text-block {
      padding: 30px var(--spacer-small) 0;
    }

    &__title {
      margin-bottom: 22px;
    }

    &__list {
      padding-right: var(--spacer-small);
    }

    &__subtitle {
      margin-bottom: 30px;
    }

    &__name {
      font-size: 15px;
    }

    &__description {
      font-size: 14px;
      margin-bottom: 35px;
    }

    &__items {
      padding-left: var(--spacer-small);
      grid-column-gap: 16px;
    }

    &__item {
      width: 152px;
    }

    &__strip {
      margin:0 var(--spacer-small) 30px;
    }

    &__strip-filling {
      width: 116px;
    }
  }
}