.product-slider {
  display: flex;

  .tns-inner {
    display: flex;
  }

  &__slider-wrap {
    flex-direction: column;
    background: var(--background-color);
    width: 100%;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__nav {
    margin: 0;
  }

  &__strip {
    flex: 1 1 auto;
    margin: 0;
  }

  &__slider-name {
    display: flex;
  }
}

@include respond-up('large') {
  .product-slider {
    &__info-wrapper {
      flex: 0 0 calc(100% / 3 + 1px);
      padding: 100px var(--spacer-large);
    }

    &__slider-wrap {
      overflow: hidden;
      padding: 100px 0 70px 80px;

      .tns-outer {
        .tns-inner {
          margin-right: 80px !important;
        }
      }
    }

    &__slide {
      width: calc(290 / 1675 * 100vw + 20px);

      &:last-child {
        width: calc(290 / 1675 * 100vw + 40px + var(--spacer-large));

        a {
          display: flex;
          flex-direction: column;
          width: calc(290 / 1675 * 100vw + 20px);
        }
      }
    }


    &__picture {
      height: calc((290 / 1675 * 100vw) * 175 / 290);
    }

    &__nav-wrap {
      padding-right: var(--spacer-large);
      margin-top: 50px;
    }

    &__slider-name {
      margin-top: 18px;
    }
  }
}

@include respond-up('medium') {
  .product-slider {
    &__nav {
      column-gap: 45px;
    }
  }
}

@include respond('medium') {
  .product-slider {
    padding-bottom: 48px;
    background: var(--background-color);

    &__slider-wrap {
      .tns-inner {
        margin-left: var(--spacer-medium) !important;
        margin-right: var(--spacer-medium) !important;
      }
    }

    &__title {
      padding: 52px var(--spacer-medium) 40px;
    }

    &__slide {
      width: calc(208 / 768 * 100vw + 15px);

      &:last-child {
        width: calc(208 / 768 * 100vw + 30px + var(--spacer-medium));

        a {
          display: flex;
          flex-direction: column;
          width: calc(208 / 768 * 100vw + 30px);
        }
      }
    }

    &__picture {
      height: calc((208 / 768 * 100vw) * 125 / 208);
    }

    &__nav-wrap {
      padding: 0 var(--spacer-medium);
    }
  }
}

@include respond-down('medium') {
  .product-slider {
    flex-direction: column;

    &__title {
      background: var(--background-color);
    }

    &__slider-name {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .product-slider {
    padding: 30px 0;

    &__slider-wrap {
      .tns-inner {
        margin-left: var(--spacer-small) !important;
        margin-right: var(--spacer-small) !important;
      }
    }

    &__title {
      padding: 30px var(--spacer-small);
    }

    &__slide {
      width: calc(208 / 375 * 100vw + 11px);

      &:last-child {
        width: calc(208 / 375 * 100vw + 22px + var(--spacer-small));

        a {
          display: flex;
          flex-direction: column;
          width: calc(208 / 375 * 100vw + 22px);
        }
      }
    }

    &__picture {
      height: calc((208 / 375 * 100vw) * 125 / 208);
    }

    &__nav-wrap {
      padding: 0 var(--spacer-small);
    }

    &__arrows {
      display: none;
    }
  }
}