.citation {
  &__inner {
    display: flex;
  }

  &__image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__circle {
    background-color: var(--circle-color);
    border-radius: 50%;
    position: absolute;
    bottom: 0;
  }

  &__picture {
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 0 0 1000px 1000px;
    overflow: hidden;
    z-index: 2;
  }

  &__name {
    display: block;
    text-align: center;
  }

  &__profession {
    display: block;
    text-align: center;
    color: var(--text-color);
  }

  &__text-block {
    border-left: 1px solid var(--border-color);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: {
        image: url('../svg/quote.svg');
        size: cover;
        repeat: no-repeat;
      };
    }
  }

  &__text {
    font-family: var(--h-font);
    white-space: pre-line;
    line-height: 1.75;
  }
}

@include respond-up('large') {
  .citation {
    padding: 120px 282/1675*100vw 120px 158/1675*100vw;

    &__image-block {
      flex: 0 0 350/1675*100vw;
    }

    &__image-wrapper {
      margin-bottom: 25px;
    }

    &__circle {
      width: 237px;
      height: 237px;
    }

    &__picture {
      width: 237px;
    }

    &__name {
      font-size: 17px;
      line-height: 1.41;
      margin-bottom: 5px;
    }

    &__profession {
      font-size: 14px;
    }

    &__text-block {
      margin-top: 16px;
      flex: 0 0 695/1675*100vw;
      padding-left: 40/1675*100vw;

      &::before {
        width: 30px;
        height: 21px;
        top: 0;
        left: -60px;
      }
    }

    &__text {
      font-size: 17px;
    }
  }
}

@include respond-up('medium') {
  .citation {
    &__inner {
      justify-content: space-between;
      align-items: flex-start;
    }

    &__profession {
      line-height: 2.14;
    }
  }
}

@include respond('medium') {
  .citation {
    padding: 50px 48/768*100vw 51px 28/768*100vw;

    &__image-block {
      flex: 0 0 165/678*100vw;
    }

    &__image-wrapper {
      margin-bottom: 14px;
    }

    &__name {
      margin-bottom: 8px;
    }

    &__text-block {
      flex: 0 0 410/768*100vw;
      padding-left: 30/768*100vw;

      &::before {
        top: 4px;
        left: -52px;
      }
    }
  }
}

@include respond-down('medium') {
  .citation {
    &__name {
      font-size: 15px;
      line-height: 175%;
    }

    &__profession {
      font-size: 14px;
      line-height: 1.55;
    }

    &__circle {
      width: 157px;
      height: 157px;
    }

    &__picture {
      width: 157px;
    }

    &__text-block {
      &::before {
        width: 27px;
        height: 19px;
      }
    }

    &__text {
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .citation {
    padding: 30px var(--spacer-small);

    &__inner {
      flex-direction: column-reverse;
      align-items: center;
    }

    &__image-wrapper {
      margin-bottom: 10px;
    }

    &__name {
      margin-bottom: 6px;
    }

    &__text-block {
      padding-left: 20/375*100vw;
      margin-left: 48/375*100vw;
      margin-bottom: 38px;

      &::before {
        top: 4px;
        left: -50px;
      }
    }
  }
}