@include respond-up('large') {
  .header-phone {
    &__callback {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .header-phone {
    &__callback {
      &_desktop {
        display: none;
      }
    }
  }
}
