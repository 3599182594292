.texts {
  &_form {
    //styleName: Форма;
    text-transform: uppercase;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
  }

  &_heading-small {
    //styleName: Заголовки мал;
    text-transform: uppercase;
    font-family: var(--h-font);
    font-size: 12px;
    font-weight: 500;
    line-height: 175%;
  }

  &_contacts-title {
    //styleName: Заголовки мал;
    text-transform: uppercase;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 176%;
    letter-spacing: 0.3px;
  }

  &_employee {
    //styleName: Сотрудник;
    font-family: var(--font);
    font-weight: 400;
    line-height: 125%;
  }

  &_card-info {
    //styleName: Инфа на карточку;
    font-family: var(--font);
    font-weight: 400;
    line-height: 125%;
  }

  &_text-small {
    //styleName: Мелкие портянки;
    font-family: var(--font);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    color: var(--text-color);
  }

  &_award {
    //styleName: Награда;
    font-family: var(--font);
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.03em;
  }

  &_award-name {
    //styleName: Название награды;
    font-family: var(--font);
    font-weight: 500;
    letter-spacing: -0.03em;
  }

  &_quote {
    //styleName: Цитата;
    font-family: var(--h-font);
    font-weight: 400;
    line-height: 175%;
  }

  &_phrase {
    //styleName: Фраза;
    font-family: var(--h-font);
    font-weight: 400;
    line-height: 155%;
    text-align: center;
  }

  &_number-big {
    //styleName: крупная цифирь;
    font-family: var(--h-font);
    font-weight: 400;
    line-height: 125%;
  }

  &_event {
    //styleName: Описание события;
    font-family: var(--font);
    font-weight: 400;
    line-height: 29.75px;
  }

  &_service {
    //styleName: Услуги;
    font-family: var(--h-font);
    font-size: 15px;
    font-weight: 400;
    line-height: 155%;
  }

  &_welcome {
    font-family: var(--h-font);
    font-weight: 500;
    //line-height: 155%;
    text-transform: uppercase;
    letter-spacing: -0.04em;
  }

  &_welcome-title {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 144%;
    text-transform: uppercase;
    letter-spacing: -0.04em;
  }

  &_description-contacts {
    font-family: var(--font);
    font-weight: 400;
    line-height: 176%;
    letter-spacing: 0.6px;
  }

  &_text-big {
    //styleName: Крупный текст;
    font-family: var(--font);
    font-weight: 400;
    line-height: 175%;
  }

  &_answers {
    //styleName: Варианты ответа;
    font-family: var(--font);
    font-weight: 400;
    line-height: 135%;
  }

  &_quiz-title {
    //styleName: Заголовок конфигуратор;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 135%;
    text-transform: uppercase;
  }

  &_quiz-description {
    //styleName: Дескрипшен квиз;
    font-family: var(--font);
    font-weight: 400;
    line-height: 135%;
  }

  &_quiz-fraction {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 26px;
  }

  &_quiz-progress {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
  }

  &_quiz-slider-label {
    font-family: var(--font);
    font-weight: 500;
    line-height: 125%;
  }

  &_quiz-slider-tabs {
    font-family: var(--font);
    font-weight: 500;
    line-height: 135%;
  }

  &_subheading {
    //styleName: Подзаголовок;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 185%;
    text-transform: uppercase;
  }
}

@include respond-up('large') {
  .texts {
    &_form {
      font-size: 22px;
    }

    &_employee {
      font-size: 17px;
    }

    &_quote {
      font-size: 17px;
    }

    &_phrase {
      font-size: 34px;
    }

    &_number-big {
      font-size: 52px;
    }

    &_event {
      font-size: 17px;
    }

    &_welcome {
      font-size: 30px;
    }

    &_welcome-title {
      font-size: 22px;
    }

    &_description-contacts {
      font-size: 17px;
    }

    &_contacts-title {
      font-size: 14px;
    }

    &_text-big {
      font-size: 19px;
    }

    &_answers {
      font-size: 18px;
    }

    &_quiz-title {
      font-size: 24px;
    }

    &_quiz-fraction {
      font-size: 20px;
    }

    &_quiz-slider-label {
      font-size: 18px;
    }

    &_quiz-slider-tabs {
      font-size: 16px;
    }

    &_subheading {
      font-size: 15px;
    }
  }
}

@include respond-up('medium') {
  .texts {
    &_card-info {
      font-size: 14px;
    }

    &_award {
      font-size: 14px;
    }

    &_award-name {
      font-size: 15px;
      line-height: 135%;
    }

    &_quiz-description {
      font-size: 19px;
    }

    &_quiz-progress {
      font-size: 17px;
    }
  }
}

@include respond('medium') {
  .texts {
    &_form {
      font-size: 18px;
    }

    &_employee {
      font-size: 15px;
    }

    &_phrase {
      font-size: 24px;
    }

    &_number-big {
      font-size: 38px;
    }

    &_welcome-title {
      font-size: 18px;
    }

    &_description-contacts {
      letter-spacing: 0;
      line-height: 185%;
    }

    &_welcome {
      font-size: 22px;
      letter-spacing: -0.01em;
    }

    &_contacts-title {
      line-height: 176%;
      letter-spacing: -0.05em;
    }

    &_quiz-title {
      font-size: 20px;
    }

    &_quiz-slider-label {
      font-size: 14px;
    }

    &_quiz-slider-tabs {
      font-size: 13px;
    }

    &_subheading {
      font-size: 14px;
    }
  }
}

@include respond-down('medium') {
  .texts {
    &_quote {
      font-size: 15px;
    }

    &_event {
      font-size: 15px;
    }

    &_description-contacts {
    }

    &_text-big {
      font-size: 16px;
    }

    &_answers {
      font-size: 16px;
    }

    &_quiz-fraction {
      font-size: 18px;
    }
  }
}

@include respond-down('small') {
  .texts {
    &_form {
      font-size: 16px;
    }

    &_employee {
      font-size: 14px;
    }

    &_card-info,
    &_award {
      font-size: 13px;
    }

    &_award-name {
      font-size: 14px;
      line-height: 140%;
    }

    &_phrase {
      font-size: 18px;
    }

    &_number-big {
      font-size: 32px;
    }

    &_welcome-title {
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0;
    }

    &_welcome {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 150%;
    }

    &_description-contacts {
      letter-spacing: 0;
    }

    &_contacts-title {
      font-size: 14px;
      letter-spacing: 0;
    }

    &_quiz-title {
      font-size: 18px;
    }

    &_quiz-description {
      font-size: 16px;
    }

    &_quiz-progress {
      font-size: 15px;
    }

    &_quiz-slider-label {
      font-size: 13px;
    }

    &_quiz-slider-tabs {
      font-size: 12px;
    }

    &_subheading {
      font-size: 13px;
    }
  }
}