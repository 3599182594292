@include respond-up('large') {
  .about-content {
    display: flex;

    &__bar {
      flex: 0 0 1/3*100%;
      padding: 0 var(--spacer-large);
      border-right: 1px solid var(--border-color);

      &_clear {
        border-right: none;
      }
    }

    &__sticky {
      padding: 100px 0;
    }

    &__main {
      flex: 0 0 2/3*100%;
    }
  }
}