.download-link {
  display: inline-flex;
  align-items: center;
  font-family: var(--h-font);
  font-weight: 700;
  font-size: 11px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--text-color-dark);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  &__text {
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      background-color: var(--text-color-dark);
      bottom: -2px;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }
  }
}

@include respond-up('large') {
  .download-link {
    &:hover {
      .download-link {
        &__text {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s var(--default-bezier);
          }
        }
      }
    }
  }
}