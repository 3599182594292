.start-block {
  background: var(--background-color);
  border-bottom: 1px solid var(--border-color);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EBEBEB;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    line-height: 155%;
  }

  &__subtitle {
    text-align: center;
  }
}

@include respond-up('large') {
  .start-block {
    padding: 60px var(--spacer-large);

    &__wrap {
      padding: 60px;
    }

    &__icon {
      width: 80px;
      height: 80px;
    }

    &__title {
      margin-top: 30px;
    }

    &__subtitle {
      margin-top: 20px;
      width: calc(941/1675 * 100vw);
    }

    &__button {
      margin-top: 35px;
    }
  }
}

@include respond('medium') {
  .start-block {
    padding: 50px var(--spacer-medium);

    &__wrap {
      padding: 40px;
    }

    &__title {
      margin-top: 35px;
    }

    &__subtitle {
      margin-top: 16px;
      width: calc(585/768 * 100vw);
    }

    &__button {
      margin-top: 25px;
    }
  }
}

@include respond-down('medium') {
  .start-block {
    &__icon {
      width: 60px;
      height: 60px;
    }
  }
}

@include respond-down('small') {
  .start-block {
    padding: 30px var(--spacer-small);

    &__wrap {
      padding: 35px 18px;
    }

    &__title {
      margin-top: 30px;
      text-align: center;
    }

    &__subtitle {
      margin-top: 12px;
    }

    &__button {
      margin-top: 20px;
    }
  }
}
