.flash {
  &__item {
    display: flex;
    align-items: center;
    padding: 16px 20px 14px;

    &.error {
      color: var(--primary-color);
      background-color: var(--alert-bg-color);
    }

    &.success {
      color: var(--success-color);
      background-color: var(--success-bg-color);
      opacity: 0;
      transform: translateY(100%);
      transition: opacity var(--default-transition) 4s, transform var(--default-transition) 4s;
    }
  }

  &__icon {
    margin-right: 11px;
  }
}