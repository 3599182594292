.categories {
  background-color: var(--background-color);

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: 100%;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--border-color);
    }
  }

  &__pictures-item {
    &._active {
      z-index: 1;
    }
  }

  &__name {
    display: block;
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 1.55;
    text-transform: uppercase;
  }

  &__image {
    height: 100%;
  }

  &__picture {
    display: flex;
  }
}

@include respond-up('large') {
  .categories {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid var(--border-color);

    &__list {
      width: 503/1675*100vw;
      padding: 100px 144/1675*100vw 93px 160/1675*100vw;
      box-sizing: content-box;
    }

    &__item {
      &::before {
        font-size: 60px;
        height: 60px;
        left: calc(0px - 160*100vw/1675 - 13px);
      }

      .categories__picture {
        display: none;
      }
    }

    &__name {
      padding: 45px 0;
      font-size: 23/1675*100vw;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: var(--text-color-dark);
      }
    }

    &__pictures-list {
      display: grid;
      grid-template-areas: 'picture';
      margin-top: 70px;
      margin-bottom: 70px;
      overflow: hidden;
      position: sticky;
      top: 0;
      left: 0;
    }

    &__pictures-item {
      position: relative;
      overflow: hidden;
      grid-area: picture;
      transition-timing-function: ease-in-out;
    }

    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__picture {
      position: sticky;
      top: 0;
      left: 0;
      width: 868/1675*100vw;
      height: 100vh;
    }
  }
}

@include respond-up('medium') {
  .categories {
    &__item {
      position: relative;
      counter-increment: counter;

       &::before {
         position: absolute;
         content: counters(counter, ".", decimal-leading-zero);
         font-family: var(--h-font);
         font-style: normal;
         font-weight: 500;
         line-height: 1;
         text-transform: uppercase;
         color: var(--menu-numbers-color);
         margin: auto 0;
         top: 0;
         bottom: 0;
       }
    }
  }
}

@include respond('medium') {
  .categories {
    padding: 50px var(--spacer-medium) 20px 75/786*100vw;

    &__item {
      &::before {
        font-size: 30px;
        height: 30px;
        left: -80/768*100vw;
      }
    }

    &__name {
      font-size: 18/768*100vw;
      padding: 20px 0;
    }

    &__picture {
      margin-left: 40/768*100vw;
    }
  }
}

@include respond-down('medium') {
  .categories {
    &__name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--text-color-dark);
      padding: 20px 0;
    }

    &__pictures-list {
      display: none;
    }

    &__picture {
      flex: 0 0 80px;
      height: 80px;
    }
  }
}

@include respond-down('small') {
  .categories {
    padding: 19px var(--spacer-small) 0;

    &__list {
      border-bottom: 1px solid var(--border-color);
    }

    &__name {
      font-size: 14/375*100vw;
    }

    &__picture {
      margin-left: 32/375*100vw;
    }
  }
}

@include respond-down('x-small') {
  .categories {
    &__name {
      font-size: 14px;
    }
  }
}