.profile-projects {
  display: flex;
  flex-direction: column;

  &__nav-list {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;

    &::after {
      content: '';
      flex: 0 0 var(--spacer-large-profile);
      height: 10px;
    }
  }

  &__nav-link {
    display: block;

    &._active {
      .profile-projects__nav-link-text {
        &::before {
          left: 0;
          transform-origin: left;
          transform: scaleX(1);
        }
      }
    }
  }

  &__nav-link-text {
    position: relative;
    display: block;
    padding-bottom: 10px;
    font-weight: 400;
    white-space: nowrap;
    transition: color var(--default-transition);

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: var(--text-color-dark);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }
  }

  &__link {
    border-top: none;
  }

  &__list {
    border-top: 1px solid var(--border-color);
    display: grid;
  }

  &__item {
    background-color: #fff;
  }

  &__info-wrapper {
    display: flex;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__status {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
    text-transform: uppercase;
    padding: 10px 15px;

    &._error {
      background-color: var(--status-error-bg-color);
      color: var(--status-error-text-color);
    }

    &._draft {
      background-color: var(--status-draft-bg-color);
      color: var(--status-draft-text-color);
    }

    &._acceptance {
      background-color: var(--status-acceptance-bg-color);
      color: var(--status-acceptance-text-color);
    }

    &._publication {
      background-color: var(--status-publication-bg-color);
      color: var(--status-publication-text-color);
    }
  }

  &__name {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    display: flex;
  }

  &__controls {
    border-top: 1px solid var(--border-color);
    display: flex;
  }

  &__delimiter-wrepper {
    display: flex;
    align-items: center;
  }

  &__delimiter {
    display: block;
    width: 1px;
    height: 19px;
    background-color: var(--border-color);
  }

  &__button {
    .download-link__icon {
      margin-left: 0;
      margin-right: 8px;
    }

    .download-link__text {
      font-weight: 500;
    }
  }

  &__comment-wpapper {
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
  }

  &__comment {
    background-color: var(--status-error-bg-color);
    font-size: 14px;
    line-height: 1.75;
    color: var(--status-error-text-color);
  }

  &__empty {
    border-top: 1px solid var(--border-color);
    color: var(--text-color-dark);

    span {
      line-height: 1.67px;
    }
  }
}

@include respond-up('large') {
  .profile-projects {
    &__nav {
      margin: 0 calc(0px - var(--spacer-large-profile));
    }

    &__nav-list {
      width: calc(200vw / 3 - var(--spacer-large-profile) - 6px);
      padding-top: 40px;
      padding-left: var(--spacer-large-profile);
    }

    &__nav-item {
      &:not(:last-of-type) {
        .profile-projects__nav-link {
          padding-right: 30px;
        }
      }

      &:not(:first-of-type) {
        .profile-projects__nav-link {
          padding-left: 30px;
        }
      }
    }

    &__nav-link {
      &:hover {
        .profile-projects__nav-link-text {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s var(--default-bezier);
          }
        }
      }
    }

    &__link {
      &:hover {
        svg {
          line {
            stroke: #fff;
          }
        }
      }

      svg {
        line {
          transition: stroke 0.3s ease-in-out;
        }
      }
    }

    &__list {
      padding: 82px var(--spacer-large) 175px var(--spacer-large-profile);
      grid-gap: 38px;
    }

    &__item {
    }

    &__info-wrapper {
      padding: 40px 32/1675*100vw 40px 40/1675*100vw;
    }

    &__status {
      font-size: 10px;
      margin-bottom: 25px;
    }

    &__name {
      font-size: 20px;
    }

    &__button {
      padding: 20px 25px;

      &:first-of-type {
        padding-left: 40/1675*100vw;
      }
    }

    &__comment-wpapper {
      padding: 20px 32/1675*100vw 20px 40/1675*100vw;
    }

    &__comment {
      padding: 20px 25/1675*100vw;
    }

    &__empty {
      padding: 40px var(--spacer-large-profile);
      font-size: 18px;
    }
  }
}

@include respond-up('medium') {
  .profile-projects {
    &__info-wrapper {
      justify-content: space-between;
    }

    &__info {
      padding-right: 55px;
    }

    &__picture {
      flex: 0 0 279px;
      height: 118px;
    }

    &__button-text_mobile {
      display: none;
    }
  }
}

@include respond('medium') {
  .profile-projects {
    &__nav {
      margin-right: calc(0px - var(--spacer-medium));
    }

    &__nav-list {
      padding-top: 30px;
    }

    &__nav-item {
      &:not(:last-of-type) {
        .profile-projects__nav-link {
          padding-right: 15px;
        }
      }

      &:not(:first-of-type) {
        .profile-projects__nav-link {
          padding-left: 15px;
        }
      }
    }

    &__nav-link-text {
      padding-bottom: 7px;
    }

    &__list {
      padding: 50px var(--spacer-medium) 70px;
      grid-gap: 30px;
    }

    &__info-wrapper {
      padding: 30px 30/768*100vw;
    }

    &__status {
      margin-bottom: 20px;
    }

    &__name {
      font-size: 16px;
    }

    &__button {
      padding: 18px 25px;

      &:first-of-type {
        padding-left: 30/768*100vw;
      }
    }

    &__comment-wpapper {
      padding: 19px 29/768*100vw 18px;
    }

    &__comment {
      padding: 18px 18/768*100vw 17px;
    }

    &__empty {
      padding: 30px var(--spacer-medium);
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .profile-projects {
    &__nav-link-text {
      line-height: 1.55;
    }

    &__status {
      font-size: 9px;
    }
  }
}

@include respond-down('small') {
  .profile-projects {
    &__nav {
      margin: 0 calc(0px - var(--spacer-small));
    }

    &__nav-list {
      padding-top: 22px;
      padding-left: var(--spacer-small);
    }

    &__nav-item {
      &:not(:last-of-type) {
        .profile-projects__nav-link {
          padding-right: 10px;
        }
      }

      &:not(:first-of-type) {
        .profile-projects__nav-link {
          padding-left: 10px;
        }
      }
    }

    &__nav-link {
    }

    &__nav-link-text {
      padding-bottom: 4px;
    }

    &__list {
      padding: 30px var(--spacer-small) 50px;
      grid-gap: 20px;
    }

    &__info-wrapper {
      flex-direction: column;
      padding: 20px 20/375*100vw;
    }

    &__info {
      padding-bottom: 20px;
    }

    &__status {
      margin-bottom: 15px;
    }

    &__name {
      font-size: 14px;
    }

    &__picture {
      height: 118/375*100vw;
    }

    &__button {
      padding: 20px;

      &:first-of-type {
        padding-left: 20/375*100vw;
      }
    }

    &__comment-wpapper {
      padding: 15px 20/375*100vw;
    }

    &__comment {
      padding: 15px 15/375*100vw;
    }

    &__button-text_desctop {
      display: none;
    }

    &__empty {
      padding: 20px var(--spacer-small);
      font-size: 15px;
    }
  }
}