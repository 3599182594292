.reviews-main{
  border-bottom: 1px solid var(--border-color);

  &__title {
    overflow: hidden;
  }

  &__list{
    display: grid;
  }

  &__link-wrapper {
    display: flex;
  }
}

@include respond-up('large') {
  .reviews-main{
    &__title {
      padding: 100px var(--spacer-large) 0;
    }

    &__list{
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__item {
      .review-item {
        padding-top: 65px;
      }
    }

    &__link-wrapper {
      justify-content: center;
    }
  }
}

@include respond('medium') {
  .reviews-main{
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;

    &__title {
      padding: 70px var(--spacer-medium) 0;
      width: 50%;
      border-right: 1px solid var(--border-color);
    }

    &__list{
      grid-template-columns: 1fr 1fr;
      opacity: 1 !important;
      transform: translate3d(0, 0, 0) !important;
    }

    &__item {
      .review-item {
        padding-top: 40px;
      }
    }

    &__item {
      &:first-of-type {
        border-right: 1px solid var(--border-color);
      }
    }
  }
}

@include respond-down('medium') {
  .reviews-main{
    &__link-wrapper {
      flex-direction: column;
      background-color: var(--background-color);
    }

    &__item {
      background-color: var(--background-color);

      &:nth-of-type(3) {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .reviews-main{

    &__title {
      padding: 40px var(--spacer-small) 0;
    }

    &__list{
      grid-template-columns: 1fr;
    }

    &__item {

      &:first-of-type {
        .review-item {
          border-bottom: 1px solid var(--border-color);
        }
      }

      .review-item {
        padding-top: 20px;
      }
    }
  }
}