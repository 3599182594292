.input-nav {
  display: flex;
  align-items: center;

  &__link {
    position: relative;
    display: flex;
    cursor: pointer;
    padding: 8px 14px;

    &:not(:last-child) {
      border-right: 1px solid var(--border-color-light);
    }

    &:hover {
      .input-nav {
        &__text {
          opacity: 1;
        }
      }
    }

    &.remove-link {
      position: relative;
      width: auto;
      height: auto;
      background: none;
    }
  }

  &__icon {
    display: flex;
  }

  &__text {
    position: absolute;
    top: calc(100% + 3px);
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    padding: 5px 11px;
    background-color: white;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: black;
    transition: opacity var(--default-transition);
    z-index: 10;
    pointer-events: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      border: 7px solid var(--t);
      border-bottom: 6px solid white;
    }
  }
}