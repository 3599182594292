body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: -moz-scrollbars-horizontal !important;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    position: absolute;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--border-color);

    &:hover {
      background-color: #b7b7b7;
    }
  }

  &::-webkit-scrollbar-track-piece{
    background-color: var(--background-color);
    border-left: 1px solid var(--border-color);
  }
}

input, button, form {
  font-family: var(--font);
}

@media print {
  body {
    background-color: white;
  }
}
