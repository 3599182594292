.producer {
  &__head {
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
  }

  &__slider-images-main {
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
  }

  &__production-items {
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
  }

  &__certificates {
    border-bottom: 1px solid var(--border-color);

    .certificates {
      &__description-short {
        display: none;
      }

      &__items {
        margin-top: unset;
      }
    }
  }

  &__categories {
    position: relative;
    border-bottom: 1px solid var(--border-color);
  }

  &__projects {
    border-bottom: 1px solid var(--border-color);
  }

  &__another-category {
    display: flex;
    border-bottom: 1px solid var(--border-color);
  }
}

@include respond-up('large') {
  .producer {
    &__head {
      padding-top: 77px;
    }

    &__another-category {
      //margin-bottom: 100px;
    }

    &__category-name-wrapper {
      flex: 0 0 33.333%;
      padding: 100px 100px var(--spacer-large);
    }

    &__subcategories {
      padding: 100px 0;
      flex: 0 0 66.666%;
    }

    &__subcategory-wrapper {
      padding: 100px 0 125px var(--spacer-large);
    }

    &__slider-images-main {
      padding: 105px 0 99px;
      margin-bottom: 100px;
    }

    &__certificates {
      .certificates {
        &__bar {
          border-right: unset;
        }

        &__main {
          margin-left: 1px;
          background-color: var(--background-color);
        }
      }
    }
  }
}

@include respond('medium') {
  .producer {
    &__head {
      padding-top: 30px;
    }

    &__slider-images-main {
      padding: 50px 0;
    }

    &__certificates {
      .certificates {
        &__bar {
          margin: unset;
          padding: 50px var(--spacer-medium) 20px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .producer {
    &__certificates {
      background-color: var(--background-color);
    }

    &__another-category {
      padding: 50px 0 50px var(--spacer-medium);
      background-color: var(--background-color);
      flex-direction: column;
    }

    &__category-name-wrapper {
      margin-bottom: 35px;
    }

    &__subcategories {
      margin-left: 0;
      padding-bottom: 0;
    }
  }
}

@include respond-down('small') {
  .producer {
    &__head {
      padding-top: 20px;
    }

    &__slider-images-main {
      padding: 40px 0;
    }

    &__another-category {
      padding: 40px 0 40px var(--spacer-small);
    }

    &__category-name-wrapper {
      margin-bottom: 17px;
    }
  }
}