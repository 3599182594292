.article {
  &__head-text {
    display: flex;
    width: 100%;
    background-color: var(--background-color);
  }

  &__tag-list {
    display: flex;
  }

  &__tag {
    white-space: nowrap;
  }

  &__body {
    display: flex;
    background-color: var(--background-color);
  }

  &__watch-text {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__watch-item {
    display: flex;
  }

  &__article-name {
    line-height: 1.75;
  }

  &__content {
    width: 100%;
  }

  &__bottom-container {
    border-top: 1px solid var(--border-color);
  }

  &__author {
    display: flex;
  }

  &__picture {
    display: flex;
  }

  &__author-photo {
    border-radius: 50%;
  }

  &__who-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__author-name {
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 15px;
  }

  &__author-profession {
    font-size: 14px;
    line-height: 15px;
    color: var(--text-color);
  }

  &__bottom-container {
    display: flex;
  }
}

@include respond-up('large') {
  .article {
    &__head-text {
      padding: 31px 0 59px;
    }

    &__date {
      font-family: var(--h-font);
      font-weight: 500;
      font-size: 11px;
      color: var(--tag-color);
      line-height: 10px;
      letter-spacing: 0.02em;
    }

    &__delimiter {
      cursor: default;
      font-size: 15px;
      line-height: 11px;
      color: var(--border-color);
      margin: 0 45px;
    }

    &__tag-list {
      &:not(:last-of-type){
        margin-right: 25/1675*100vw;
      }
    }

    &__tag {
      &:not(:last-of-type) {
        margin-right: 25px;
      }
    }

    &__bar {
      flex: 0 0 1/3*100% ;
      padding: 0 var(--spacer-large) 125px;
    }

    &__image {
      width: 100%;
    }

    &__bar-image {
      width: 100%;
      padding-top: 78px;
    }

    &__picture {
      width: 100%;
      padding-bottom: 80px;
    }

    &__sticky {
      position: sticky;
    }

    &__watch-text {
      font-size: 22px;
      padding-bottom: 35px;
      padding-top: 80px;
    }

    &__watch-item {
      flex-direction: column;
    }

    &__tag-link {
      margin-bottom: 14px;
    }

    &__article-link {
      &:hover {
        .article__article-name {
          background-size: 100% 100%;
          background-position: left;
        }
      }
    }

    &__article-name {
      font-size: 17px;
      width: calc(100%);
      background-image: linear-gradient(transparent calc(100% - 1px), var(--text-color-dark) 1px);
      background-repeat:no-repeat;
      background-size: 0 100%;
      background-position: right;
      transition: background-size var(--default-transition);
      padding-bottom:2px;
    }

    &__watch-item {
      padding: 22px 0 18px;
      border-top: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: 1px solid var(--border-color);
      }
    }

    &__content {
      padding: 0 var(--spacer-large) 125px;
      border-left: 1px solid var(--border-color);

      article {
        & > p,
        & > h2,
        & > h3,
        & > blockquote,
        & > ul,
        & > ol {
          margin-left: 15/1675*100vw;
          width: var(--text-content-width);
        }

        & > div {
          & > img {
            width: 343/1675*100vw;

            &:not(:first-of-type) {
              margin-left: 61/1675*100vw;
            }
          }
        }
      }
    }

    &__description {
      margin: 80px 13/1675*100vw 0;
    }

    &__post-slider {
      .slider__image {
        height: 463/1675*100vw;
      }
    }

    &__bottom-container {
      margin-left: 15/1675*100vw;
      margin-top: 125px;
    }

    &__author {
      margin-top: 40px;
    }

    &__author-photo {
      width: 72/1675*100vw;
      height: 72/1675*100vw;
      min-width: 72px;
      min-height: 72px;
      margin-right: 26px;
    }

    &__share {
      margin-top: 50px;
    }

    &__post-slider {
      margin: 50px 0 100px;
    }
  }
}

@include respond-up('medium') {
  .article {
    &__bottom-container {
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .article {
    &__head-text {
      padding: 28px 0 40px;
    }

    &__content {
      padding: 0 var(--spacer-medium) 90px;
    }

    &__author {
      margin-top: 40px;
    }

    &__share {
      margin-top: 40px;
    }

    &__watch-text {
      font-size: 18px;
      padding: 0 var(--spacer-medium) 25px;
    }

    &__watch-list {
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 90px;
    }

    &__watch-item {
      padding: 35px var(--spacer-medium);

      &:not(:last-of-type) {
        border-right: 1px solid var(--border-color);
      }
    }

    &__tag-link {
      margin-bottom: 18px;
    }

    &__description {
      margin-top: 50px;
    }

    &__bottom-container {
      margin-top: 70px;
    }

    &__article-name {
      font-size: 15px;
    }

    &__post-slider {
      margin: 50px 0 70px;
    }
  }
}

@include respond-down('medium') {
  .article {
    &__date {
      display: none;
    }

    &__delimiter {
      display: none;
    }

    &__tag {
      font-size: 10px;
      padding: 0;
      background-color: transparent;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }

    &__image {
      display: none;
    }

    &__watch-list {
      display: flex;
    }

    &__watch-item {
      display: flex;
      flex-direction: column;
    }

    &__body {
      flex-direction: column-reverse;
    }

    &__author-photo {
      width: 62px;
      height: 62px;
      margin-right: 25px;
    }
  }
}

@include respond-down('small') {
  .article {
    &__head-text {
      margin: 20px 0 16px;
    }

    &__tag-list {
      flex-wrap: wrap;
    }

    &__tag {
      margin-bottom: 16px;
    }

    &__body {
      padding: 0 var(--spacer-small);
    }

    &__watch-text {
      padding-bottom: 20px;
    }

    &__watch-list {
      flex-direction: column;
      margin-bottom: 30px;
    }

    &__watch-item {
      padding: 18px 0;
      border-top: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: 1px solid var(--border-color);
      }
    }

    &__content {
      padding: 0 0 40px;
    }

    &__description {
      margin-top: 40px;
    }

    &__bottom-container {
      margin-top: 30px;
      flex-direction: column;
    }

    &__author {
      margin-top: 30px;
    }

    &__share {
      margin-top: 30px;
    }

    &__post-slider {
      margin: 30px 0 40px;
    }
  }
}