.history-team {
  display: flex;

  &__list,
  &__points {
    display: grid;
  }

  &__points {
    grid-template-columns: repeat(2, 1fr);
  }

  &__text {
    article {
      margin: 0;
    }
  }

  &__point-text {
    margin-top: 15px;
  }
}

@include respond-up('large') {
  .history-team {
    &__text-wrap {
      flex: 0 0 calc(100%/3 + 1px);
      padding: 100px var(--spacer-large);
    }

    &__team-wrap {
      flex: 0 0 calc(2*100%/3 - 1px);
      border-left: 1px solid var(--border-color);
      padding: 100px var(--spacer-large) 125px 78px;
    }

    &__list {
      grid-column-gap: 20px;
      grid-row-gap: 50px;
    }

    &__text {
      margin-top: 55px;
    }

    &__points {
      margin-top: 116px;
      grid-column-gap: 28px;
    }
  }
}

@include respond-up('medium') {
  .history-team {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }

    &__button-wrap {
      margin-top: 40px;
    }

    &__points {
      grid-row-gap: 35px;
    }
  }
}

@include respond('medium') {
  .history-team {
    row-gap: 69px;
    padding: 50px var(--spacer-medium);

    &__list {
      grid-column-gap: 15px;
      grid-row-gap: 40px;
    }

    &__text {
      margin-top: 22px;
    }

    &__points {
      margin-top: 45px;
      grid-column-gap: 22px;
      grid-template-columns: repeat(2, calc(283/768*100vw));
      justify-content: flex-end;
    }
  }
}

@include respond-down('medium') {
  .history-team {
    flex-direction: column;
  }
}

@include respond-down('small') {
  .history-team {
    row-gap: 45px;
    padding: 30px var(--spacer-small) 45px;

    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 25px;
    }

    &__text {
      margin-top: 22px;
    }

    &__button-wrap {
      margin-top: 25px;
    }

    &__points {
      margin-top: 45px;
      grid-column-gap: 16px;
      grid-row-gap: 25px;
    }
  }
}