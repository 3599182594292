.main {
  &__slider {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__badge-icon,
  &__badge-picture {
    position: absolute;
    z-index: 3;
  }

  &__hero-button {
    background-color: #000000;
    padding: 15px 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__hero-button-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 133%;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  &__badge-icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .main {
    &__badge-icon,
    &__badge-picture {
      right: 11px;
      top: 170px;
      height: 113px;
      width: 64px;
      position: fixed;
    }

    &__hero-button {
      top: 304px;
      position: fixed;

      &:hover {
        .main__hero-button {
          &::after {
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }

    &__hero-button-title {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #fff;
        transform: scaleX(0);
        transform-origin: center right;
        transition: transform .4s;
      }
    }
  }
}

@include respond-up('medium') {
  .main {
    &__hero-button {
      right: 0;
      z-index: 3;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
    }
  }
}

@include respond('medium') {
  .main {
    &__badge-icon,
    &__badge-picture {
      right: 3px;
      top: 75px;
    }

    &__hero-button {
      padding: 12px 10px 11px;
      top: 166px;
      position: absolute;
    }
  }
}

@include respond-down('medium') {
  .main {
    &__badge-icon,
    &__badge-picture {
      height: 78px;
      width: 44px;
    }
  }
}

@include respond-down('small') {
  .main {
    &__badge-icon,
    &__badge-picture {
      right: 2px;
      top: 70px;
    }

    &__hero-button {
      width: 100%;
    }


    &__hero-button-title {
      font-size: 10px;
    }
  }
}