.contacts-address {
  &__info-item {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__buttons-wrap {
    display: flex;
  }

  &__button-schema {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &__schema-text {
    font-family: var(--h-font);
    font-size: 11px;
    font-weight: 700;
    line-height: 133%;
    text-transform: uppercase;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: -3px;
      transition: transform .2s ease-out;
    }

    &::before {
      transform-origin: right;
    }

    &::after {
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  &__schema-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .contacts-address {
    &__info-list {
      grid-column-gap: 20px;
      grid-row-gap: 40px;
      grid-template-areas:
                          'address address'
                          'phone email'
                          'worktime worktime'
      ;
    }

    &__description {
      margin-top: 20px;
    }

    &__buttons-wrap {
      flex-direction: column;
      row-gap: 60px;
      margin-top: 35px;
    }

    &__button-route {
      &.button {
        width: 100%;
      }
    }

    &__button-schema {
      column-gap: 17px;

      &:hover {
        .contacts-address {
          &__schema-text {
            &::after {
              transition: transform .3s var(--default-bezier) .33s;
              transform: scaleX(1);
            }

            &::before {
              transition: transform .3s var(--default-bezier);
              transform: scaleX(0);
            }
          }
        }
      }
    }

    &__schema-icon {
      width: 48px;
      height: 61px;
    }
  }
}

@include respond-up('medium') {
  .contacts-address {
    &__info-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__info-item {
      &_address {
        grid-area: address;
      }
      &_phone {
        grid-area: phone;
      }
      &_email {
        grid-area: email;
      }
      &_worktime {
        grid-area: worktime;
      }
    }
  }
}

@include respond('medium') {
  .contacts-address {
    width: calc(100% - (61 / 768 * 100vw));

    &__info-list {
      grid-column-gap: 83px;
      grid-row-gap: 50px;
      grid-template-areas:
                          'address phone'
                          'email worktime'
      ;
    }

    &__description,
    &__buttons-wrap {
      margin-top: 40px;
    }

    &__buttons-wrap {
      column-gap: calc(83 / 768 * 100vw);
    }

    &__button-route {
      &.button {
        padding: 20px 58px;
      }
    }
  }
}

@include respond-down('medium') {
  .contacts-address {
    &__button-schema {
      column-gap: 20px;
    }

    &__schema-icon {
      width: 43px;
      height: 55px;
    }
  }
}

@include respond-down('small') {
  .contacts-address {
    &__info-list {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }

    &__description {
      margin-top: 21px;
    }

    &__buttons-wrap {
      margin-top: 30px;
      flex-direction: column;
      row-gap: 25px;
    }

    &__button-route {
      &.button {
        width: 100%;
      }
    }
  }
}