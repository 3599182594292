.try-vizio {
  &__title-wrap  {
    display: flex;
    flex-direction: column;
  }

  &__buttons-wrap,
  &__button-wrap {
    display: flex;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__awards {
    margin: 0;
  }

  &__left-content {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .try-vizio {
    &__content-wrap {
      display: grid;
      grid-template-columns: calc(100%/3) 1fr;
      grid-template-areas:
                          'left title'
                          'left image'
                          '. image'
                          '. image';
      padding: 100px 0 65px;
    }

    &__left-content {
      grid-area: left;
    }

    &__awards-wrap {
      grid-area: awards;
    }

    &__title-wrap {
      grid-area: title;
      row-gap: 35px;
    }

    &__text-wrap {
      grid-area: text;
      margin-top: 81px;
    }

    &__image-wrap {
      grid-area: image;
      margin-top: 65px;
    }

    &__title-wrap,
    &__image-wrap {
      padding: 0 var(--spacer-large) 0 80px;
    }

    &__buttons-wrap {
      grid-area: button;
      margin-top: 35px;
      flex-direction: column;
      row-gap: 60px;
    }

    &__awards-wrap,
    &__text-wrap,
    &__buttons-wrap {
      padding: 0 var(--spacer-large);
    }

    &__picture {
      height: calc((971/1675* 100vw) * 600/971);
    }

    &__subtitle {
      max-width: calc(726/1675* 100vw);
    }

    &__awards {
      max-width: unset;
    }
  }
}

@include respond('medium') {
  .try-vizio {
    background: var(--background-color);

    &__content-wrap {
      padding: 50px var(--spacer-medium);
    }

    &__title-wrap {
      row-gap: 22px;
    }

    &__awards-wrap,
    &__text-wrap {
      margin-top: 50px;
    }

    &__buttons-wrap {
      margin-top: 40px;
      column-gap: 22px;
    }

    &__image-wrap {
      margin-top: 60px;
    }

    &__picture {
      height: calc((709/768 * 100vw) * 438/709);
    }
  }
}

@include respond-down('medium') {
  .try-vizio {
    &__title-wrap {
      row-gap: 22px;
    }

    &__button {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .try-vizio {
    &__content-wrap {
      padding: 30px var(--spacer-small) 40px;
    }

    &__awards-wrap,
    &__text-wrap {
      margin-top: 30px;
    }

    &__buttons-wrap {
      margin-top: 30px;
      flex-direction: column;
      row-gap: 25px;
    }

    &__image-wrap {
      margin-top: 40px;
    }

    &__picture {
      height: calc((320/375 * 100vw) * 198/320);
    }

    &__recall {
      &.button {
        padding: 20px 28px;
      }
    }

    &__awards {
      max-width: unset;
    }
  }
}