.avatar-input {
  display: inline-block;

  &__content {

  }

  &__list {
    height: 100%;

    &.files-list {
      li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.files-list {
      display: flex;
    }
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100px;
    border: 6px solid var(--background-color);
  }

  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(7, 17, 40, 0.52);
    border-radius: 100px;
    border: 6px solid var(--background-color);
  }

  &__nav {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@include respond-down('large') {
  .avatar-input {

  }
}