.profile-header {

}

@include respond-up('large') {
  .profile-header {
    padding-bottom: 70px;
    border-bottom: 1px solid var(--border-color);

    &__back {
      display: none;
    }

    &__main {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .profile-header {
    &__main {
      padding: 56px 0 20px;
    }
  }
}

@include respond-down('medium') {
  .profile-header {
    &__heading,
    &__logout {
      display: none;
    }
  }
}

@include respond-down('small') {
  .profile-header {
    &__main {
      padding: 30px 0 15px;
    }
  }
}