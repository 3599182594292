.colors {
  display: flex;

  &__label {
    display: grid;
    grid-template-areas: 'display';
    overflow: hidden;
  }

  &__display {
    grid-area: display;
    display: flex;
    align-items: flex-end;
    flex: 0 0 100%;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform 0s .9s;

    &._active {
      transform: translateX(0);
      transition: transform .7s .2s;
      z-index: 2;

      .colors__name {
        transform: translateX(0);
        transition: transform .7s .2s;
      }
    }
  }

  &__name {
    display: block;
    width: 100%;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 15px;
    line-height: 1.55;
    text-transform: uppercase;
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0s .7s;
  }

  &__info {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(5, 43px);
    grid-template-rows: repeat(2, 43px);
    grid-gap: 13px;
    border-bottom: 1px solid var(--border-color);
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;

    &._active {
      pointer-events: none;

      .colors__circle {
        transform: scale(1.14);
        transition: transform .9s;

        &::before {
          opacity: 1;
          transition: opacity .9s;
        }
      }
    }
  }

  &__circle {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: transform .5s;
    transform-origin: center;

    &:hover {
      transform: scale(1.14);
      transition: transform .5s;
    }

    &::before {
      content: '';
      position: absolute;
      width: 45px;
      height: 45px;
      top: -9px;
      left: -9px;
      border: 1px solid var(--border-color);
      border-radius: 50%;
      opacity: 0;
      transition: opacity .9s;
    }
  }

  &__link {

  }
}

@include respond-up('large') {
  .colors {
    &__label {
      width: 275px;
      height: 204px;
    }

    &__display {
      width: 275px;
      height: 204px;
    }

    &__info {
      margin-left: 119/1675*100vw;
    }

    &__list {
      margin-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .colors {
    &__label {
      margin-top: 6px;
    }

    &__info {
      flex: 1 1 0;
      flex-direction: column;
      justify-content: space-between;
    }

    &__list {
      padding-bottom: 57px;
    }
  }
}

@include respond('medium') {
  .colors {
    &__info {
      margin-left: 119/1675*100vw;
    }

    &__list {
      margin-bottom: 40px;
    }
  }
}

@include respond-down('medium') {
  .colors {
    &__lebel {
      width: 253px;
      height: 187px;
    }

    &__display {
      width: 253px;
      height: 187px;
    }

    &__list {
      margin-bottom: 25px;
    }
  }
}

@include respond-down('small') {
  .colors {
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25/375*100vw;

    &__info {
      padding-top: 30px;
      flex-direction: column;
    }

    &__list {
      padding-bottom: 36px;
    }
  }
}

@include respond-down('x-small') {
  .colors {
    &__lebel {
      width: 100%;
    }

    &__display {
      width: 100%;
    }

    &__list {
      grid-template-columns: repeat(4, 43px);
    }

    &__name {
      font-size: 13px;
    }
  }
}