.main-slider {
  position: relative;
  scroll-behavior: revert;
  overflow: hidden;

  &__badge-picture {
    position: absolute;
    z-index: 3;
  }

  &__to-form-button {
    background-color: #000000;
    padding: 15px 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__to-form-button-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 133%;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  &__filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    pointer-events: none;

    &_blue {
      background:linear-gradient(358.73deg, rgba(7, 20, 51, 0.26) 2.23%, rgba(7, 20, 51, 0) 97.6%);
    }

    &_black {
      transition-duration: .1s;
      transition-property: opacity;
      background-color: #000;
      opacity: 0;
    }
  }

  &__inner {
    vertical-align: top;
    overflow: hidden;
    width: 100%;
  }

  &__slide {
    position: relative;
    overflow: hidden;
    transform: translateX(100%);
    transition-duration: 0s;
    animation-duration: 0s;

    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .main-slider__image {
        transform: translateX(-90%) scale(1.2);
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 2;
      transition: transform 1.5s;

      .main-slider__image {
        transform: translateX(0) scale(1);
        transition: transform 1.5s;
      }

      .main-slider__info {
        z-index: 1;
      }

      .main-slider__text {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.6s 1.6s ease, transform 0.6s 1.6s ease;
      }

      .main-slider__details {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.4s 1.8s ease, transform 0.4s 1.8s ease;
      }
    }

    &.animate_out {
      transform: translateX(0);
      z-index: 1;
      overflow: visible;

      .main-slider__image {
        transform: translateX(-30%);
        transition: transform 1.5s;
      }

      .main-slider__info {
        z-index: 1000;
      }
    }
  }

  &__info {
    position: absolute;
    z-index: 5;
  }

  &__title {
    color: #fff;
  }

  &__title-inner {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__title-line {
    position: relative;
    line-height: 1;
  }

  &__text {
    color: #fff;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.6s 0.1s ease, transform 0.6s 0.1s ease;
  }

  &__details {
    color: #fff;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.4s ease, transform 0.4s ease;

    &::after, &::before {
      background-color: #fff;
    }
  }

  &__picture {
    display: block;
    font-size: 0;
  }

  &__image {
    object-fit: cover;
    max-width: none;
    transition: transform 0s;
    height: 100vh;
  }

  &__label {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
  }

  &__strip {
    height: 1px;
    background-color: var(--slide-strip-color);
    overflow: hidden;
  }

  &__strip-filling {
    background-color: #fff;
    height: 100%;
    transition: width .9s;
  }
}

@include respond-up('large') {
  .main-slider {
    &__badge-picture {
      right: 11px;
      top: 170px;
      height: 113px;
      width: 64px;
    }

    &__to-form-button {
      top: 304px;

      &:hover {
        .main-slider__to-form-button-title {
          &::after {
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }

    &__label {
      bottom: 40px;
      right: 55px;
    }

    &__image {
      width: 100%;
      object-fit: cover;
    }

    &__strip {
      width: 160px;
      margin-right: 30px;
    }

    &__info {
      top: 264px;
      left: 155/1675*100vw;
    }

    &__title {
      width: 772/1675*100vw;
      margin-bottom: 35px;
      opacity: 1 !important;
    }

    &__title-inner {
      margin-bottom: 24/1675*100vw;
    }

    &__text {
      width: 670/1675*100vw;
      margin-bottom: 35px;
    }

    &__to-form-button-title {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #fff;
        transform: scaleX(0);
        transform-origin: center right;
        transition: transform .4s;
      }
    }
  }
}


@include respond-up('medium') {
  .main-slider {
    &__to-form-button {
      position: absolute;
      right: 0;
      z-index: 3;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
    }

    &__slide {
      &.animate_in {
        .main-slider__info {
          transform: translateX(0);
        }

        .main-slider__title-inner {
          &:first-of-type {
            .main-slider__title-line {
              transition: transform 0.5s 1.5s ease;
            }
          }
          &:nth-child(2){
            .main-slider__title-line {
              transition: transform 0.5s 1.6s ease;
            }
          }
          &:nth-child(3){
            .main-slider__title-line {
              transition: transform 0.5s 1.7s ease;
            }
          }
        }

        .main-slider__title-line {
          transform: translateY(0);
        }
      }
    }

    &__info {
      opacity: 1 !important;
      transform: translateX(-80px);
      transition: transform 1.5s ease;
    }

    &__title-inner {
      &:first-of-type {
        .main-slider__title-line {
          transition: transform 0.8s 0.4s ease;
        }
      }
      &:nth-child(2){
        .main-slider__title-line {
          transition: transform 0.8s 0.25s ease;
        }
      }
      &:nth-child(3){
        .main-slider__title-line {
          transition: transform 0.8s 0.1s ease;
        }
      }
    }

    &__title-line {
      display: block;
      transform: translateY(60px);
      transition: transform 0.5s 1.5s ease;
    }

    &__word {
      top: 0 !important;
    }
  }
}


@include respond('medium') {
  .main-slider {
    &__badge-picture {
      right: 3px;
      top: 75px;
    }

    &__to-form-button {
      padding: 12px 10px 11px;
      top: 166px;
    }

    &__label {
      bottom: 31px;
      right: 24px;
    }

    &__image {
     width: 100%;
     object-fit: cover;
    }

    &__info {
      top: 253px;
      left: 75/768*100vw;
    }

    &__title {
      width: 559/768*100vw;
      margin-bottom: 30px;
    }

    &__title-inner {
      margin-bottom: 16/768*100vw;
    }

    &__text {
      width: 553/768*100vw;
      margin-bottom: 30px;
    }
  }
}


@include respond-down('medium') {
  .main-slider {
    &__badge-picture {
      height: 78px;
      width: 44px;
    }

    &__strip {
      width: 100px;
      margin-right: 23px;
    }
  }
}


@include respond-down('small') {
  .main-slider {
    &__badge-picture {
      right: 2px;
      top: 70px;
    }

    &__to-form-button {
      width: 100%;
    }

    &__to-form-button-title {
      font-size: 10px;
    }

    &__label {
      bottom: 64px;
      right: 13px;
    }

    &__image {
      width: 100%;
      object-fit: cover;
    }

    &__strip {
      background-color: var(--slide-strip-color);
    }

    &__strip-filling {
      background-color: #fff;
    }

    &__slide {
      &.animate_in {
        .word {
          transform: translateY(0);
          transition: transform 0.8s 1.2s ease;
        }
      }
    }

    &__info {
      top: 111px;
      left: 0;
      right: 0;
      padding: 0 var(--spacer-small);
      transform: none !important;
    }

    &__title {
      line-height: 1.55;
      margin-bottom: 20px;

      .word-wrapper {
        overflow: hidden;
        display: inline-block;
      }

      .word {
        display: inline-block;
        transform: translateY(60px);
        transition: transform 0.8s 0.4s ease;
      }
    }

    &__title-inner {
      display: inline;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__arrows {
      .arrows__link {
        &::before {
          border-color: rgba(255, 255, 255, .5);
        }
      }

      .arrows__icon {
        .arrow-svg {
          &__path {
            stroke: #fff;
          }
        }
      }

      .arrows__counter {
        color: #fff;
      }
    }
  }
}