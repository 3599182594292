.profile {
  display: flex;

  &__image-list {
    display: grid;
    grid-template-areas: 'display';
    overflow: hidden;
  }

  &__image-item {
    grid-area: display;
    position: relative;
    flex: 0 0 100%;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform 0s .9s;

    &._active {
      transform: translateX(0);
      transition: transform .7s .2s;
      z-index: 2;

      .profile__image-name {
        transform: translateX(0);
        transition: transform .7s .2s;
      }
    }
  }

  &__picture {
    display: flex;
  }

  &__image-name {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 15px;
    line-height: 1.55;
    color: #fff;
    text-transform: uppercase;
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0s .7s;
  }

  &__image-filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(179.29deg, rgba(7, 20, 51, 0.73) 2.08%, rgba(7, 20, 51, 0) 99.61%);
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__drop-down-check {
    position: relative;
    border: 1px solid var(--border-color);

    &.profile__drop-down-item {
      padding: 8px 40px 7px 10px;
      margin: 0;
    }

    .profile__drop-down-name {
      color: var(--text-color-dark);
    }
  }

  &__icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: flex;

    svg {
      fill: #000;
    }
  }

  &__drop-down {
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    position: relative;
    transition: height .7s;
    z-index: 2;
  }

  &__drop-down-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-top: none;
    transform: scaleY(0);
    opacity: 0;
    visibility: hidden;
    transition: opacity .35s,visibility .35s,transform .35s;
    transform-origin: top;

    &._active {
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }

  &__drop-down-item {
    display: flex;
    align-items: center;
    padding: 8px 0 7px;
    margin: 0 8px 0 10px;
    border-top: 1px solid var(--border-color);
    cursor: pointer;

    &:hover {
      .profile__drop-down-name {
        color: var(--text-color-dark);
      }
    }
  }

  &__drop-down-name {
    pointer-events: none;
    margin-left: 20px;
    color: var(--text-color);
    transition: color var(--default-transition);
  }

  &__description-list {
    display: grid;
    grid-template-areas: 'description';
    margin-top: 29px;
  }

  &__description-item {
    background-color: #fff;
    grid-area: description;

    &._active {
      z-index: 1;
    }
  }

  &__drop-down-image-list {
    pointer-events: none;
    display: grid;
    grid-template-areas: 'image';
  }

  &__drop-down-image {
    grid-area: image;

    &._active {
      z-index: 1;
    }
  }

  &__picture {
    height: 100%;
  }

  &__image {
    width: 100%;
  }
}

@include respond-up('large') {
  .profile {
    &__image-list {
      width: 275px;
      height: 204px;
    }

    &__image-item {
      width: 275px;
      height: 204px;
    }

    &__info {
      margin-left: 119/1675*100vw;
      width: 399/1675*100vw;
      max-width: 399px;
    }
  }
}

@include respond-up('medium') {
  .profile {
    &__drop-down-name {
      font-size: 16px;
      line-height: 1.35;
    }
  }
}

@include respond('medium') {
  .profile {
    &__info {
      margin-left: 60/768*100vw;
      width: 347px;
    }
  }
}

@include respond-down('medium') {
  .profile {
    &__image-list {
      width: 253px;
      height: 187px;
    }

    &__image-item {
      width: 253px;
      height: 187px;
    }
  }
}

@include respond-down('small') {
  .profile {
    flex-direction: column;
    padding: 25px 25/375*100vw;

    &__image-list {
      margin-bottom: 30px;
    }

    &__drop-down-name {
      font-size: 15px;
      line-height: 1.07;
    }
  }
}

@include respond-down('x-small') {
  .profile {
    &__image-list {
      width: 100%;
    }

    &__image-item {
      width: 100%;
    }

    &__image-name {
      font-size: 13px;
    }

    &__drop-down-name {
      font-size: 13px;
    }
  }
}