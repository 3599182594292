.projects {
  &__filter-form {
    background-color: var(--background-color);
    display: inline-block;
    padding-bottom: 42px;

    .form-field {
      margin-bottom: 0;
    }

    .label {
      display: none;
    }

    .checkbox-list {
      display: inline-flex;
      margin: 0 -12px;
      flex-wrap: wrap;
    }

    input[type=checkbox] {
      display: none;
    }

    input[type=checkbox] + label {
      color: var(--tag-color);
      transition: var(--default-transition);
      font-family: var(--h-font);
      pointer-events: auto;
      padding: 12px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 10px;
      line-height: 1;
      white-space: nowrap;

      &:hover {
        color: var(--text-color-dark);
      }
    }

    input[type=checkbox]:checked + label {
      color: var(--text-color-dark);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__list-item {
    &:nth-child(5n + 1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-child(5n + 2) {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &:nth-child(5n + 3) {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &:nth-child(5n + 4) {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &:nth-child(5n + 5) {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}

@include respond-up('large') {
  .projects {
    &__description {
      display: none;
    }

    &__filter-form {
      width: 50vw;
      padding-top: 75px;

      input[type=checkbox] + label {
        font-size: 11px;
      }
    }

    &__list {
      grid-column-gap: calc(var(--spacer-large) * 2);
      align-items: end;
    }

    &__list-item {
      margin-bottom: 137px;

      &:first-child {
        align-self: stretch;
        display: flex;
        align-items: flex-end;
        background: var(--gradient-to-top);
      }

      //&:nth-child(1), &:nth-child(6n) {
      //  width: calc((986/1675) * 100vw);
      //}

      &:nth-child(2) {
        margin-top: -290px;
      }

      //&:nth-child(2), &:nth-child(7n) {
      //  width: calc((429/1675) * 100vw);
      //}

      &:nth-child(5n + 3) {
        //width: calc((1082/1675) * 100vw);
        margin: 0 auto 139px;
      }
    }
  }
}

@include respond('medium') {
  .projects {
    &__description {
      padding-bottom: 49px;
      padding-top: 30px;
      background-color: var(--background-color);
    }

    &__filter-form {
      display: block;
      background-color: var(--background-color);
    }

    &__list-wrapper {
      border-top: 1px solid var(--border-color);
      margin: 0 calc(var(--spacer-medium) * -1);
      padding: 0 var(--spacer-medium);
    }

    &__list {
      grid-column-gap: calc(var(--spacer-medium));
      align-items: end;
      margin-top: 58px;
      margin-bottom: 20px;
    }

    &__list-item {
      margin-bottom: 20px;
      align-self: stretch;
      display: flex;
      align-items: flex-end;
      /*background: var(--gradient-to-top);*/

      //&:nth-child(1), &:nth-child(6n) {
      //  width: calc((452/765) * 100vw);
      //}
      //
      //&:nth-child(2), &:nth-child(7n) {
      //  width: calc((196/765) * 100vw);
      //}

      &:nth-child(5n + 3) {
        //width: calc((505/765) * 100vw);
        margin: 0 auto 20px;
      }
    }
  }
}

@include respond-down('small') {
  .projects {
    &__description {
      padding-top: 20px;
      padding-bottom: 30px;
      background-color: var(--background-color);
    }

    &__filter-form {
      display: block;
      background-color: var(--background-color);

      .checkbox-list {
        flex-wrap: wrap;
        margin: -7px;
      }

      input[type=checkbox] + label {
        color: var(--tag-color);
        transition: var(--default-transition);
        pointer-events: auto;
        padding: 7px;
        cursor: pointer;
        font-size: 10px;

        &:hover {
          color: var(--text-color-dark);
        }
      }
    }

    &__list-wrapper {
      border-top: 1px solid var(--border-color);
      margin: 0 calc(var(--spacer-small) * -1);
      padding: 0 var(--spacer-small);
    }

    &__list {
      grid-column-gap: calc(var(--spacer-small) * 2);
      align-items: end;
      margin-top: 58px;
      margin-bottom: 20px;
    }

    &__list-item {
      grid-column-start: 1 !important;
      grid-column-end: 4 !important;
      margin-bottom: 40px;

      //&:nth-child(2n+1) {
      //  width: calc((320/375) * 100vw);
      //}

      &:nth-child(2n) {
        width: calc((245/375) * 100vw);
        margin: 0 auto 40px;
      }
    }
  }
}