.project-awards-list {
  position: absolute;
  z-index: 10;

  &__list {
    display: flex;
  }

  &__image-wrap {
    background: rgba(22, 26, 34, 0.35);
    transition: background var(--default-transition);
  }

  &__image-wrap,
  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .project-awards-list {
    top: 25px;
    left: 25px;

    &__list {
      column-gap: 4px;
    }

    &__item {
      position: relative;

      &:hover {
        .project-awards-list {
          &__image-wrap {
            background: var(--text-color-dark);
          }

          &__name-wrap {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    &__image-wrap {
      width: 44px;
      height: 44px;
    }

    &__picture {
      width: 36px;
      height: 36px;
    }

    &__name-wrap {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity var(--default-transition);
    }

    &__name {
      position: relative;
      background: var(--text-color-dark);
      padding: 8px 10px;
      font-family: var(--h-font);
      font-size: 9px;
      font-weight: 500;
      line-height: 10px;
      color: #fff;
      width: auto;
      white-space: nowrap;

      &::before {
        position: absolute;
        content: '';
        left: 18px;
        top: -3px;
        border-style: solid;
        border-width: 0 4px 3px 4px;
        border-color: transparent transparent var(--text-color-dark) transparent;
      }
    }
  }
}

@include respond-up('medium') {
  .project-awards-list {

  }
}

@include respond('medium') {
  .project-awards-list {

  }
}

@include respond-down('medium') {
  .project-awards-list {
    top: 8px;
    left: 8px;

    &__list {
      column-gap: 2px;
    }

    &__image-wrap {
      width: 32px;
      height: 32px;
    }

    &__picture {
      width: 26px;
      height: 26px;
    }

    &__name {
      display: none;
    }
  }
}

@include respond-down('small') {
  .project-awards-list {

  }
}