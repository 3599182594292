.search-results {
  &__contents {
    padding: 65px 0 0;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 65px;
    grid-row-gap: 80px;
    margin-bottom: 90px;
  }

  &__content-item {
    position: relative;
  }

  &__content-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  &__more-button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__not-found {
    text-transform: uppercase;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.55em;
    margin-bottom: 73px;
  }
}

@include respond-up('large') {
  .search-results {
    &__content-item {
      &:hover {
        .search-results__product-card {
          .search-result-card__name_bordered {
            border-bottom: 1px solid black;
          }
        }
      }
    }

    &__items {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 65px;
      grid-row-gap: 80px;
      margin-bottom: 90px;
    }

    &__not-found {
      font-size: 30px;
    }
  }
}


@include respond-down('medium') {
  .search-results {
    &__items {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 30px;
      grid-row-gap: 50px;
      margin-bottom: 70px;
    }

    &__not-found {
      font-size: 22px;
    }
  }
}

@include respond-down('small') {
  .search-results {
    &__items {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 21px;
      grid-row-gap: 30px;
      margin-bottom: 41px;
    }

    &__not-found {
      font-size: 20px;
    }
  }
}


