article {
  --article-font-size: 16px;

  &:last-child {
    margin-bottom: 0 !important;
  }

  p, li {
    font-size: var(--article-font-size);
    line-height: 1.75;
  }

  p, li, pre {
    color: var(--text-color);
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  img {
    height: auto;
  }

  pre {
    font-size: 14px;
    white-space: normal;
    margin-bottom: 20px;
  }

  a {
    border-bottom: 1px solid #b3b3b4;
  }

  h2 {
    font-weight: 500;
  }

  h3 {
    font-weight: 400;
  }

  ul > li {
    &::before {
      content: '';
      display: block;
      background-color: #000;
      min-width: 20px;
      height: 1px;
      margin-right: 29px;
      margin-top: 11px;
    }
  }

  li {
    display: flex;
    line-height: 1.75;

    p {
      strong {
        display: block;
        font-weight: 400;
        color: var(--text-color-dark);
        font-family: var(--h-font);
        font-size: 20px;
        line-height: 1.75em;
        margin-top: -6px;
        margin-bottom: 8px;
      }
    }
  }

  ol {
    counter-reset: counter;
    padding: 0;
  }

  ol > li {
    counter-increment: counter;

    &::before {
      content: counters(counter, ".", decimal-leading-zero)".";
      display: block;
      font-weight: 500;
      font-family: var(--h-font);
      margin-top: 9px;
      line-height: 10px;
      text-transform: uppercase;
      color: var(--text-color-dark);
      margin-right: 25px;
    }
  }

  blockquote {
    position: relative;

    p {
      color: var(--text-color-dark);
      font-family: var(--h-font);
      line-height: 1.75;
    }

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: var(--border-color);
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 21px;
      width: 30px;
      left: 0;
      background: {
        image: url('../svg/quote.svg');
        size: contain;
      };
    }
  }

  & > div {
    display: flex;
    align-items: baseline;

    & > img {
      flex: 1 1 45%;
    }
  }
}

@include respond-up('large') {
  article {
    margin-top: 98px;

    h2 {
      margin: 98px 0 27px;
    }

    h3 {
      margin: 60px 0 28px;
    }

    ul, ol {
      margin-bottom: 50px;
    }

    ul > li {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ol > li {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      & + p,
      & + ul,
      & + ol {
        margin-top: 20px;
      }
    }

    blockquote {
      padding-left: 101px;
      margin-bottom: 50px;

      p {
        font-size: 17px;
        width: 100%;
        margin-left: 0;
      }

      &::before {
        left: 60px;
        top: 6px;
        bottom: 6px;
      }

      &::after {
        top: 6px;
      }
    }

    div {
      margin: 50px 0;
    }
  }
}

@include respond('medium') {
  article {
    margin-top: 70px;

    h2 {
      font-size: 22px;
      margin: 70px 0 28px;
    }

    h3 {
      font-size: 18px;
      margin: 60px 0 22px;
    }

    ul, ol {
      margin-bottom: 50px;
    }

    ul > li {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ol > li {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      & + p,
      & + ul,
      & + ol {
        margin-top: 18px;
      }
    }

    blockquote {
      margin-bottom: 50px;
      padding-left: 83px;

      p {
        font-size: 15px;
      }

      &::before {
        left: 52px;
        top: 8px;
        bottom: 8px;
      }

      &::after {
        top: 8px;
      }
    }

    & > div {
      margin: 50px 0;

      & > img {
        width: 332/768*100vw;

        &:nth-of-type(2n) {
          margin-left: 44/768*100vw;
        }
      }
    }
  }
}

@include respond-down('medium') {
  article {
    --article-font-size: 15px;
  }
}

@include respond-down('small') {
  article {
    margin-top: 40px;

    h2 {
      font-size: 18px;
      margin: 40px 0 22px;
    }

    h3 {
      font-size: 16px;
      margin: 40px 0 20px;
    }

    ul, ol {
      margin-bottom: 30px;
    }

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        margin-right: 20px;
        margin-top: 7px;
      }
    }

    ol > li {
      &::before {
        margin-right: 21px;
      }
    }

    p {
      & + p,
      & + ul,
      & + ol {
        margin-top: 10px;
      }
    }

    blockquote {
      padding-left: 68px;
      margin-bottom: 30px;

      p {
        font-size: 15px;
      }

      &::before {
        left: 47px;
        top: 8px;
        bottom: 8px;
      }

      &::after {
        top: 8px;
        height: 19px;
        width: 27px;
      }
    }

    & > div {
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 40px calc(0px - var(--spacer-small));
      padding: 0 var(--spacer-small);

      &::-webkit-scrollbar {
        display: none;
      }

      & > img {
        width: 229/375*100vw;

        &:not(:first-of-type) {
          margin-left: 30px;
          box-sizing: content-box;

          &:last-of-type {
            padding-right: var(--spacer-small);
          }
        }
      }
    }
  }
}