.share {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 32px;
    display: flex;
    align-items: center;
  }

  &__links {
    .ya-share2 {
      &__list {
        display: flex;
        flex-wrap: nowrap;
      }

      &__item {
        margin: 0 !important;
        border: 1px solid var(--border-color);
        transition: background-color var(--default-transition), border-color var(--default-transition);

        &:not(:first-of-type) {
          border-left: none;
        }

        .social-icon {
          &__path {
            fill: var(--text-color-dark);
            transition: fill var(--default-transition);
          }
        }

        &:hover {
          background-color: var(--text-color-dark);
          border-color: var(--text-color-dark);

          .ya-share2{
            &__icon {
              filter: invert(100%)
            }
          }
        }

        &:nth-of-type(1) {
          .ya-share2 {
            &__icon {
              background-image: url('../svg/vk.svg') !important;
            }
          }
        }

        &:nth-of-type(2) {
          .ya-share2 {
            &__icon {
              background-image: url('../svg/fb.svg') !important;
            }
          }
        }

        &:nth-of-type(3) {
          .ya-share2 {
            &__icon {
              background-image: url('../svg/telegram.svg') !important;
            }
          }
        }
      }

      &__link {
        width: 60px;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        .ya-share2{
          &__badge {
            background-color: transparent;
          }
        }
      }

      &__icon {
        background: {
          size: auto !important;
          repeat: no-repeat;
          position: center;
        }
        transition: filter var(--default-transition);

        &:active {
          box-shadow: none;
        }
      }
    }
  }
}