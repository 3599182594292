.profile-main {
  &__top {
    border-bottom: 1px solid var(--border-color);
  }
}

@include respond-up('large') {
  .profile-main {
    display: flex;

    &__menu {
      flex: 0 0 1/3*100%;
    }

    &__content {
      flex: 0 0 2/3*100%;
      border-left: 1px solid var(--border-color);
    }

    &__top {
      padding: 40px var(--spacer-large-profile);
    }
  }
}

@include respond('medium') {
  .profile-main {
    &__top {
      padding: 0 var(--spacer-medium) 30px;
    }
  }
}

@include respond-down('medium') {
  .profile-main {
    &__menu {
      display: none;
    }
  }
}

@include respond('small') {
  .profile-main {
    &__top {
      padding: 0 var(--spacer-small) 20px;
    }
  }
}

@include respond-down('x-small') {
  .profile-main {
    &__top {
      padding: 0 var(--spacer-smaller) 20px;
    }
  }
}