.product-print {
  &__design-picture {
    display: flex;
  }

  &__coat-block {
    margin-top: 6.2mm;
  }

  &__glass-picture {
    flex: 0 0 20.1mm;
    height: 20.1mm;
    margin-right: 4.4mm;
    display: flex;
  }

  &__glass-item-description {
    margin-top: 1.8mm;
  }

  &__glass-item-text {
    display: flex;
    flex-direction: column;
  }

  &__glass-item {
    padding: 4.4mm;
    background: var(--background-color);
    -webkit-print-color-adjust: exact;
    display: flex;
    flex-direction: row;
  }

  &__glass-list {
    margin-top: 4.4mm;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.5mm;
  }

  &__colors-title {
    margin-top: 3.5mm;
  }

  &__colors-item {
    -webkit-print-color-adjust: exact;
    height: 22.9mm;
    padding: 3.9mm;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__colors-list {
    margin-top: 4.4mm;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3.5mm;
  }

  &__design-text {
    margin-top: 2.6mm;
  }

  &__design-short-text {
    margin-top: 4.4mm;
  }

  &__design-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__design-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.5mm;
  }

  &__pictures-item {
    height: 65.8mm;
    margin-right: 3.5mm;
    margin-bottom: 3.5mm;
  }

  &__pictures-list {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(3 * 65.8mm + 2 * 3.5mm);
    overflow: hidden;
  }

  &__details-text {
    margin-top: 2.6mm;
  }

  &__details-text-wrapper {
    flex: 1 0 auto;
    padding: 3.5mm 3.5mm 7.1mm;
    background-color: var(--background-color);
    -webkit-print-color-adjust: exact;
  }

  &__details-item {
    display: flex;
    flex-direction: column;
    page-break-inside: avoid;
  }

  &__details-list {
    margin-top: 4.4mm;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3.5mm;
  }

  &__automatization-text-short {
    margin-top: 2.6mm;
  }

  &__automatization-text {
    margin-top: 4.4mm;
  }

  &__automatization-link-wrapper {
    display: flex;
  }

  &__automatization-link {
    position: relative;
    margin-top: 1.4mm;
    flex: 0 1 auto;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      border-bottom: .2mm solid var(--text-color-dark);
      left: 0;
      bottom: 0;
      height: 1px;
    }
  }

  &__features-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.5mm;
    margin-top: 4.4mm;
  }

  &__features-item {
    background-color: var(--background-color);
    -webkit-print-color-adjust: exact;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 4.4mm 4.4mm 4.4mm 18.2mm;
    position: relative;
  }

  &__features-counter {
    position: absolute;
    left: -4.9mm;
    top: 0;
  }

  &__features-count {
    color: var(--opacity-numbers-color);
  }

  &__features-name {
  }

  &__features-description {
    margin-top: 2.1mm;
  }

  &__param-list {
    margin-top: 4.4mm;
    border-top: 0.2mm solid var(--border-color);
  }

  &__param-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3.5mm;
    border-bottom: 0.2mm solid var(--border-color);
    padding-top: 4.1mm;
    padding-bottom: 4.1mm;
  }

  &__property-picture {
    flex: 0 0 82mm;
    height: 82mm;
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  &__property-block {
    display: flex;
    justify-content: space-between;
  }

  &__property-icon {
    width: 7.1mm;
    height: 7.1mm;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__property-heading {
    position: relative;
    display: flex;
  }

  &__property-heading,
  &__property-text {
    padding-left: 13.8mm;
  }

  &__property-text {
    margin-top: 2.8mm;
  }

  &__property-list {
    display: flex;
    flex-direction: column;
  }

  &__property-item {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 7.1mm;
    }

    &:first-child {
      margin-top: 2mm;
    }
  }


  &__block {
    page-break-after: auto;
    page-break-inside: avoid;
    //тут особая логика
    padding-top: 22.9mm; // до верха страницы 130 px
    padding-bottom: 29.1mm; // 80 между блоками + 15mm надо
    margin-bottom: calc(-1 * 37.9mm); // аннигиляция: 22.9 + 15mm

    // а после этого меньше отступ
    &_small {
      padding-bottom: 25.6mm; // 60 между блоками + 15mm надо
    }
  }

  &__main-picture-wrapper {
    margin-top: 4.4mm;
  }

  &__description {
    margin-top: 6.2mm;
  }

  &__price-line {
    position: absolute;
    width: 11.3mm;
    height: .2mm;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-top: .2mm solid var(--text-color-dark);
  }

  &__price {
    position: relative;
    padding-left: 16.2mm;
    margin-top: 5.3mm;
  }
}