:root {
  --default-input-height: 37px;
  --errors-color: var(--alert-color, #b50000)
}

.button, button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  background: var(--primary-color);
  font-size: 16px;
  cursor: pointer;

  &.expand, &.wide {
    width: 100%;
  }

  &.height {
    height: var(--default-input-height);
  }
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

  label {
    display: flex;
    align-items: center;
    font-size: 19px;
    line-height: 25px;
    pointer-events: none;
    transition: all .2s;
    top: 0;
    left: 0;
    height: calc(100% - 2px);

    &.required::after {
      content: ' *';
      color: var(--alert-color);
    }
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: var(--errors-color);
    font-size: 12px;
    position: absolute;
    top: 100%;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    position: relative;
    margin-bottom: 50px;

    & > .errors {
      position: absolute;
    }
  }

  #{$inputs}, textarea, select {
    margin: 0;
    appearance: none;
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    display: flex;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: 2px solid var(--form-border-color);
    transition: border var(--default-transition);

    &:focus {
      border-color: var(--button-color);
    }
  }

  select {
    cursor: pointer;
    background: {
      image: url("../svg/select-arrow.svg");
      position: calc(100% - 4px) calc(50% + 4px);
      repeat: no-repeat;
    }

    & + label {
      display: none !important;
    }
  }

  .radio-list {
    display: flex;

    input {
      display: none;
    }

    & + label,
    label {
      position: static;
      pointer-events: auto;
    }

    label  {
      position: relative;
      padding-left: 29px;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: auto;
        display: block;
        border-radius: 50%;
        width: 19px;
        height: 19px;
        box-sizing: border-box;
        padding: 4px;
        border: 1px solid var(--form-radio-border-color);
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      &:checked {
        & + label {
          &::before {
            background: {
              color: var(--form-radio-background-color);
              position: center center;
              repeat: no-repeat;
              clip: content-box;
            }
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  form {
    label {
      font-size: 19px;
    }

    input, textarea, select {
      padding: 20px 0 10px;
      font-size: 19px;
      line-height: 1.32;
    }
  }
}

@include respond('medium') {
  form {
    label {
      font-size: 17px;
    }

    input, textarea, select {
      padding: 22px 0 10px;
      font-size: 17px;
    }
  }
}

@include respond-down('medium') {
  form {
    input, textarea, select {
      line-height: 1.55;
    }
  }
}

@include respond-down('small') {
  form {
    label {
      font-size: 16px;
    }

    input, textarea, select {
      padding: 20px 0 8px;
      font-size: 16px;
    }
  }
}