.search-result-card {
  &__picture {
    display: flex;
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  &__name {
    font-family: var(--h-font);
    font-weight: normal;
    line-height: 1.25em;
    position: relative;

    &_bordered {
      display: inline;
      transition: var(--default-transition);
      border-bottom: 1px solid var(--t);
    }
  }

  &__description {
    color: var(--text-color);
    font-weight: normal;
    line-height: 1.75em;
    margin-top: 10px;
  }
}

@include respond-up('large') {
  .search-result-card {
    &__picture {
      margin-bottom: 20px;
    }

    &__name {
      font-size: 18px;
    }

    &__description {
      font-size: 14px;
    }
  }
}

@include respond-down('medium') {
  .search-result-card {
    &__picture {
      margin-bottom: 15px;
    }

    &__name {
      font-size: 15px;
    }

    &__description {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .search-result-card {
    &__name {
      font-size: 14px;
    }

    &__description {
      display: none;
    }
  }
}