.product-automatization {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__video {
    width: 100%;
    font-size: 0;
  }

  &__frame {
    width: 100%;
    pointer-events: none;
  }
}

@include respond-up('large') {
  .product-automatization {
    &__column {
      &:first-of-type {
        flex: 0 0 430/1675*100vw;
        margin-right: 97/1675*100vw;
      }

      &:last-of-type {
        flex: 0 0 421/1675*100vw;
      }
    }

    &__columns {
      display: flex;
      margin-bottom: 70px;
    }

    &__container {
      padding: 0 var(--spacer-large) 100px 80/1675*100vw;
    }

    &__title {
      padding-bottom: 27px;
    }

    &__frame {
      height: 544/1675*100vw;
    }

    &__link {
      padding-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .product-automatization {
    &__video {
      position: relative;
      flex: 0 0 100%;
    }

    &__fslightbox {
      display: none;
    }

    &__video-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

@include respond('medium') {
  .product-automatization {
    &__container {
      padding: 0 0 70px;
    }

    &__column {
      padding-bottom: 40px;

      &:first-of-type {
        flex: 0 0 319/768*100vw;
      }

      &:last-of-type {
        flex: 0 0 341/768*100vw;
      }
    }

    &__frame {
      height: 395/768*100vw;
    }
  }
}

@include respond-down('medium') {
  .product-automatization {
    &__title {
      padding-bottom: 22px;
    }

    &__text {
      &_short {
        padding-bottom: 20px;
      }
    }

    &__link {
      padding-top: 25px;
    }
  }
}

@include respond-down('small') {
  .product-automatization {
    &__container {
      padding-bottom: 50px;
    }

    &__column {
      &:nth-last-of-type(2) {
        padding-bottom: 35px;
      }
    }

    &__frame {
      display: none;
    }

    &__fslightbox {
      display: block;

    }
  }
}