.files-input {
  .info {

  }

  .image-wrapper {
    img {
      height: 135px;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .item-wrapper {
    position: relative;
  }

  .remove-link {
    background: rgba(32, 55, 82, 0.5) url("../images/icons/x.svg") no-repeat 50% 50%;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .files-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
  }

  .images {
    img {
      height: auto;
    }
  }
}

@include respond-up('large') {
  .files-input {
    .files-list {
      li:last-child {
        margin-bottom: 40px;
      }
    }
  }
}

@include respond('medium') {
  .files-input {
    .files-list {
      li:last-child {
        margin-bottom: 30px;
      }
    }
  }
}

@include respond-down('small') {
  .files-input {
    .files-list {
      grid-template-columns: 1fr 1fr;

      li:last-child {
        margin-bottom: 20px;
      }
    }
  }
}