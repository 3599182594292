.documents-categories {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    border-bottom: 1px solid var(--border-color);

    &:first-child {
      border-top: 1px solid var(--border-color);
    }
  }
}