.our-plants {
  &__producers {
    background-color: #fff;
  }

  &__border {
    border-top: 1px solid var(--border-color);
  }

  &__items {
    display: grid;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%);
    transition: filter var(--default-transition);

    &:hover {
      filter: grayscale(0);
    }
  }

  &__picture {
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .our-plants {
    &__container {
      margin-bottom: 125px;
    }

    &__description {
      padding: 100px var(--spacer-large) 80px 80/1675*100vw;
    }

    &__first {
      margin-bottom: 20px;
    }

    &__warranty {
      white-space: nowrap;
    }

    &__producers {
      padding: 76px 102/1675*100vw;
    }

    &__items {
      grid-template-columns: repeat(auto-fill, 158px);
      grid-column-gap: 78/1675*100vw;
      grid-row-gap: 75px;
    }

    &__item {
      height: 63px;
    }
  }
}

@include respond('medium') {
  .our-plants {
    &__container {
      margin-bottom: 50px;
    }

    &__top {
      padding: 50px var(--spacer-medium) 40px;
    }

    &__producers {
      padding: 40px 48/768*100vw;
    }

  }
}

@include respond-down('medium') {
  .our-plants {
    &__description {
      margin-top: 22px;
    }

    &__items {
      grid-template-columns: repeat(auto-fill, 110px);
      grid-column-gap: 56/768*100vw;
      grid-row-gap: 40px;
    }


    &__first {
      margin-bottom: 20px;
    }

    &__item {
      height: 44px;
    }
  }
}

@include respond('small') {
  .our-plants {
    &__top {
      padding: 30px var(--spacer-small);
    }
  }
}

@include respond-down('small') {
  .our-plants {
    &__container {
      margin-bottom: 30px;
    }


    &__producers {
      padding: 30px 22/375*100vw;
    }
  }
}

@include respond-down('x-small') {
  .our-plants {
    &__top {
      padding: 30px var(--spacer-smaller);
    }

    &__items {
      grid-template-columns: repeat(auto-fill, 106px);
    }
  }
}