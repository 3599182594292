.drop-down-answer {
  &__select {
    background-color: #fff;
    border: 1px solid var(--border-color);
    background-image: url("../svg/quiz-select-arrow.svg");
    background-size: 14px 10px;

    &:active,
    &:focus {
      border-color: var(--border-color);
    }
  }
}

@include respond-up('large') {
  .drop-down-answer {
    &__select {
      padding: 17px 20px;
      background-position: calc(100% - 20px) 50%;
    }
  }
}

@include respond('medium') {
  .drop-down-answer {
    &__select {
      padding: 16px 20px;
      background-position: calc(100% - 20px) 50%;
    }
  }
}

@include respond-down('small') {
  .drop-down-answer {
    &__select {
      padding: 16px 10px;
      background-position: calc(100% - 10px) 50%;
    }
  }
}