.documents-text-block {
  &__text {
    color: var(--text-color-dark);
  }
}

@include respond-up('large') {
  .documents-text-block {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-gap: 20px;

    &__text {
      grid-column: 6/15;
      padding-left: 14px;
    }
  }
}

@include respond-up('medium') {
  .documents-text-block {

  }
}

@include respond('medium') {
  .documents-text-block {
    margin-top: 48px;
  }
}

@include respond-down('medium') {
  .documents-text-block {

  }
}

@include respond-down('small') {
  .documents-text-block {
    margin-top: 45px;
  }
}