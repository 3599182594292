.slider-images {
  &__items {
    cursor: pointer;
  }

  &__slide {
    height: 100%;
    display: flex;
  }

  &__picture {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__image {
    object-position: bottom;
    max-height: 100%;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__strip {
    height: 1px;
    background-color: var(--slider-line-bg-color);
    overflow: hidden;
  }

  &__strip-filling {
    background-color: var(--slider-line-color);
    height: 100%;
    transition: width .9s;
  }
}

@include respond-up('large') {
  .slider-images {
    &__items {
      height: 504/1675*100vw;
    }

    &__slide {
      max-width: 876/1675*100vw;
    }

    &__nav-wrap {
      margin-top: 70px;
    }

    &__nav {
      margin: 0 var(--spacer-large);
    }

    &__strip {
      flex: 0 0 160px;
      margin-right: 40px;
    }
  }
}

@include respond-down('medium') {
  .slider-images {
    &__nav-wrap {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .slider-images {
    &__items {
      height: 294/768*100vw;
    }

    &__slide {
      max-width: 473/768*100vw;
    }

    &__nav {
      margin: 0 var(--spacer-medium);
    }
  }
}

@include respond-down('medium') {
  .slider-images {
    &__strip {
      flex: 0 0 100px;
      margin-right: 30px;
    }
  }
}

@include respond('small') {
  .slider-images {
    &__nav {
      margin: 0 var(--spacer-small);
    }
  }
}

@include respond-down('small') {
  .slider-images {
    &__items {
      height: 268/375*100vw;
      max-height: 250px;
    }

    &__slide {
      max-width: 432/375*100vw;
    }
  }
}

@include respond-down('x-small') {
  .slider-images {
    &__nav {
      margin: 0 var(--spacer-smaller);
    }

    &__strip {
      flex: 0 0 90px;
      margin-right: 15px;
    }
  }
}