.progress-bar {
  &__line {
    display: flex;
    height: 4px;
    width: 100%;
    background: #DBDBDB;
    position: relative;
  }

  &__progress-line {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: var(--slider-line-color);
  }
}

@include respond-up('medium') {
  .progress-bar {
    &__line {
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .progress-bar {
    &__line {
      margin-top: 8px;
    }
  }
}