.articles {
  &__container {
    display: flex;
  }

  &__bar {
    flex: 0 0 calc(100%/3 + 1px);
  }

  &__tags-text {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
  }

  &__body {
    flex: 0 0 calc(2*100%/3 - 1px);
  }

  &__description {

  }

  &__filter-form {
    background-color: var(--background-color);
    display: flex;
    flex-wrap: wrap;

    .form-field {
      margin-bottom: 0;
    }

    .label {
      display: none;
    }

    .checkbox-list {
      display: flex;
      flex-wrap: wrap;
    }

    input[type=checkbox] {
      display: none;
    }

    input[type=checkbox] + label {
      color: var(--tag-color);
      transition: var(--default-transition);
      font-family: var(--h-font);
      pointer-events: auto;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 10px;
      line-height: 1;
      white-space: nowrap;

      &:hover {
        color: var(--text-color-dark);
      }
    }

    input[type=checkbox]:checked + label {
      color: var(--text-color-dark);
    }
  }

  &__list-item {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--border-color);
    }
  }

  &__tabs-wrap {
    &.scrollable-menu {
      .articles {
        &__tab-title {
          color: var(--tag-color);
          line-height: 135%;
          font-family: var(--h-font);
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }
  }

  &__tab-item {
    &._active {
      .articles {
        &__tab-title {
          color: var(--text-color-dark);
        }
      }
    }
  }
}

@include respond-up('large') {
  .articles {
    background-color: var(--background-color);

    &__sticky {
      position: sticky;
      top: 0;
      left: 0;
      padding: 80px calc(var(--spacer-large) - 16px);
    }

    &__tags-text {
      font-size: 22px;
      padding: 0 16px 24px;
    }

    &__share-text {
      font-size: 22px;
      margin: 119px 0 35px 16px;
    }

    &__button {
      margin-left: 16px;
    }

    &__body {
      background-color: var(--background-color);
      border-left: 1px solid var(--border-color);
    }

    &__tab-title {
      font-size: 18px;
    }
  }
}

@include respond-up('medium') {
  .articles {
    &__filter-form {
      input[type=checkbox] + label {
        padding: 16px;
      }
    }
  }
}

@include respond('medium') {
  .articles {
    &__container {

    }

    &__sticky {
      padding: 30px calc(var(--spacer-medium) - 16px) 40px;
      border-bottom: 1px solid var(--border-color);
    }

    &__bar {
      background-color: var(--background-color);
    }

    &__tab-title {
      font-size: 16px;
    }

    &__tabs-list {
      padding: 0 var(--spacer-medium);
    }
  }
}

@include respond-down('medium') {
  .articles {
    &__container {
      flex-direction: column;
    }

    &__tags-text {
      display: none;
    }

    &__share-text {
      display: none;
    }

    &__button {
      display: none;
    }

    &__tabs-wrap {
      &.scrollable-menu {
        .articles {
          &__tabs-list {
            flex-wrap: wrap;
            row-gap: 16px;
            column-gap: 35px;

            &::before,
            &::after {
              content: none;
            }
          }

          &__tab-link {
            padding: 0;
          }
        }
      }
    }

    &__tabs-list {

    }
  }
}

@include respond-down('small') {
  .articles {
    &__bar {
      padding: 20px calc(var(--spacer-small) - 7px) 30px;
      border-bottom: 1px solid var(--border-color);
    }

    &__filter-form {
      input[type=checkbox] + label {
        padding: 7px;
      }
    }

    &__tabs-wrap {
      &.scrollable-menu {
        .articles {
          &__tab-title {
            font-size: 14px;
          }
        }
      }
    }

    &__tabs-list {
      padding: 0 var(--spacer-small);
    }
  }
}