.answers {
  &__item {
    input {
      display: none;

      &:checked + label {
        border-color: var(--search-button-border);
      }
    }

    label {
      display: flex;
      align-items: center;
      background: #fff;
      border: 1px solid var(--border-color);
      pointer-events: auto;

      &::before {
        content: '';
        display: flex;
        border: 1px solid var(--search-button-border);
      }
    }

    &_radio {
      label {
        &::before {
          border-radius: 100%;
          transition: border-width var(--default-transition);
          box-sizing: border-box;
        }
      }
    }

    &_checkbox {
      input {
        &:checked + label {
          &::before {
            background-color: var(--search-button-border);
          }
        }
      }

      label {
        &::before {
          background-image: url('../svg/checked-checkbox.svg');
          background-position: center;
          background-repeat: no-repeat;
          transition: background-color var(--default-transition);
        }
      }
    }
  }

  &__text-answer {
    textarea {
      resize: none;
      border: 1px solid var(--border-color);
      background: #fff;
      font-family: var(--font);
      font-weight: 400;
      line-height: 135%;

      &::placeholder {
        color: var(--text-color-dark);
      }

      &:focus {
        border-color: var(--border-color);
      }
    }
  }
}

@include respond-up('large') {
  .answers {
    &__item {
      label {
        padding: 21px 20px;

        &::before {
          width: 22px;
          height: 22px;
        }
      }

      &_radio {
        input {
          &:checked + label {
            &::before {
              border-width: 8px;
            }
          }
        }
      }

      &_checkbox {
        label {
          &::before {
            background-size: 14px 14px;
          }
        }
      }
    }

    &__text-answer {
      textarea {
        min-height: 106px;
        font-size: 18px;
      }
    }
  }
}

@include respond-up('medium') {
  .answers {
    &__item {
      label {
        column-gap: 18px;
      }
    }

    &__text-answer {
      textarea {
        padding: 17px 20px;
      }
    }
  }
}

@include respond('medium') {
  .answers {
    &__item {
      label {
        padding: 16px 20px;
      }
    }

    &__text-answer {
      textarea {
        min-height: 100px;
      }
    }
  }
}

@include respond-down('medium') {
  .answers {
    &__item {
      label {
        &::before {
          width: 16px;
          height: 16px;
        }
      }

      &_radio {
        input {
          &:checked + label {
            &::before {
              border-width: 6px;
            }
          }
        }
      }

      &_checkbox {
        label {
          &::before {
            background-size: 12px 12px;
          }
        }
      }
    }

    &__text-answer {
      textarea {
        font-size: 16px;
      }
    }
  }
}

@include respond-down('small') {
  .answers {
    &__item {
      label {
        padding: 12px 10px;
        column-gap: 12px;
      }
    }

    &__text-answer {
      textarea {
        padding: 12px 10px;
        min-height: 90px;
      }
    }
  }
}
