.cabinet-menu {

  &__title {
    padding-top: 0;
  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
  }

  &__link {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.55;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__logout {
    display: block;
  }
}

@include respond-up('medium') {
  .cabinet-menu {
    &__title {
      display: block;
      padding-bottom: 10px;
    }

    &__list {

    }

    &__item {

    }

    &__link {

    }

    &__logout {

    }
  }
}

@include respond('medium') {
  .cabinet-menu {
    &__title {

    }

    &__list {

    }

    &__item {

    }

    &__link {
      font-size: 18/768*100vw;
      padding: 25px var(--spacer-medium);
    }

    &__logout {
      padding: 50px var(--spacer-medium) 70px;
    }
  }
}

@include respond-down('medium') {
  .cabinet-menu {
    border-top: 1px solid var(--border-color);

    &__title {

    }

    &__list {

    }

    &__item {

    }

    &__link {

    }

    &__logout {

    }
  }
}

@include respond-down('small') {
  .cabinet-menu {
    &__title {
      display: block;
    }

    &__list {

    }

    &__item {

    }

    &__link {
      font-size: calc((100vw - 375px)/(767 - 375) * (18 - 15) + 15px);
      padding: 13px var(--spacer-small);
    }

    &__logout {
      padding: 30px var(--spacer-small) 50px;
    }
  }
}