.breadcrumbs {
  &__item {
    display: inline;
    margin-right: 6px;

    &_delimiter {
      color: #CFCFCF;
      cursor: default;
      white-space: nowrap;
    }
  }

  &__link {
    .breadcrumbs {
      &__name {
        position: relative;

        &:hover {
          &::before {
            background-color: var(--text-color-border);
          }
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background-color: var(--t);
          transition: background-color var(--default-transition);
        }
      }
    }
  }

  &__name {
    font-size: 12px;
    line-height: 1;
    color: #999A9C;
  }
}