$gap-large: 20px;
$gap-small: 15px;

.assortment {
  &__main {
    overflow: hidden;
  }

  &__items {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    cursor: pointer;
    user-select: none;

    &::after,
    &::before {
      content: '';
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
  }

  &__number {
    position: absolute;
    top: 16px;
    left: -22px;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 60px;
    line-height: 50/60*1em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.3);
  }

  &__name {
    position: relative;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.75em;
    text-transform: uppercase;
    color: #fff;
  }

  &__shadow {
    background: rgba(7, 17, 40, 0.65);
  }

  &__picture,
  &__shadow {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .assortment {
    &__description {
      margin-top: 27px;
    }

    &__main {
      padding: 110px 0 125px 80/1675*100vw;
    }

    &__items {
      grid-gap: $gap-large;

      &::before {
        display: none;
      }

      &::after {
        width: calc(var(--spacer-large) - #{$gap-large});
      }
    }
  }
}

@include respond-up('medium') {
  .assortment {
    &__item {
      height: 246px;
      width: 290px;
      padding: 28px 25px;
    }

    &__name {
      font-size: 12px;
    }
  }
}

@include respond('medium') {
  .assortment {
    &__bar {
      margin: 70px var(--spacer-medium) 30px;
    }

    &__main {
      margin-bottom: 50px;
    }

    &__items {
      &::after,
      &::before {
        width: calc(var(--spacer-medium) - #{$gap-small});
      }
    }
  }
}

@include respond-down('medium') {
  .assortment {
    &__description {
      margin-top: 22px;
    }

    &__items {
      grid-gap: $gap-small;
    }
  }
}

@include respond('small') {
  .assortment {
    &__bar {
      margin: 40px var(--spacer-small) 30px;
    }

    &__items {
      &::after,
      &::before {
        width: calc(var(--spacer-small) - #{$gap-small});
      }
    }
  }
}

@include respond-down('small') {
  .assortment {
    &__main {
      margin-bottom: 30px;
    }

    &__item {
      height: 200px;
      width: 240px;
      padding: 28px 15px 28px 20px;
    }

    &__name {
      font-size: 11px;
    }
  }
}

@include respond-down('x-small') {
  .assortment {
    &__bar {
      margin: 40px var(--spacer-smaller) 30px;
    }


    &__items {
      &::after,
      &::before {
        width: calc(var(--spacer-smaller) - #{$gap-small});
      }
    }
  }
}