.contacts-bottom {
  &__content {
    display: flex;
  }

  &.developers-contacts__bottom-block {
    border-top: none;
    background-color: var(--background-color);
  }
}

@include respond-up('large') {
  .contacts-bottom {
    padding: 80px 0 125px;
    position: relative;
    border-top: 1px solid var(--border-color);

    &.developers-contacts__bottom-block {
      padding: 0 0 125px;
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      border-left: 1px solid var(--border-color);
      top: 0;
      left: calc(100% / 3);
      bottom: 0;
    }

    &__tabs-wrap {
      display: flex;
      justify-content: flex-end;
    }

    &__tabs {
      padding: 0 var(--spacer-large) 0 80px;
    }

    &__map-wrap {
      padding: 55px var(--spacer-large) 0 80px;
    }

    &__map {
      height: 620px;
    }

    &__info-wrap {
      flex: 0 0 calc(100% / 3);
      padding: 55px 80px 0 var(--spacer-large);
    }

    &__map-wrap,
    &__tabs {
      flex: 0 0 calc(100% * 2 / 3);
    }
  }
}

@include respond-up('medium') {
  .contacts-bottom {

  }
}

@include respond('medium') {
  .contacts-bottom {
    padding: 50px var(--spacer-medium);

    &__content {
      margin-top: 46px;
    }

    &__map-wrap {
      margin-top: 50px;
    }

    &__map {
      height: 300px;
    }
  }
}

@include respond-down('medium') {
  .contacts-bottom {
    &__content {
      display: flex;
      flex-direction: column;
    }

    &__map {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .contacts-bottom {
    padding: 30px 0 40px;

    &__tabs-wrap,
    &__info-wrap {
      padding: 0 var(--spacer-small);
    }

    &__content {
      margin-top: 36px;
    }

    &__map-wrap {
      margin-top: 40px;
    }

    &__map {
      height: 328px;
    }
  }
}