.quiz {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

@include respond-up('large') {
  .quiz {

  }
}

@include respond-up('medium') {
  .quiz {

  }
}

@include respond('medium') {
  .quiz {

  }
}

@include respond-down('medium') {
  .quiz {

  }
}

@include respond-down('small') {
  .quiz {

  }
}