.about-page {

  &__schema-name {
    display: none;
  }
  &__picture {
    display: flex;
  }

  &__image {
    height: 604/1675*100vw;
    width: 100%;
    object-fit: cover;
  }

  &__border {
    border-bottom: 1px solid var(--border-color);
  }
}

@include respond-up('large') {
  .about-page {
    &__image-block {
      padding: 0 var(--spacer-large);
    }

    &__slider {
      padding: 100px 0;
    }
  }
}

@include respond('medium') {
  .about-page {
    &__image-block {
      padding: 0 var(--spacer-medium);
    }

    &__slider {
      padding: 50px 0;
    }
  }
}

@include respond-down('small') {
  .about-page {
    &__image-block {
      padding: 0 12/375*100vw;
    }

    &__slider {
      padding: 30px 0;
    }
  }
}