.button {
  margin: 0;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: var(--button-color);
  background: none;
  border: 1px solid var(--button-color);
  font-family: var(--h-font);
  font-weight: 700;
  font-size: 11px;
  line-height: 1.33;
  text-transform: uppercase;
  transition: background-color var(--default-transition), color var(--default-transition);

  &__line {
    background: unset;
    border: unset;
    color: unset;
    line-height: 133%;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 100%;
      border-bottom: 2px solid var(--search-button-border);
      transition: transform .2s ease-out;
    }

    &::before {
      transform-origin: right;
    }

    &::after {
      transform-origin: left;
      transform: scale(0);
    }
  }

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &:hover {
    //&:not(.button__line) {
      background-color: var(--button-color);
      color: #fff;
    //}
  }

  &_dark {
    border: 1px solid var(--border-color);
    color: var(--text-color-dark);

    &:hover {
      color: white;
      background-color: var(--text-color-dark);
    }
  }

  &_orange {
    background: var(--button-color);
    color: #fff;
    font-family: var(--h-font);
    font-weight: 700;
    line-height: 133%;
  }
}

@include respond-up('large') {
  .button {
    padding: 23px 34px;

    &__line {
      padding: unset;

      &:hover {
        color: var(--text-color-dark);
        background: unset;
        &::before {
          transition: transform .3s var(--default-bezier);
          transform: scaleX(0);
        }

        &::after {
          transition: transform .3s var(--default-bezier) .33s;
          transform: scaleX(1);
        }
      }
    }

    &_orange {
      padding: 20px 57px;
    }
  }
}

@include respond-up('medium') {
  .button {
    &_orange {
      font-size: 13px;
    }
  }
}

@include respond-down('medium') {
  .button {
    padding: 20px 30px;

    &__line {
      padding: unset;
      &::after {
        content: none;
      }
    }

    &_orange {
      padding: 20px 50px;
    }
  }
}

@include respond-down('small') {
  .button {
    &_orange {
      padding: 14px 34px;
      font-size: 11px;
    }
  }
}