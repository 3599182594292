.profile-menu {
  padding: 0 var(--spacer-large);

  &__nav {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 60px 0;
  }

  &__link {
    padding: 20px 0;
    transition: color var(--default-transition);

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color);
    }

    &:hover {
      color: var(--primary-color)
    }

    &._active {
      display: flex;
      align-items: center;
      color: var(--primary-color);

      &::before {
        content: '';
        display: block;
        height: 6px;
        width: 6px;
        margin-right: 18px;
        margin-bottom: 2px;
        border-radius: 100px;
        background-color: var(--primary-color);
      }
    }
  }
}