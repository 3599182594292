.footer {
  display: grid;
  border-top: 1px solid var(--border-color);
  box-sizing: border-box;

  &__badge-icon,
  &__badge-picture {
    position: absolute;
  }

  &__nav {
    background-color: var(--background-color);
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__nav-title {
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 2;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--text-color-dark);
    padding-bottom: 19px;
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__nav-item {
    font-size: 14px;
    line-height: 1.57;
    color: var(--text-color);
  }

  &__nav-link {
    display: inline-block;
    padding: 6px 0;

    &:hover {
      .footer__nav-name {
        color: var(--text-color-dark);
      }
    }
  }

  &__nav-name {
    transition: color .25s;
  }

  &__lk {
    position: relative;
    display: inline-block;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33;
    letter-spacing: 0.08em;
    color: var(--text-color-dark);
    text-transform: uppercase;
    padding-bottom: 6px;

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform-origin: right;
      transition: transform .2s ease-out;
    }

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .2s ease-out;
    }
  }

  &__phone {
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.37;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--text-color-dark);
    padding-bottom: 10px;
    max-width: 260px;
  }

  &__mail {
    font-size: 14px;
    line-height: 1;
    padding-top: 5px;
    max-width: 260px;
  }

  &__address {
    font-size: 14px;
    line-height: 1.71;
    max-width: 260px;
    padding-top: 10px;
    margin-top: 20px;
    color: var(--text-color);
  }

  &__rights {
    font-size: 13px;
    line-height: 13px;
    color: var(--footer-text-color);
  }

  &__policy-link {
    position: relative;
    font-size: 13px;
    line-height: 13px;
    color: var(--footer-text-color);
    white-space: nowrap;
    transition: color var(--default-transition);

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: var(--text-color-dark);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }
  }

  &__developer {
    font-size: 13px;
    line-height: 13px;
    color: var(--footer-text-color);
  }

  &__developer-link {
    position: relative;
    color: var(--text-color-dark);

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: var(--text-color-dark);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }
  }

  &__badge-icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .footer {
    grid-template-areas: 'nav partners contacts'
                        'label label developer';
    grid-template-columns: 4fr 2fr 3fr;

    &__badge-icon,
    &__badge-picture {
      top: 50px;
      right: 67px;
      width: 71px;
      height: 120px;
    }

    &__nav {
      grid-area: nav;
      border-bottom: 1px solid var(--border-color);
      box-sizing: border-box;
      padding: 52px 80/1675*100vw 64px 65px;
      display: flex;
      justify-content: space-between;
    }

    &__services {
      padding-right: 65px;
      flex: 2 1 0;
    }

    &__company {
      flex: 1 1 0;

      .footer__nav-name {
        white-space: nowrap;
      }
    }

    &__partners {
      grid-area: partners;
      border-bottom: 1px solid var(--border-color);
      box-sizing: border-box;
      padding: 52px 47/1675*100vw 64px;
    }

    &__lk {
      margin-top: 122px;

      &:hover {
        &::after {
          transform: scaleX(1);
          transition: transform .3s var(--default-bezier) .33s;
        }

        &::before {
          transform: scaleX(0);
          transition: transform .3s var(--default-bezier);
        }
      }
    }

    &__contacts {
      grid-area: contacts;
      padding: 49px 65/1675*100vw 68px;
      border-bottom: 1px solid var(--border-color);
      box-sizing: border-box;
    }

    &__mail {
      padding-bottom: 30px;
    }

    &__address {
      padding-bottom: 71px;
    }

    &__label {
      grid-area: label;
      background-color: var(--background-color);
      display: grid;
      grid-template-columns: 1fr .5fr;
    }

    &__rights {
      display: flex;
      align-items: center;
      padding-left: 65px;
    }

    &__developer {
      grid-area: developer;
      padding: 39px 65/1675*100vw 37px;
    }

    &__policy {
      display: flex;
      padding: 39px 47/1675*100vw 37px;
    }

    &__policy-link {
      padding-bottom: 2px;

      &:hover {
        color: var(--text-color-dark);

        &::before {
          left: 0;
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .3s var(--default-bezier);
        }
      }
    }

    &__developer-link {
      padding-bottom: 2px;

      &:hover {
        color: var(--text-color-dark);

        &::before {
          left: 0;
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .3s var(--default-bezier);
        }
      }
    }

    &_line {
      &::before {
        content: '';
        height: 100%;
        border-left: 1px solid var(--border-color);
        grid-row: 1;
        grid-column: 3;
      }
    }
  }
}

@include respond('medium') {
  .footer {
    grid-template-areas:
            'nav nav'
            'partners contacts'
            'label label'
            'developer developer';
    grid-template-columns: 1fr 1fr;

    &__badge-icon,
    &__badge-picture {
      width: 60px;
      height: 101px;
    }

    &__nav {
      grid-area: nav;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 53px var(--spacer-medium) 40px;
    }

    &__services {
      padding-right: 15px;
    }

    &__company {
      padding-left: 15px;
    }

    &__partners {
      grid-area: partners;
      padding: 40px 15px 53px var(--spacer-medium);
    }

    &__lk {
      margin-top: 70px;
    }

    &__contacts {
      grid-area: contacts;
      padding: 40px var(--spacer-medium) 53px 15px;
    }

    &__label {
      grid-area: label;
      padding: 25px var(--spacer-medium) 0;
    }

    &__rights {
      padding: 10px 0;
    }

    &__policy {
      display: inline-block;
      padding: 10px 0;
    }

    &__developer {
      grid-area: developer;
      display: block;
      padding: 10px var(--spacer-medium);
      margin-bottom: 25px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    background-color: var(--background-color);

    &__badge-icon,
    &__badge-picture {
      top: 40px;
      right: 0;
    }

    &__mail {
      padding-bottom: 5px;
    }

    &__address {
      padding-bottom: 10px;
      margin-bottom: 30px;
    }

    &__label {
      border-top: 1px solid var(--border-color);
    }
  }
}

@include respond-down('small') {
  .footer {
    &__badge-icon,
    &__badge-picture {
      width: 51px;
      height: 86px;
    }

    &__nav {
      padding: 40px var(--spacer-small) 34px;
    }

    &__nav-title {
      padding-bottom: 10px;
    }

    &__services {
      padding-bottom: 34px;
    }

    &__partners {
      padding: 0 var(--spacer-small) 40px;
      border-bottom: 1px solid var(--border-color);
    }

    &__lk {
      margin-top: 34px;
    }

    &__contacts {
      padding: 40px var(--spacer-small);
    }

    &__label {
      padding: 20px var(--spacer-small) 0;
    }

    &__rights {
      line-height: 20px;
      padding: 10px 0;
    }

    &__policy {
      display: inline-block;
      padding: 10px 0;
    }

    &__developer {
      display: block;
      padding: 10px var(--spacer-small);
      margin-bottom: 25px;
    }
  }
}

@media print {
  .footer {
    display: none;
  }
}
