.producer-head-text {
  &__button-wrap {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__button {
    width: max-content;
  }

  &__logo-picture {
    display: flex;
  }

  &__logo-image {
    width: 100%;
    height: 100%;
  }

  &__description {
    font-weight: normal;
    line-height: 1.75em;
    color: var(--text-color-dark);

    article {
      margin-top: 0;
    }
  }

  &__item-title {
    font-weight: normal;
    line-height: 1.75em;
    font-family: var(--h-font);
  }

  &__item-description {
    font-weight: normal;
    line-height: 1.75em;
    color: var(--text-color);

    article {
      margin-top: 0;
    }
  }

  &__list-item {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 1px;
      background-color: black;
      left: 0;
      top: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include respond-up('large') {
  .producer-head-text {
    display: flex;
    &__item {
      &_left {
        flex: 0 0 auto;
        border-right: 1px solid var(--border-color);
        padding: 80px calc((216 / 1675) * 100vw) 125px 0;
      }

      &_right {
        flex: 1 1 auto;
        padding: 80px calc((247 / 1675) * 100vw) 125px calc((83 / 1675) * 100vw);
      }
    }

    &__logo-picture {
      width: 200px;
    }

    &__button {
      padding: 22px 39px 23px;
      margin-top: 35px;
    }

    &__description {
      font-size: 19px;
      margin-bottom: 70px;
    }

    &__item-title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &__item-description {
      font-size: 16px;
    }

    &__list-item {
      padding-left: 48px;
      margin-bottom: 51px;
    }

    &__title {
      font-size: 22px;
      line-height: 155%;
    }

    &__button-wrap {
      max-width: 428px;
      margin-top: 70px;
    }

    &__video-part{
      padding-bottom: 70px;
    }
  }
}

@include respond('medium') {
  .producer-head-text {
    padding: 50px 0;
    &__item-title {
      margin-bottom: 12px;
    }

    &__list-item {
      margin-bottom: 30px;
    }

    &__item {
     &_left {
       display: flex;
       //align-items: center;
       //justify-content: space-between;
     }
    }

    &__description {
      margin-bottom: 30px;
    }

    &__button {
      margin-top: 11px;
    }

    &__title {
      font-size: 18px;
      line-height: 175%;
    }

    &__button-wrap {
      margin-left: calc((104 / 768) * 100vw);
    }
    &__video-part{
      padding-bottom: 50px;
    }
  }
}

@include respond-down('medium') {
  .producer-head-text {
    &__description {
      font-size: 16px;
    }

    &__item-title {
      font-size: 18px;
    }

    &__item-description {
      font-size: 15px;
    }

    &__item {
      &_left {
        margin-bottom: 30px;
      }
    }

    &__logo-picture {
      width: 139px;
    }

    &__list-item {
      padding-left: 38px;
    }
  }
}

@include respond-down('small') {
  .producer-head-text {
    padding: 30px 0 40px;
    &__item-title {
      margin-bottom: 10px;
    }

    &__list-item {
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 20px;
    }

    &__logo-picture {
      margin-bottom: 20px;
    }

    &__button {
      margin-top: 20px;
    }

    &__title {
      font-size: 16px;
      line-height: 155%;
    }
    &__video-part{
      padding-bottom: 30px;
    }
  }
}