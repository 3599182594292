.floating-field {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: var(--text-color-dark);
    background-color: var(--background-color);
    font-weight: 400;
    line-height: 25px;
    transition: all 0.2s;
    margin: 0;
    height: calc(100% - 2px);
  }

  &._filled label,
  input:focus + label,
  textarea:focus + label {
    color: var(--tag-color);
    width: 100%;
    font-size: 13px;
    background-color: transparent;
  }

  &._filled label,
  input:focus + label,
  textarea:focus + label {
    font-size: 13px;
    height: 12px;
  }

  textarea {
    resize: none !important;
  }

  button[data-show-password] {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    padding: 10px;
    margin: 0 -10px;
    background-color: var(--t);
    transform: translateY(-50%);
    z-index: 10;

    &:hover {
      .svg-eye {
        &__sclera {
          stroke: var(--text-color-dark);
        }

        &__iris {
          fill: var(--text-color-dark);
        }
      }
    }

    & + input {
      padding-right: 26px;
    }

    .svg-eye {
      &__sclera {
        transition: stroke var(--default-transition);
      }

      &__iris {
        transition: fill var(--default-transition);
      }
    }
  }
}

@include respond-up('large') {
  .floating-field {
    .radio-list {
      & + label {
        padding: 20px 0;
      }
    }
  }
}

@include respond('medium') {
  .floating-field {
    .radio-list {
      & + label {
        padding: 20px 0;
      }
    }
  }
}

@include respond-down('small') {
  .floating-field {

    &._filled label,
    input:focus + label,
    textarea:focus + label,
    textarea:focus + label {
      font-size: 12px;
      height: 12px;
    }

    .radio-list {
      & + label {
        padding: 18px 0;
      }
    }
  }
}