.why {
  background-color: var(--background-color);
  overflow: hidden;

  &__item {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .why {
    &__wrap {
      padding-top: 80px;
      padding-bottom: 100px;
    }

    &__main {
      margin-top: 56px;
    }

    &__items {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 70px;
    }

    &__item {
      &.tns-item {
        width: calc((349/1675 * 100vw) + 50px);
      }
    }

    &__name {
      margin-top: 30px;
    }

    &__description {
      margin-top: 16px;
    }

    &__slider-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond-up('medium') {
  .why {
    &__items {
      display: grid;
      grid-auto-rows: auto;
    }
  }
}

@include respond('medium') {
  .why {
    &__wrap {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__main {
      margin-top: 40px;
    }

    &__items {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 45px;
      grid-row-gap: 60px;
    }

    &__name {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .why {
    &__description {
      margin-top: 14px;
    }

    &__nav-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .why {
    &__wrap {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__main {
      margin-top: 22px;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__name {
      margin-top: 15px;
    }
  }
}