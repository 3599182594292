.year-card {
  display: flex;

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .year-card {
    column-gap: calc(52/1675*100vw);

    &_left {
      flex-direction: row-reverse;
    }

    &__picture {
      width: calc(329/1675*100vw);
      height: calc((329/1675*100vw) * 250/329);
    }

    &__text-wrap {
      width: calc(325/1675*100vw);
    }

    &__text {
      margin-top: 25px;
    }
  }
}

@include respond-up('medium') {
  .year-card {
    &_left {
      .year-card {
        &__year {
          text-align: right;
        }
      }
    }
  }
}

@include respond('medium') {
  .year-card {
    row-gap: 30px;
    height: 100%;
    justify-content: space-between;

    &__picture {
      width: calc(304/768*100vw);
      height: calc((304/768*100vw) * 232/304);
    }

    &__text {
      margin-top: 16px;
    }
  }
}

@include respond-down('medium') {
  .year-card {
    flex-direction: column;
  }
}

@include respond-down('small') {
  .year-card {
    row-gap: 16px;

    &__picture {
      width: calc(201/375*100vw);
      height: calc((201/375*100vw) * 153/201);
    }

    &__text {
      margin-top: 16px;
    }
  }
}