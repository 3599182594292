.dictionary {
  display: flex;
  border-top: 1px solid var(--border-color);

  &__title {
    display: block;
  }

  &__bar {

  }

  &__sticky-block {

  }

  &__sticky-nav {

  }

  &__sticky-list {

  }

  &__sticky-item {
    cursor: pointer;
  }

  &__sticky-text {
    line-height: 22px;
    color: var(--text-color);
    transition: color var(--default-transition);
    display: block;
    user-select: none;
    -webkit-user-drag: none;
  }

  &__sticky-circle {

  }
  &__list {

  }

  &__item {
    background-color: var(--background-color);

    &:not(:first-of-type) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__name {
    text-transform: uppercase;
  }

  &__text {

  }

  &__use-products {

  }

  &__use-products-title {
    display: inline-block;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.55;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__use-products-list {
    display: flex;
  }

  &__use-products-link {
    padding: 7px 10px;
    background-color: #fff;
  }
}

@include respond-up('large') {
  .dictionary {
    &__title {
      padding-bottom: 75px;
    }

    &__bar {
      flex: 0 0 calc(100%/3 + 1px);
    }

    &__sticky-block {
      position: sticky;
      top: 0;
      left: 0;
      padding: 80px var(--spacer-large) 145px;
    }

    &__sticky-nav {
      position: relative;
      transition: opacity 0.1s;
    }

    &__sticky-list {

    }

    &__sticky-item {
      &._active {
        .dictionary__sticky-text {
          color: var(--text-color-dark);
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 17px;
      }

      &._active {
        .dictionary__sticky-text {
          position: relative;
          display: block;
        }
      }
    }

    &__sticky-text {
      line-height: 1.6;
      padding-left: 30px;
    }

    &__sticky-circle {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      top: 7px;
      left: 0;
      border-radius: 50%;
      background-color: var(--primary-color);

      &:nth-of-type(2) {
        opacity: 0;
      }
    }

    &__list {

    }

    &__item {
      padding: 60px 80/1675*100vw 52px;
    }

    &__name {
      padding-bottom: 28px;
      font-size: 22px;
    }

    &__text {

    }

    &__use-products {
      padding-top: 50px;
    }

    &__use-products-title {
      padding-bottom: 15px;
      font-size: 16px;
      cursor: default;

      svg {
        display: none;
      }
    }

    &__links-wrapper {
      height: auto !important;
    }

    &__use-products-list {

    }

    &__use-products-link {
    }
  }
}

@include respond-up('medium') {
  .dictionary {

    &__bar {

    }

    &__sticky-block {

    }

    &__sticky-nav {

    }

    &__sticky-list {

    }

    &__sticky-item {

    }

    &__sticky-text {
      font-size: 15px;
    }

    &__sticky-circle {

    }

    &__list {

    }

    &__item {

    }

    &__name {

    }

    &__text {

    }

    &__use-products {

    }

    &__use-products-list {
      flex-wrap: wrap;
    }

    &__use-products-link {
      margin-bottom: 8px;

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
}

@include respond('medium') {
  .dictionary {
    &__title {
      padding-bottom: 30px;
    }

    &__bar {

    }

    &__sticky-block {

    }

    &__sticky-nav {

    }

    &__sticky-list {
      padding: 21px calc(var(--spacer-medium) - 15px) 13px;
    }

    &__sticky-item {

    }

    &__sticky-text {
      padding: 0 15px;

      span {
        font-size: 16px;
      }
    }

    &__sticky-circle {

    }

    &__list {

    }

    &__item {
      padding: 60px var(--spacer-medium) 32px;
    }

    &__name {
      padding-bottom: 15px;
    }

    &__text {

    }

    &__use-products {
      padding-top: 40px;
    }

    &__use-products-title {
      padding-bottom: 8px;
      font-size: 14px;

      svg {
        margin-left: 10px;
      }
    }

    &__use-products-list {
      padding-top: 8px;
    }

    &__use-products-link {

    }
  }
}

@include respond-down('medium') {
  .dictionary {
    flex-direction: column;

    &__bar {

    }

    &__sticky-block {
      background-color: var(--background-color);
    }

    &__sticky-nav {

    }

    &__sticky-list {
      display: flex;
      flex-wrap: wrap;
    }

    &__sticky-item {
      margin-bottom: 12px;
    }

    &__sticky-text {
      display: block;

      span {
        position: relative;
        display: block;
        line-height: 1.55;
        padding-bottom: 4px;
        font-weight: 400;
        white-space: nowrap;
        transition: color var(--default-transition);
      }
    }

    &__sticky-circle {
      display: none;
    }

    &__list {
      border-top: 1px solid var(--border-color);
    }

    &__item {

    }

    &__name {

    }

    &__text {

    }

    &__use-products {

    }

    &__use-products-title {
      display: flex;
      align-items: baseline;

      svg {
        width: 11px;
        transition: transform var(--default-transition);
      }

      &._active {
        svg {
          transform: translateY(3px) rotate(180deg);
        }
      }
    }

    &__links-wrapper {
      position: relative;
      overflow: hidden;
    }

    &__use-products-list {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__use-products-link {

    }
  }
}

@include respond-down('small') {
  .dictionary {
    &__title {
      padding-bottom: 20px;
    }

    &__bar {

    }

    &__sticky-block {

    }

    &__sticky-nav {

    }

    &__sticky-list {
      padding: 23px calc(var(--spacer-small) - 7.5px) 6px;
    }

    &__sticky-item {

    }

    &__sticky-text {
      font-size: 14px;
      padding: 0 7.5px;
    }

    &__sticky-circle {

    }

    &__list {

    }

    &__item {
      padding: 30px var(--spacer-small) 24px;
    }

    &__name {
      padding-bottom: 12px;
    }

    &__text {

    }

    &__use-products {
      padding-top: 25px;
    }

    &__use-products-title {
      padding-bottom: 6px;
      font-size: 12px;

      svg {
        margin-left: 9px;
      }
    }

    &__use-products-list {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 12px;

      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }

    &__use-products-link {
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }

  }

  &__name {

  }

  &__text {
  }
}