.menu {
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: calc(100dvh - 100%);
  background-color: var(--background-color);
  transition: transform var(--default-transition), background-color var(--default-transition);

  &._active {
    opacity: 1;
  }

  &__categories {
    overflow-y: scroll;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__categories-list {
    counter-reset: counter;

    &._active {
      .menu__categories-link::before {
        opacity: 1;
        transition: opacity 0.6s 1.3s;
      }
    }
  }

  &__categories-item {
    list-style: none;
  }

  &__categories-link {
    display: flex;
    position: relative;
    flex-direction: column;

    &::before {
      counter-increment: counter;
      content: counters(counter, ".", decimal-leading-zero);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      font-family: var(--h-font);
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      color: var(--menu-numbers-color);
      transition: opacity 0.6s;
      opacity: 0;
    }
  }

  &__categories-name-wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &__categories-name {
    align-items: center;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.75;
    text-transform: uppercase;
    color: var(--menu-link-color);
    transition: color 0.3s ease-in-out;
  }

  &__projects-name {
    display: inline-block;
    line-height: 1.55;
  }
}

@include respond-up('large') {
  .menu {
    &__inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__categories {
      width: calc(663*100vw/1675);
      padding: 55px 0;
    }

    &__categories-list {
      &:hover {
        .menu__projects {
          &::before {
            width: calc(95*100vw/1675);
          }
        }
      }
    }

    &__categories-item {
      counter-reset: delay;
      counter-set: delay = 1;

      &:hover {
        .menu__projects-list {
          opacity: 1;
          transition: opacity 0s;
        }

        .menu__projects-item {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0);
          transition: transform .3s , opacity .3s;
        }

        .menu__categories-name {
          color: var(--text-color-dark);
        }

        .menu__projects {
          pointer-events: auto;
          z-index: 20;
        }
      }
    }

    &__categories-link {
      box-sizing: content-box;
      margin-left: 160*100vw/1675;
      border-bottom: 1px solid var(--border-color);
      padding: 35px  0;

      &:before {
        left: calc(0px - 160*100vw/1675 - 13px);
        height: 45px;
        font-size: 60/1675*100vw;
      }

      &_mobile {
        display: none;
      }
    }

    &__categories-name {
      font-size: 20/1675*100vw;
    }

    &__projects {
      position: absolute;
      left: 663*100vw/1675;
      top: 0;
      display: flex;
      flex-direction: column;
      padding: 98px 84px 90px 285/1675*100vw;
      width: calc(100vw - 663*100vw/1675 - 15px);
      height: 100%;
      z-index: 10;
      pointer-events: none;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-color: var(--menu-line-color);
        position: absolute;
        top: 118px;
        left: calc(150*100vw/1675);
        margin: auto;
        transition: width .3s;
      }

      &:hover {
        opacity: 1;
        pointer-events: auto;
        z-index: 20;
      }
    }

    &__projects-list {
      opacity: 0;
      transition: opacity .2s;
    }

    &__projects-item {
      display: flex;
      opacity: 0;
      pointer-events: none;
      transform: translateY(50px);
      transition: opacity .2s, transform .2s .25s;
    }

    &__projects-link {
      padding: 10px;

      &:hover {
        .menu__projects-name {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
    }

    &__projects-name {
      font-size: 15px;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: var(--text-color-dark);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s var(--default-bezier);
      }
    }

    &__nav-list {
      display: none;
    }
  }
}

@include respond('medium') {
  .menu {
    &__projects-item {
      &:first-of-type {
        .menu__projects-name {
          padding-top: 5px;
        }
      }
    }

    &__categories-link {
       overflow: hidden;
       z-index: 20;
       padding: 15px 68/768*100vw;
       background-color: var(--background-color);

       &::before {
         left: -9px;
         height: 35px;
         font-size: 30/768*100vw;
       }
    }
  }
}

@include respond-down('medium') {
  .menu {
    overflow-y: auto;

    &__inner {
      padding: 47px 0 35px;
    }

    &__categories-link {
      overflow: hidden;
      z-index: 20;
      background-color: var(--background-color);

      &_desktop {
        display: none;
      }

      &._active {
        .menu__categories-name {
          color: var(--text-color-dark);
        }
      }
    }

    &__categories-item {
      position: relative;

      &:hover {
        .menu__categories-name {
          color: var(--text-color-dark);
        }
      }
    }

    &__projects {
      overflow: hidden;
      height: 0;
      position: relative;
      transition: height var(--default-transition);
      background-color: var(--background-color);
    }

    &__projects-list {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 17.5px;
    }

    &__projects-name {
      font-size: 15px;
      padding: 7.5px 70px;
    }

    &__hr {
      margin: 0 37px 0 25px;
      border-bottom: 1px solid var(--border-color);
    }
  }
}

@include respond-down('small') {
  .menu {
    &__inner {
      padding: 27.5px 0;
    }

    &__categories {

    }

    &__categories-link {
      padding: 12.5px var(--spacer-small);

      &::before {
        content: none;
      }
    }

    &__categories-name {
      font-size: 15px;
    }

    &__projects-list {
      padding-bottom: 5px;
    }

    &__projects-name {
      padding: 7.5px var(--spacer-small);
    }

    &__hr {
      margin: 0 var(--spacer-small);
    }
  }
}

