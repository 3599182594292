.video-block {
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-top: 9/16*100%;

    &:hover {
      .video-block__play {
        border-color: #fff;
      }
    }
  }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 10;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid var(--slide-tag-color);
    border-radius: 50%;
    transition: border-color var(--default-transition);
    z-index: 20;
  }

  &__iframe {
    width: 320/375*100vw;
    height: 181/375*100vw;

    .fslightbox-source {
      display: none;
    }
  }

  &__youtube-framed {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .video-block {
    &__play {
      width: 70px;
      height: 70px;
    }
  }
}

@include respond-down('medium') {
  .video-block {
    &__play {
      width: 60px;
      height: 60px;
    }
  }
}

