.catalog-item {
  &__content-container {
    position: relative;
  }

  &__content {
    display: block;
  }

  &__image {
    width: 100%;
  }

  &__link-list {
    position: absolute;
    z-index: 10;
    right: 30px;
    bottom: 30px;
    left: 40px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--border-light-white-color);
    overflow: hidden;
  }

  &__link-item {
    border-left: 1px solid var(--border-light-white-color);
    border-bottom: 1px solid var(--border-light-white-color);
    margin-bottom: -1px;
    margin-left: -1px;

    &:last-child {
      border-right: none;
    }

    &:first-child {
      border-left: none;
    }
  }

  &__link {
    color: #fff;
    font-family: var(--h-font);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    padding: 12px 18px;
    display: flex;
    transition: all var(--default-transition);

    &:hover {
      background-color: var(--primary-color);
    }
  }

  &__link-text {
    white-space: nowrap;
  }

  &__name {
    text-transform: uppercase;
    font-weight: 500;
    font-family: var(--h-font);
    line-height: 1.55;
    padding: 10px 0;
    margin: -10px 0;
  }
}

@include respond-up('large') {
  .catalog-item {
    width: 100%;

    &__content-container {
      margin-bottom: 35px;

      &:hover {
        .catalog-item {
          &__link-list {
            opacity: 1;
          }

          &__link {
            pointer-events: auto;
          }

          &__link-text {
            transform: translateY(0);
          }

          &__content {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &__content {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180.8deg, rgba(7, 20, 51, 0.73) 1.79%, rgba(7, 20, 51, 0) 99%);
        transform: matrix(1, 0, 0, -1, 0, 0);
        opacity: 0;
        transition: all var(--default-transition);
      }
    }

    &__image {
      margin-bottom: -6px;
    }

    &__link-list {
      transition: all var(--default-transition);
      opacity: 0;
      overflow: hidden;
    }

    &__link-item {

    }

    &__link {
      pointer-events: none;
    }

    &__link-text-animation-wrapper {
      display: inline-block;
      overflow: hidden;
    }

    &__link-text {
      display: inline-block;
      transform: translateY(11px);
      transition: transform .2s .25s var(--default-bezier);
    }

    &__description {
      margin-bottom: 75px;
    }
  }
}

@include respond('medium') {
  .catalog-item {
    &__content-container {

      &:hover {
        .catalog-item {
          &__content {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &__content {
      margin-bottom: 15px;
    }

    &__image {
      margin-bottom: -6px;
    }

    &__link-list {
      display: none;
    }

    &__link-item {

    }

    &__link {

    }

    &__name {
      font-size: 14px;
    }

    &__description {
      display: none;
    }
  }
}

@include respond-down('small') {
  .catalog-item {
    &__content-container {

      &:hover {
        .catalog-item {
          &__content {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &__content {
      margin-bottom: 15px;
    }

    &__image {
      margin-bottom: -6px;
    }

    &__link-list {
      display: none;
    }

    &__name {
      font-size: 14px;
    }

    &__description {
      display: none;
    }
  }
}