.developers-contacts-main-block {
  width: 100%;
}

@include respond-up('large') {
  .developers-contacts-main-block {
    &__content {
      margin-top: 65px;
    }

    &__description{
      max-width: 725px;
    }

    &__managers-wrap {
      padding: 0 var(--spacer-large) 0 80px;
    }

    &__tabs {
      padding: 0 var(--spacer-large) 0 80px;
    }

    &__title-table{
      display: none;
    }
  }
}

@include respond-up('medium') {
  .developers-contacts-main-block {

  }
}

@include respond('medium') {
  .developers-contacts-main-block {
    &__content {
      margin-top: 27px;
    }
  }
}

@include respond-down('medium') {
  .developers-contacts-main-block {
    &__title-table{
      display: block;
    }
    &__description{
      margin-top: 22px;
    }

  }
}

@include respond-down('small') {
  .developers-contacts-main-block {
    &__content {
      margin-top: 30px;
    }
  }
}