.site-grid {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &::before {
    left: 1/3*100%;
    border-right: 1px solid var(--border-color);
  }

  &::after {
    right: 1/3*100%;
    border-left: 1px solid var(--border-color);
  }
}

@include respond-up('large') {
  .site-grid {
    &_hide-right-line {
      &::after {
        content: unset;
      }
    }
  }
}

@include respond('medium'){
  .site-grid{
    &_medium-half{
      &::before {
        left: 50%;
      }

      &::after {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .site-grid {
    display: none;
  }
}

@media print{
  .site-grid {
    display: none;
  }
}
