.quiz-slider {
  &__slider {
    flex-direction: column;
    &.swiper {
      display: none;

      &._active {
        display: block;
      }
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__answer-item {
    border-top: 1px solid var(--border-color);

    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }

    input {
      &:checked + label {
        border-color: var(--border-color);
      }
    }

    label {
      background: unset;
      border: unset;
    }
  }

  &__labels-wrap {
    display: flex;
  }

  &__sizes-labels {
    display: flex;
    column-gap: 4px;
  }

  &__label {
    background: #fff;
    display: flex;
    width: fit-content;
  }

  &__slider-tabs-list {
    display: flex;
    column-gap: 2px;
  }

  &__slider-tabs-item {
    background: rgba(0, 0, 0, .4);
    transition: background var(--default-transition);

    &._active {
      background: #000;
    }
  }

  &__slider-tabs {
    display: flex;
    color: #fff;
  }
}

@include respond-up('large') {
  .quiz-slider {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-gap: 20px;
    position: relative;

    &__slider-wrap {
      position: relative;
      grid-column: 1/11;
    }

    &__answers {
      grid-column: 11/17;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
    }

    &__picture {
      height: calc(541/958 * 100vh);
    }

    &__arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 50;

      &_left {
        left: 15px;
      }

      &_right {
        right: 15px;
      }
    }
  }
}

@include respond-up('medium') {
  .quiz-slider {
    &__labels-wrap {
      justify-content: space-between;
      margin-top: 12px;
    }

    &__label {
      padding: 6px 14px;
    }

    &__slider-tabs-list {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 50;
    }

    &__slider-tabs {
      padding: 8px 20px;
    }
  }
}

@include respond('medium') {
  .quiz-slider {
    &__answers {
      margin-top: 30px;
    }

    &__picture {
      height: calc((710/768 * 100vw) * 401/710)
    }
  }
}

@include respond-down('medium') {
  .quiz-slider {
    &__arrow {
      display: none;
    }

    &__slider-wrap {
      position: sticky;
      top: 0;
      background: var(--background-color);
    }
  }
}

@include respond-down('small') {
  .quiz-slider {
    &__picture {
      height: calc((355/375 * 100vw) * 189/335)
    }

    &__labels-wrap {
      margin-top: 10px;
      flex-wrap: wrap;
      gap: 4px;
    }

    &__label {
      padding: 4px 10px;
    }

    &__answers {
      margin-top: 18px;
    }

    &__slider-tabs {
      padding: 4px 10px;
      text-wrap: nowrap;
    }

    &__slider-wrapper {
      margin-top: 10px;
    }

    &__slider-tabs-list {
      width: 100%;
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}