.special-offer {
  border-top: 1px solid var(--border-color);

  &__item {
    display: flex;
    flex-direction: column;
    user-select: none;
    -webkit-user-drag: none
  }

  &__items {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    cursor: pointer;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__main {
    overflow: hidden;
  }

  &__strip {
    height: 1px;
    background-color: var(--border-color);
    position: relative;
  }

  &__strip-filling {
    height: 2px;
    background-color: var(--text-color-dark);
    position: absolute;
    bottom: 0;
    left: 0;
  }

}

@include respond-up('large') {
  .special-offer {
    padding: 100px var(--spacer-large) 125px;

    &__main {
      margin-top: 56px;

    }

    &__items {
      width: calc(1507 / 1675 * 100vw - 2px);
      margin-bottom: 121px;
      grid-column-gap: 40px;
    }

    &__item {
      width: 356px;
    }

    &__name {
      margin-top: 30px;
    }

    &__description {
      margin-top: 16px;
    }

    &__strip-filling {
      width: 579px;
    }
  }
}

@include respond-up('medium') {
  .special-offer {
    &__items {
      display: grid;
      grid-auto-rows: auto;
    }
  }
}

@include respond('medium') {
  .special-offer {
    padding: 0 var(--spacer-medium);
    &__wrap {
      margin: 50px 0;
    }

    &__main {
      margin-top: 40px;
    }

    &__items {
      margin-bottom: 45px;
      width: calc(706 / 768 * 100vw - 2px);
      grid-column-gap: 39px;
    }

    &__item {
      width: calc((327/768)*100vw);
    }

    &__name {
      margin-top: 20px;
    }

    &__strip-filling {
      width: 283px;
    }
  }
}

@include respond-down('medium') {
  .special-offer {
    &__description {
      margin-top: 14px;
    }
  }
}

@include respond-down('small') {
  .special-offer {

    &__wrap {
      margin: 30px 0;
    }

    &__main {
      margin-top: 22px;
    }

    &__title {
      padding: 0 0 0 var(--spacer-small);
    }

    &__items {
      padding: 0 0 0 var(--spacer-small);
      margin-bottom: 35px;
      grid-column-gap: 35px;
    }

    &__item {
      width: calc((215 / 375) * 100vw);
    }

    &__name {
      margin-top: 15px;
    }

    &__strip {
      margin:0 var(--spacer-small);
    }

    &__strip-filling {
      width: 116px;
    }
  }
}