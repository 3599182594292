@include respond-up('large') {
  .documents-page {
    padding: 65px 0 125px;
  }
}

@include respond('medium') {
  .documents-page {
    padding: 30px 0 70px;
  }
}

@include respond-down('small') {
  .documents-page {
    padding: 20px 0 45px;
  }
}