.team {
  &__items {
    display: grid;
  }
}

@include respond-up('large') {
  .team {
    &__description {
      margin-top: 28px;
    }

    &__main {
      padding: 100px var(--spacer-large) 125px 80/1675*100vw;
    }

    &__items {
      grid-column-gap: 20px;
      grid-row-gap: 50px;
    }
  }
}

@include respond-up('medium') {
  .team {
    &__items {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@include respond('medium') {
  .team {
    &__wrap {
      padding: 50px var(--spacer-medium) 50px;
    }

    &__main {
      margin-top: 40px;
    }

    &__items {
      grid-row-gap: 40px;
    }
  }
}

@include respond-down('medium') {
  .team {
    &__description {
      margin-top: 22px;
    }

    &__items {
      grid-column-gap: 15px;
    }
  }
}

@include respond('small') {
  .team {
    &__wrap {
      padding: 30px var(--spacer-small);
    }
  }
}

@include respond-down('small') {
  .team {
    &__main {
      margin-top: 30px;
    }

    &__items {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 25px;
    }
  }
}

@include respond-down('x-small') {
  .team {
    &__wrap {
      padding: 30px var(--spacer-smaller);
    }
  }
}