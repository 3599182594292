.material {
  &__card {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    display: flex;
  }

  &__picture {
    display: flex;
  }

  &__description {
    margin-top: 0;

    p {
      &:first-child {
        color: var(--text-color-dark);
      }
    }
  }
}

@include respond-up('large') {
  .material {
    &__image-wrapper {
      flex: 0 0 558/1675*100vw;
      padding: 80px 0 125px;
    }

    &__picture {
      width: 494/1675*100vw;
    }

    &__description {
      padding: 80px 81/1675*100vw 125px;
      max-width: var(--text-content-width);
      box-sizing: content-box;
    }
  }
}

@include respond-up('medium') {
  .material {
    &__description {
      background-color: var(--background-color);
    }
  }
}

@include respond('medium') {
  .material {
    &__image-wrapper {
      flex: 0 0 256/768*100vw;
      padding: 50px 0;
    }

    &__picture {
      width: 223/768*100vw;
    }

    &__description {
      padding: 50px 54/768*100vw 88px 30/768*100vw;
    }
  }
}

@include respond-down('small') {
  .material {
    &__card {
      background-color: var(--background-color);
      flex-direction: column;
      padding: 30px var(--spacer-small);
    }

    &__image-wrapper {
      padding-bottom: 30px;
    }

    &__picture {
      width: 283/375*100vw;
    }
  }
}