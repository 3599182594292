.worker {
  width: 100%;

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }

  &__name {
    color: var(--text-color-dark);
  }

  &__profession {
    font-size: 14px;
    line-height: 1.55em;
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .worker {
    &__image {
      height: 316/1675*100vw;
    }

    &__text {
      margin-top: 20px;
    }

    &__name {
      font-size: 17px;
      line-height: 24/17*1em;
    }

    &__profession {
      margin-top: 6px;
    }
  }
}

@include respond('medium') {
  .worker {
    &__image {
      height: 230/768*100vw;
    }

    &__text {
      margin-top: 14px;
    }

    &__profession {
      margin-top: 4px;
    }
  }
}

@include respond-down('medium') {
  .worker {
    &__name {
      font-size: 15px;
      line-height: 24/15*1em;
    }
  }
}

@include respond-down('small') {
  .worker {
    &__image {
      height: 158/375*100vw;
    }

    &__text {
      margin-top: 10px;
    }
  }
}