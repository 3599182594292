.about-top {
  &__button-wrap {
    display: flex;
    flex-direction: column;

    &_medium-down {
      display: none;
    }
  }

  &__bar-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__button {
    width: max-content;
    display: flex;
    align-items: center;
  }

  &__achievement-number,
  &__achievement-name {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.55em;
    color: var(--text-color-dark);
  }

  &__description-inner {
    margin-top: 0;
  }
}

@include respond-up('large') {
  .about-top {
    &__button-wrap {
      margin-top: 120px;
    }

    &__bar-title {
      font-size: 22px;
      line-height: 155%;
    }

    &__button {
      padding: 22px 39px 23px;
      margin-top: 35px;
    }

    &__container {
      display: flex;
    }

    &__bar {
      flex: 0 0 calc(1/3*(100% + (var(--spacer-large) * 2)) - var(--spacer-large));
      padding-right: 80/1675*100vw;
      border-right: 1px solid var(--border-color);
    }

    &__main {
      padding: 152px 80/1675*100vw 80px;
    }

    &__description {
      margin-bottom: 40px;
    }

    &__picture {
      margin-bottom: 90px;
    }

    &__short-description {
      margin-bottom: 60px;
    }

    &__achievements {
      max-width: var(--text-content-width);
    }

    &__achievement-text {
      margin-top: 15px;
    }
  }
}

@include respond-up('medium') {
  .about-top {
    &__achievements {
      display: flex;
      justify-content: space-between;
    }

    &__achievement {
      flex: 0 0 190px;
    }

    &__achievement-number {
      margin-right: 6px;
      font-size: 30px;
    }

    &__achievement-name {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .about-top {
    &__bar-title {
      font-size: 18px;
      line-height: 175%;
      margin-right: 22px;
    }

    &__button-wrap {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 70px;
    }

    &__button {
      white-space: nowrap;
    }

    &__main {
      margin: 30px 0 70px;
    }

    &__short-description {
      margin-bottom: 40px;
    }

    &__achievement-text {
      margin-top: 9px;
    }

    &__picture {
      margin-bottom: 70px;
    }
  }
}

@include respond-down('medium') {
  .about-top {
    &__button-wrap {
      &_desktop {
        display: none;
      }

      &_medium-down {
        display: flex;
      }
    }

    &__description {
      margin-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  .about-top {
    &__bar-title {
      font-size: 16px;
      line-height: 155%;
    }

    &__button-wrap {
      margin-bottom: 50px;
    }

    &__button {
      margin-top: 20px;
    }

    &__main {
      margin: 20px 0 30px;
    }

    &__short-description {
      margin-bottom: 25px;
    }

    &__achievement {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &__achievement-number {
      margin-right: 5px;
      font-size: 25px;
    }

    &__achievement-name {
      font-size: 12px;
    }

    &__achievement-text {
      margin-top: 6px;
    }

    &__picture {
      margin-bottom: 40px;
    }
  }
}