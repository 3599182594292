.about-main {
  border-bottom: 1px solid var(--border-color);

  &__description {
    article {
      margin-top: 0;
    }
  }

  &__button-wrap {
    display: flex;
    flex-direction: column;
  }

  &__button {
    width: max-content;
    display: flex;
    align-items: center;
  }

  &__bar-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__heading {
    overflow: hidden;
  }

  &__achievement-number,
  &__achievement-name {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.55em;
    color: var(--text-color-dark);
  }

  &__container {
    display: flex;
  }
}

@include respond-up('large') {
  .about-main {
    &__button {
      padding: 22px 39px 23px;
      margin-top: 35px;
    }

    &__bar-title {
      font-size: 22px;
      line-height: 155%;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__heading {
      &_mobile {
        display: none;
      }
    }

    &__bar {
      flex: 0 0 calc(1 / 3 * (100% + 3px + (var(--spacer-large) * 2)) - var(--spacer-large));
      padding-right: 80/1675*100vw;
      border-right: 1px solid var(--border-color);
    }

    &__main {
      padding: 100px 80/1675*100vw 173px;
    }

    &__heading {
      margin-bottom: 28px;
    }

    &__description {
      margin-bottom: 70px;
    }

    &__short-description {
      margin-bottom: 120px;

      &_mobile {
        display: none;
      }
    }

    &__achievements {
      max-width: var(--text-content-width);
    }

    &__achievement-text {
      margin-top: 15px;
    }

    &__link {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .about-main {
    &__achievements {
      display: flex;
      justify-content: space-between;
    }

    &__achievement {
      flex: 0 0 190px;
    }

    &__achievement-number {
      margin-right: 6px;
      font-size: 30px;
    }

    &__achievement-name {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .about-main {
    &__button-wrap {
      flex-direction: row;
      justify-content: space-between;
    }

    &__button {
      white-space: nowrap;
    }

    &__bar-title {
      font-size: 18px;
      line-height: 175%;
      margin-right: 22px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__container {
      padding-bottom: 70px;
    }

    &__main {
      padding: 30px 0 45px;
    }

    &__description {
      margin-bottom: 37px;
    }

    &__short-description {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    &__achievement-text {
      margin-top: 9px;
    }
  }
}

@include respond-down('medium') {
  .about-main {
    background-color: var(--background-color);

    &__link,
    &__heading,
    &__short-description {
      &_desktop {
        display: none;
      }
    }

    &__container {
      flex-direction: column;
    }

    &__main {
      order: 1;
    }

    &__bar {
      order: 2;
    }
  }
}

@include respond-down('small') {
  .about-main {
    &__bar-title {
      font-size: 16px;
      line-height: 155%;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__container {
      padding-bottom: 40px;
    }

    &__main {
      padding: 40px 0 25px;
    }

    &__description {
      margin-bottom: 25px;

      article {
        ul {
          li {
            flex-wrap: wrap;
          }
        }
      }
    }

    &__short-description {
      margin-top: 18px;
      margin-bottom: 25px;
    }

    &__achievement {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &__achievement-number {
      margin-right: 5px;
      font-size: 25px;
    }

    &__achievement-name {
      font-size: 12px;
    }

    &__achievement-text {
      margin-top: 6px;
    }

    &__button {
      margin-top: 20px;
    }
  }
}