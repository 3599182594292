.scrollable-menu {
  color: var(--text-color-dark);
  overflow-x: scroll;
  overflow-y: hidden;

  &_small {
    color: var(--text-color);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    flex-wrap: nowrap;

    &::before,
    &::after {
      content: '';
      height: 10px;
    }

    li {
      a {
        display: block;

        span {
          position: relative;
          display: block;
          padding-bottom: 10px;
          font-weight: 400;
          white-space: nowrap;
          transition: color var(--default-transition);

          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: var(--text-color-dark);
            transform: scaleX(0);
            transform-origin: right;
            transition: transform .3s ease-out;
          }
        }
      }

      &._active {
        a span {
          color: var(--text-color-dark);

          &::before {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .scrollable-menu {
    margin: 58px calc(0px - var(--spacer-large)) 0;
    padding-bottom: 44px;
    border-bottom: 1px solid var(--border-color);

    ul {
      &::before,
      &::after {
        flex: 0 0 calc(var(--spacer-large) - 30px);
      }

      li a {
        padding: 0 30px;

        &:hover {
          span {
            color: var(--text-color-dark);

            &::before {
              left: 0;
              transform-origin: left;
              transform: scaleX(1);
              transition: transform .3s var(--default-bezier);
            }
          }
        }

        span {
          font-size: 18px;
          line-height: 30/18*1em;
        }
      }
    }
  }
}

@include respond('medium') {
  .scrollable-menu {
    margin: 0 calc(0px - var(--spacer-medium));
    padding: 30px 0 50px 0;

    &_small {
      font-size: 15px;

      ul li a {
        padding: 0 10px;

        span {
          padding-bottom: 7px;
        }
      }
    }

    ul {
      &::before,
      &::after {
        flex: 0 0 calc(var(--spacer-medium) - 15px);
      }

      li a {
        padding: 0 15px;

        span {
          font-size: 16px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .scrollable-menu {
    border-bottom: 1px solid var(--border-color);
    ul li a span {
      line-height: 1.55
    }
  }
}

@include respond-down('small') {
  .scrollable-menu {
    padding: 20px 0 29px;
    margin: 0 calc(0px - var(--spacer-small));

    &_small {
      ul li a {
        padding: 0 7.5px;

        span {
          font-size: 14px;
          padding-bottom: 4px;
        }
      }
    }

    ul {
      &::before, &::after {
        flex: 0 0 calc(var(--spacer-small) - 10px);
      }

      li a {
        padding: 0 10px;

        span {
          font-size: 15px;
        }
      }
    }
  }
}

@include respond-down('x-small') {
  .scrollable-menu {
    margin: 0 calc(0px - var(--spacer-smaller));

    ul {
      &::before, ::after {
        flex: 0 0 calc(var(--spacer-smaller) - 10px);
      }
    }
  }
}