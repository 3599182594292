.production {

  &__link {
    width: 98px;
  }

  &__wrap {
    display: flex;
  }
}

@include respond-up('large') {
  .production {

    &__title {
      margin-bottom: 27px;
    }

    &__description {
      margin-bottom: 40px;
    }

    &__video-wrap {
      padding: 100px 0 125px 80/1675*100vw;
    }

    &__text-wrap{
      flex: 0 0 calc(1/3*(100% + (var(--spacer-large) * 2)) - var(--spacer-large));
      padding-right: 70px;
      border-right: 1px solid var(--border-color);
    }

    &__text {
      padding-top: 100px;
    }

    &__video {
      width: 972px;
    }
  }
}

@include respond('medium') {
  .production {

    &__wrap {
      margin-top: 50px;
      flex-direction: column;
    }

    &__title {
      margin-bottom: 22px;
    }

    &__description {
      margin-bottom: 30px;
    }

    &__video-wrap {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    &__video {
      width: 710px;
    }
  }
}

@include respond-down('small') {
  .production {

    &__wrap {
      margin-top: 30px;
      flex-direction: column;
    }

    &__title {
      margin-bottom: 22px;
    }

    &__description {
      margin-bottom: 25px;
    }

    &__video-wrap {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__video {
      width: 320px;
    }
  }
}