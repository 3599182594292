.document-item {
  &__link {
    display: flex;
    align-items: center;
  }

  &__text-wrap {
    display: flex;
    flex: auto;
  }

  &__name,
  &__size {
    font-family: var(--font);
    font-weight: 400;
  }

  &__name {
    line-height: 145%;
  }

  &__size {
    line-height: 125%;
    text-transform: uppercase;
    color: var(--text-color);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .document-item {
    &__link {
      padding: 25px 0;
      column-gap: 81px;
    }

    &__name {
      font-size: 18px;
    }
  }
}

@include respond-up('medium') {
  .document-item {
    &__text-wrap {
      align-items: center;
      justify-content: space-between;
    }

    &__size {
      font-size: 15px;
    }
  }
}

@include respond('medium') {
  .document-item {
    &__link {
      padding: 22px 0;
      column-gap: 37px;
    }

    &__name {
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .document-item {
    &__link {
      padding: 16px 0;
      column-gap: 22px;
    }

    &__text-wrap {
      flex-direction: column;
      row-gap: 5px;
    }

    &__name {
      font-size: 15px;
    }

    &__size {
      font-size: 14px;
    }
  }
}