@include respond-up('large') {
  .product-details {
    padding: 0 0 125px 80/1675*100vw;
  }
}

@include respond('medium') {
  .product-details {
    margin-right: calc(0px - var(--spacer-medium));
    padding-bottom: 70px;
  }
}

@include respond-down('small') {
  .product-details {
    padding: 0 0 50px var(--spacer-small);
  }
}