.badge {
  font-family: var(--h-font);
  font-weight: 500;
  line-height: 1.33;

  background-color: #fff;
  text-transform: uppercase;

  &_hot {
    color: var(--hot-color);
  }

  &_cold {
    color: var(--cold-color);
  }
}

@include respond-up('large') {
  .badge {
    padding: 10px 15px;
    font-size: 10px;
  }
}

@include respond-down('medium') {
  .badge {
    padding: 7px 10px;
    font-size: 8px;
  }
}

@include respond-down('small') {
  .badge {
    font-size: 7px;
  }
}