.developers-top {

  &__description-inner {
    margin-top: 0;
  }
}

@include respond-up('large') {
  .developers-top {
    &__container {
      display: flex;
    }

    &__bar {
      flex: 0 0 calc(1/3*(100% + (var(--spacer-large) * 2)) - var(--spacer-large));
      padding-right: 80/1675*100vw;
      border-right: 1px solid var(--border-color);
    }

    &__main {
      padding: 152px 80/1675*100vw 80px;
    }

    &__description {
      margin-bottom: 40px;
    }

    &__picture {
      margin-bottom: 90px;
    }
  }
}

@include respond-up('medium') {
  .developers-top {
  }
}

@include respond('medium') {
  .developers-top {
    &__main {
      padding: 30px 0 50px;
      background: var(--background-color);
    }

    &__picture {
      margin-bottom: 70px;
    }
  }
}

@include respond-down('medium') {
  .developers-top {

  }
}

@include respond-down('small') {
  .developers-top {
    &__main {
      margin: 20px 0 30px;
    }


    &__picture {
      margin-bottom: 40px;
    }
  }
}