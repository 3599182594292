.our-values-slider {
  background: var(--background-color);

  .tns-inner {
    display: flex;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__block {
    height: 0;
    transition: height var(--default-transition);
    overflow: hidden;
  }

  &__link {
    display: flex;
    justify-content: space-between;

    &._active {
      .our-values-slider {
        &__plus-wrap {
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }

  &__plus-wrap {
    display: flex;
    border: 1px solid var(--slider-line-color);
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      border-color: var(--slider-line-color);
      transform: translate3d(-50%, -50%, 0);
      transition: opacity var(--default-transition);
    }

    &::before {
      border-bottom: 2px solid;
    }

    &::after {
      border-left: 2px solid;
    }
  }

  &__nav {
    margin: 0;
  }

  &__top-wrap {
    display: flex;
  }
}

@include respond-up('large') {
  .our-values-slider {
    padding: 100px 0 145px;

    .tns-inner {
      margin-right: var(--spacer-large) !important;
      margin-left: var(--spacer-large) !important;
    }

    &__slide {
      width: calc(475 / 1675 * 100vw + 60px);

      &:last-child {
        width: calc(475 / 1675 * 100vw + 120px + var(--spacer-large));

        .our-values-slider {
          &__picture,
          &__image,
          &__text-wrap {
            width: calc(475 / 1675 * 100vw);
          }
        }
      }

      &:nth-child(3n-2) {
        .our-values-slider {
          &__picture {
            height: calc(380 / 1675 * 100vw);
          }
        }
      }

      &:nth-child(3n-1) {
        .our-values-slider {
          &__picture {
            height: calc(480 / 1675 * 100vw);
          }
        }
      }

      &:nth-child(3n) {
        .our-values-slider {
          &__picture {
            height: calc(427 / 1675 * 100vw);
          }
        }
      }
    }

    &__link {
      column-gap: 28px;
    }

    &__text-wrap {
      margin-top: 28px;
    }

    &__description {
      padding-top: 20px;
    }

    &__top-wrap {
      align-items: center;
      justify-content: space-between;
      padding: 0 var(--spacer-large);
    }

    &__nav-wrap {
      flex: 1 1 auto;
    }

    &__slider-wrap {
      margin-top: 65px;
    }
  }
}

@include respond-up('medium') {
  .our-values-slider {
    &__plus-wrap {
      flex: 0 0 40px;
      height: 40px;

      &::before {
        width: 18px;
      }

      &::after {
        height: 18px;
      }
    }
  }
}

@include respond('medium') {
  .our-values-slider {
    padding: 50px 0 110px;

    .tns-inner {
      margin-right: var(--spacer-medium) !important;
      margin-left: var(--spacer-medium) !important;
      margin-top: 30px !important;
    }

    &__slide {
      width: calc(343 / 768 * 100vw + 22px);

      &:last-child {
        width: calc(343 / 768 * 100vw + 44px + var(--spacer-medium));

        .our-values-slider {
          &__picture,
          &__image,
          &__text-wrap {
            width: calc(343 / 768 * 100vw);
          }
        }
      }

      &:nth-child(3n-2) {
        .our-values-slider {
          &__picture {
            height: calc(274 / 768 * 100vw);
          }
        }
      }

      &:nth-child(3n-1) {
        .our-values-slider {
          &__picture {
            height: calc(348 / 768 * 100vw);
          }
        }
      }

      &:nth-child(3n) {
        .our-values-slider {
          &__picture {
            height: calc(309 / 768 * 100vw);
          }
        }
      }
    }

    &__link {
      column-gap: 21px;
    }

    &__text-wrap {
      margin-top: 22px;
    }

    &__description {
      padding-top: 18px;
    }

    &__top-wrap {
      padding: 0 var(--spacer-medium);
      flex-direction: column;
      row-gap: 30px;
    }
  }
}

@include respond-down('medium') {
  .our-values-slider {

  }
}

@include respond-down('small') {
  .our-values-slider {
    padding: 30px 0 102px;
    overflow: hidden;

    &__slider-wrap {
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__slider {
      display: flex;
      margin-right: var(--spacer-small) !important;
      margin-left: var(--spacer-small) !important;
      margin-top: 30px !important;
    }

    .tns-inner {
      margin-right: var(--spacer-small) !important;
      margin-left: var(--spacer-small) !important;
      margin-top: 30px !important;
    }

    &__slide {
      width: calc(320 / 375 * 100vw + 18px);
      flex: 0 0 calc(320 / 375 * 100vw + 18px);
      padding-right: 18px;

      &:last-child {
        width: calc(320 / 375 * 100vw + 36px + var(--spacer-small));
        flex: 0 0 calc(320 / 375 * 100vw + 36px + var(--spacer-small));

        .our-values-slider {
          &__picture,
          &__image,
          &__text-wrap {
            width: calc(320 / 375 * 100vw);
          }
        }
      }

      &:nth-child(2n-1) {
        .our-values-slider {
          &__picture {
            height: calc(256 / 375 * 100vw);
          }
        }
      }

      &:nth-child(2n) {
        .our-values-slider {
          &__picture {
            height: calc(324 / 375 * 100vw);
          }
        }
      }
    }

    &__plus-wrap {
      flex: 0 0 32px;
      height: 32px;

      &::before {
        width: 14px;
      }

      &::after {
        height: 14px;
      }
    }

    &__link {
      column-gap: 15px;
    }

    &__text-wrap {
      margin-top: 18px;
    }

    &__description {
      padding-top: 16px;
    }

    &__top-wrap {
      padding: 0 var(--spacer-small);
    }

    &__nav-wrap {
      display: none;
    }
  }
}