.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  z-index: 100;
  position: relative;
}

@media print {
  .layout {
    justify-content: flex-start;
  }
}