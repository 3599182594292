.details-slider {
  position: relative;
  scroll-behavior: revert;


  &__title {
    position: absolute;
    display: inline-block;
    z-index: 3;
    border-bottom: 1px solid var(--border-color);
  }

  &__label {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
  }

  &__inner {
    position: relative;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
    pointer-events: none;
  }

  &__slide {
    position: relative;
    display: grid !important;
    transition-duration: 0s;
    animation-duration: 0s;
    top: 0;
    left: 0 !important;

    &.normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .details-slider__info-block {
        background-color: #fff;
      }

      .details-slider__name, .details-slider__description {
        transform: translateY(20px);
        opacity: 0;
      }

      .details-slider__picture {
        transform: translateX(100%);
      }

      .details-slider__image {
        transform: translateX(-100%);
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 2;

      .details-slider__name, .details-slider__description {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .3s;
      }

      .details-slider__picture {
        transform: translateX(0);
        transition: transform .7s;
      }

      .details-slider__image {
        animation: animate-image .7s;
      }
    }

    &.animate_out {
      transform: translateX(0);
      z-index: 1;

      .details-slider__name, .details-slider__description {
        transform: translateY(20px);
        opacity: 0;
      }

      .details-slider__picture {
        transform: translateX(0);
        transition: transform .7s;
      }

      .details-slider__image {
        transform: translateX(0);
        transition: transform .7s;
      }
    }

    &::before {
      content: '';
      background-color: #fff;
    }
  }

  &__name {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.85;
    text-transform: uppercase;
  }

  &__name, &__description {
    transition: transform .5s, opacity .5s;
  }

  &__picture {
    display: block;
    font-size: 0;
  }

  &__image {
    max-width: none;
    transition: transform 0s;
  }
}

@include respond-up('large') {
  .details-slider {
    &__title {
      top: 64px;
      left: 60/1675*100vw;
      width: 376/1675*100vw;
      padding-bottom: 39px;
    }

    &__label {
      top: 32px;
      right: 69/1675*100vw;
    }

    &__slide {
      grid-template-columns: 522/1675*100vw 166/1675*100vw 1fr;
      grid-template-rows: 83px 1fr 72px;
      height: 650px;
    }

    &__info-block {
      padding: 201px 74/1675*100vw 78px 60/1675*100vw;
      min-height: 578px;
      grid-area: 1 / 1 / 3 / 2;
    }

    &__name {
      font-size: 15px;
      padding-bottom: 16px;
    }

    &__picture {
      grid-area: 2 / 2 / -1 / -1;
      height: 100%;
    }
  }
}


@include respond-up('medium') {
  .details-slider {
    &__picture {
      overflow: hidden;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


@include respond('medium') {
  .details-slider {
    &__title {
      top: 40px;
      left: 28/768*100vw;
      width: 315/768*100vw;
      padding-bottom: 40px;
    }

    &__label {
      top: 7px;
      right: 35/768*100vw;
    }

    &__slide {
      grid-template-columns: 376/768*100vw 87/768*100vw 1fr;
      grid-template-rows: 56px calc((100vw - 768px)/(1199 - 768) * (338 - 400) + 400px) 37px;

      &::after {
        content: '';
        background-color: #fff;
        grid-area: -1 / 2 / 3 / 3;
      }
    }

    &__info-block {
      padding: 151px 33/768*100vw 89px 28/768*100vw;
      min-height: 440px;
      grid-area: 1 / 1 / -1 / 2;
    }

    &__name {
      font-size: 15px;
      padding-bottom: 16px;
    }

    &__picture {
      grid-area: 2 / 2 / 3 / -1;
    }
  }
}


@include respond-down('medium') {
  .details-slider {
    &__name {
      font-size: 14px;
      padding-bottom: 14px;
    }
  }
}


@include respond-down('small') {
  .details-slider {

    &__title {
      top: 30px;
      left: 25/375*100vw;
      padding-left: 5px;
      padding-bottom: 20px;
      width: 270/375*100vw;
    }

    &__label {
      bottom: calc(237/375*100vw + 85px);
      left: 17/375*100vw;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__slide {
      position: relative;
      grid-template-columns: var(--spacer-small) 1fr var(--spacer-small);
      grid-template-rows: calc((100vw - 375px)/(767 - 375) * (350 - 488) + 488px) 60px 237/375*100vw;

      &::before {
        grid-area: 2 / 1 / 3 / 2;
      }
    }

    &__info-block {
      grid-area: 1 / 1 / 2 / 3;
      padding: calc((100vw - 375px)/(767 - 375) * (115 - 101) + 101px) 25/375*100vw 89px;
    }

    &__picture {
      grid-area: 2 / 2 / -1 / -1;
    }
  }
}


@include respond-down('x-small') {
  .details-slider {
    &__slide {
      grid-template-rows: calc((100vw - 320px) / (374 - 320) * (463 - 600) + 600px) 60px 237/375*100vw;
    }
  }
}

@keyframes animate-image {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}