.watch-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid var(--border-color);
  position: relative;

  &__link {
    display: block;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #000;
    text-align: center;
  }
}

@include respond-up('large') {
  .watch-all {
    flex: 0 0 calc(100% / 3);
    overflow: hidden;

    &__link {
      padding: 30px;
      position: relative;
      z-index: 2;
      transition: color 0.2s ease-in-out;
    }

    &__filter {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      height: 100%;
      width: 100%;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform 0.3s ease-in-out;
      z-index: 1;
    }

    &:hover {
      .watch-all {
        &__link {
          color: #fff;
        }

        &__filter {
          transform: scaleY(1);
        }
      }
    }
  }
}

@include respond('medium') {
  .watch-all {
    &__link {
      padding: 25px;
    }
  }
}

@include respond-down('medium') {
  .watch-all {
    &__link {
      background-color: var(--background-color);
    }
  }
}

@include respond-down('small') {
  .watch-all {
    &__link {
      padding: 20px;
    }
  }
}