.producer-categories {
  display: flex;
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__link-text {
    font-weight: normal;
    font-family: var(--h-font);
    color: var(--text-color-dark);
  }
}

@include respond-up('large') {
  .producer-categories {
    padding: 100px 0 125px var(--spacer-large);
    &__items {
      grid-gap: 142px calc(var(--spacer-large) * 2);
      padding-top: 65px;
    }

    &__item {
      align-self: end;
      &:nth-child(10n+5) {
        grid-column-start: 2;
        grid-column-end: 4;
      }

      &:nth-child(10n+9) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    &__picture {
      margin-bottom: 30px;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180.8deg, rgba(7, 20, 51, 0.73) 1.79%, rgba(7, 20, 51, 0) 99%);
        transform: matrix(1, 0, 0, -1, 0, 0);
        opacity: 0;
        transition: all var(--default-transition);
      }
    }

    &__link {
      &:hover {
        .producer-categories {
          &__picture {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &__link-text {
      font-size: 22px;
      line-height: 1.54em;
      min-height: 66px;
    }
  }
}

@include respond-up('medium') {
  .producer-categories {

  }
}

@include respond('medium') {
  .producer-categories {
    padding: 50px 0;

    &__picture {
      margin-bottom: 15px;
    }

    &__link-text {
      font-size: 15px;
      min-height: 46px;
    }

    &__items {
      grid-gap: 70px calc(var(--spacer-medium) * 2);
      padding-top: 40px;
    }

    &__item {
      align-self: end;

      &:nth-child(10n+5) {
        grid-column-start: 2;
        grid-column-end: 4;

        .product-card__description {
          max-width: calc((350/768) * 100vw);
        }
        .product-card__border {
          display: block;
          bottom: 0;
          border-top: 1px solid var(--border-color);
          left: calc(var(--spacer-medium) * -1);
          width: calc(100% + var(--spacer-medium) * 2);
        }
      }

      &:nth-child(7n+9) {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: var(--background-color);

        .product-card__description {
          max-width: calc((350/768) * 100vw);
        }

        .product-card__border {
          display: block;
          bottom: 0;
          border-top: 1px solid var(--border-color);
          right: calc(var(--spacer-medium) * -1);
          width: calc(100% + var(--spacer-medium) * 2);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .producer-categories {
    &__link-text {
      line-height: 1.55em;
    }
  }
}

@include respond-down('small') {
  .producer-categories {
    padding: 40px 0;

    &__items {
      padding-top: 30px;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px var(--spacer-small);
    }

    &__item {
      align-items: end;

      &:nth-child(10n+5) {
        grid-column-start: 1;
        grid-column-end: 3;

        .producer-categories {
          &__link-text {
            min-height: unset;
          }
        }
      }
    }

    &__link {
      height: 100%;
      justify-content: flex-end;
    }

    &__picture {
      margin-bottom: 10px;
    }

    &__link-text {
      font-size: 14px;
      min-height: 66px;
    }
  }
}