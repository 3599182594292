.profile-info {
  &__description {

  }

  &__content {
    p {
      color:  var(--text-color-dark);
    }
  }
}

@include respond-up('large') {
  .profile-info {
    margin: 60px 80/1675*100vw 125px;

    &__description {
      width: var(--text-content-width);
    }

    &__content {
      margin-top: 70px;

      ul {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 25px;
      }
    }
  }
}

@include respond('medium') {
  .profile-info {
    margin: 50px var(--spacer-medium) 70px;

    &__content {
      margin-top: 50px;

      ul {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 22px;
      }

      h3 {
        margin-top: 50px;
      }
    }
  }
}

@include respond-down('small') {
  .profile-info {
    margin: 30px var(--spacer-small) 50px;

    &__content {
      margin-top: 40px;

      ul {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 20px;
      }

      h3 {
        margin-top: 40px;
      }
    }
  }
}