$gap-large: 20px;
$gap-small: 15px;

.certificates {
  overflow: hidden;

  &__main {
    overflow: hidden;
  }

  &__items {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    a, img, picture {
      user-select: none;
      -webkit-user-drag: none
    }
  }

  &__docs-link {
    position: relative;
    display: inline-block;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33;
    letter-spacing: 0.08em;
    color: var(--text-color-dark);
    text-transform: uppercase;
    padding-bottom: 6px;

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform-origin: right;
      transition: transform .2s ease-out;
    }

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .2s ease-out;
    }
  }
}

@include respond-up('large') {
  .certificates {
    &__description {
      margin-top: 27px;
    }

    &__main {
      padding: 100px 0 125px 80/1675*100vw;
    }

    &__items {
      margin-top: 80px;
      grid-gap: $gap-large;
      grid-template-columns: repeat(auto-fill, 208px);

      &::before {
        display: none;
      }

      &::after {
        width: calc(var(--spacer-large) - #{$gap-large});
      }
    }

    &__docs-link {
      margin-top: 25px;

      &:hover {
        &::after {
          transform: scaleX(1);
          transition: transform .3s var(--default-bezier) .33s;
        }

        &::before {
          transform: scaleX(0);
          transition: transform .3s var(--default-bezier);
        }
      }
    }
  }
}

@include respond('medium') {
  .certificates {
    &__bar {
      margin: 50px var(--spacer-medium) 20px;
    }

    &__main {
      margin-bottom: 50px;
    }

    &__description-short {
      margin: 0 var(--spacer-medium) 58px;
    }

    &__items {
      &::after,
      &::before {
        width: calc(var(--spacer-medium) - #{$gap-small});
      }
    }
  }
}

@include respond-down('medium') {
  .certificates {
    &__description {
      margin-top: 22px;
    }

    &__items {
      grid-gap: $gap-small;
      grid-template-columns: min-content repeat(auto-fill, 176px) min-content;
    }

    &__docs-link {
      margin-top: 10px;
    }
  }
}

@include respond('small') {
  .certificates {
    &__bar {
      margin: 40px var(--spacer-small) 20px;
    }

    &__description-short {
      margin: 0 var(--spacer-small) 35px;
    }

    &__items {
      &::after,
      &::before {
        width: calc(var(--spacer-small) - #{$gap-small});
      }
    }
  }
}

@include respond-down('small') {
  .certificates {
    &__main {
      margin-bottom: 30px;
    }
  }
}

@include respond-down('x-small') {
  .certificates {
    &__bar {
      margin: 40px var(--spacer-smaller) 20px;
    }

    &__description-short {
      margin: 0 var(--spacer-smaller) 35px;
    }

    &__items {
      &::after,
      &::before {
        width: calc(var(--spacer-smaller) - #{$gap-small});
      }
    }
  }
}
