.contacts-info {
  &__item{
    &.developers__info{
      display: none;
    }
  }
}

@include respond-up('large') {
  .contacts-info {
    &__list {
      grid-column-gap: 28px;
      grid-row-gap: 60px;
    }

    &__socials,
    &__requisites {
      margin-top: 20px;
      &.developers-info-social{
        margin-top: 16px;
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-info {
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
              'phone email'
              'socials requisites'
              '. docs';
      &.developers-info-social{
        display: block;
      }
    }

    &__item {
      &_phone {
        grid-area: phone;
      }

      &_email {
        grid-area: email;
      }

      &_socials {
        grid-area: socials;
      }

      &_requisites {
        grid-area: requisites;
      }

      &_docs {
        grid-area: docs;
      }
    }
  }
}

@include respond('medium') {
  .contacts-info {
    &__list {
      grid-column-gap: 22px;
      grid-row-gap: 40px;
    }

    &__item {
      &_socials,
      &_requisites {
        padding-top: 10px;
      }
    }
  }
}

@include respond-down('medium') {
  .contacts-info {
    &__socials,
    &__requisites {
      margin-top: 16px;
    }
  }
}

@include respond-down('small') {
  .contacts-info {
    &__item {
      &_email {
        margin-top: 15px;
      }

      &_socials,
      &_requisites,
      &_docs {
        margin-top: 30px;
      }
    }
  }
}