.quote-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  overflow: hidden;

  &__icon{
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .quote-block {
    padding: 75px 0;

    &__title {
      width: calc(100vw - var(--spacer-large) * 2);
    }

    &__icon{
      margin-top: 50px;
    }
  }
}

@include respond('medium') {
  .quote-block {
    padding: 45px 0;

    &__title {
      width: calc(100vw - var(--spacer-medium) * 2);
    }

    &__icon{
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .quote-block {
    padding: 25px 0;

    &__title {
      width: calc(100vw - var(--spacer-small) * 2);
    }

    &__icon{
      margin-top: 30px;
    }
  }
}