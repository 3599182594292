.project-form {
  &__main {

  }

  &__block {
    &:not(:first-child) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__delete {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 -10px;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--text-color-dark);

    svg {
      margin-right: 8px;
    }
  }
}

@include respond-up('large') {
  .project-form {
    &__back-link {
      display: flex;
      align-items: center;
      padding: 20px var(--spacer-large-profile);
      font-family: var(--h-font);
      font-weight: 500;
      font-size: 11px;
      line-height: 1.33;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-color-dark);

      svg {
        display: flex;
        margin-right: 10px;
      }
    }
  }
}

@include respond-up('medium') {
  .project-form {
    &__button {
      &:not(:last-child) {
        margin-right: 30px;
      }

      &:last-child {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@include respond-down('medium') {
  .project-form {
    &__back-link {
      display: none;
    }
  }
}

@include respond-down('small') {
  .project-form {
    &__buttons {
      flex-wrap: wrap;
      max-width: 400px;
    }

    &__button {
      &:first-child {
        flex: 1 1 100%;
        max-width: 290px;
        margin-bottom: 15px;
      }

      &:nth-child(2) {
        margin-right: 40px;
      }
    }
  }
}