.vacancies-page{
    &__list{
        display: grid;
        box-shadow: inset 0 -1px 0 0 var(--border-color);
        border-top: 1px solid var(--border-color);
    }

    &__item{
        box-shadow: inset 0 -1px 0 0 var(--border-color);
    }

    &__title{
        display: block;
    }

    &__empty{
        padding-bottom: 50px;
    }
}

@include respond-up('large'){
    .vacancies-page{
        &__title{
            padding-bottom: 75px;
        }

        &__list{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@include respond('medium'){
    .vacancies-page{
        &__list{
            grid-template-columns: 1fr 1fr;
        }

        &__title{
            padding-bottom: 30px;
        }
    }
}

@include respond-down('small'){
    .vacancies-page{
        &__list{
            grid-template-columns: 1fr;
        }

        &__title{
            padding-bottom: 20px;
        }
    }
}