@include respond-up('large') {
  .search-name-link {
    display: inline-block;
    position: relative;
    color: #000;
    padding-bottom: 2px;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.2em;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform-origin: right;
      transition: transform .2s ease-out;
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .2s ease-out;
    }

    &_white {
      color: #fff;

      &::before {
        background-color: #fff;
      }

      &::after {
        background-color: #fff;
      }
    }
  }
}