.quiz-final {
  border-top: 1px solid var(--button-color);
  &__content {
    background: #fff;

    &_logo {
      .quiz-final {
        &__title,
        &__description {
          text-align: center;
        }
      }
    }
  }

  &__success-icon,
  &__link-icon,
  &__logo-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__success-icon {
    flex: 0 0 56px;
    height: 56px;
  }

  &__text-wrap {
    display: flex;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: var(--button-color);
  }

  &__link {
    display: flex;
    width: fit-content;
    align-items: center;
    column-gap: 16px;
  }

  &__link-icon {
    width: 20px;
    height: 20px;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bottom-wrap {
    justify-content: flex-end;
  }
}

@include respond-up('large') {
  .quiz-final {
    padding-top: calc(60/945 * 100vh);
    &__content {
      display: grid;
      grid-template-columns: repeat(16, 1fr);
      gap: 20px;

      &_image {
        .quiz-final {
          &__text-wrap {
            padding: 50px;
            grid-column: 1/7;
          }

          &__text {
            margin-top: calc(35/945 * 100vh);
          }

          &__description {
            margin-top: calc(60/945 * 100vh);
          }

          &__link-wrap {
            margin-top: calc(45/945 * 100vh);
          }

          &__image-wrap {
            grid-column: 7/17;
          }
        }
      }

      &_logo {
        grid-row-gap: 0;

        .quiz-final {
          &__text-wrap {
            grid-column: 5/13;
            padding: calc(50/945 * 100vh) 0 calc(128/945 * 100vh);
          }

          &__success-icon {
            width: 92px;
            height: 92px;
          }

          &__logo-icon {
            grid-column: 1/17;
            height: auto;
          }

          &__title {
            margin-top: 50px;
          }

          &__description {
            margin-top: 35px;
          }
        }
      }
    }

    &__text-wrap {
      flex-direction: column;
    }

    &__success-icon {
      align-self: center;
    }

    &__link {
      &:hover {
        .quiz-final__link-title {
          color: var(--text-color-dark);
          background: unset;
          &::before {
            transition: transform .3s var(--default-bezier);
            transform: scaleX(0);
          }

          &::after {
            transition: transform .3s var(--default-bezier) .33s;
            transform: scaleX(1);
          }
        }
      }
    }

    &__picture {
      height: calc(541/945 * 100vh);
    }
  }
}

@include respond-up('medium') {
  .quiz-final {
    &__button {
      &.button {
        padding: 20px 30px;
      }
    }
  }
}

@include respond('medium') {
  .quiz-final {
    padding-top: 40px;

    &__content {
      &_image {
        .quiz-final {
          &__text-wrap {
            padding: 30px calc(100/768 * 100vw) 45px 35px;
            column-gap: 30px;
          }

          &__text {
            padding-top: 16px;
          }

          &__description {
            margin-top: 30px;
          }

          &__link-wrap {
            margin-top: 35px;
          }
        }
      }

      &_logo {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 22px;
        grid-row-gap: 0;

        .quiz-final {
          &__text-wrap {
            padding: 45px 0 90px;
            grid-column: 3/11;
            flex-direction: column;
          }

          &__logo-icon {
            grid-column: 1/13;
            height: auto;
          }

          &__title {
            margin-top: 45px;
          }

          &__description {
            margin-top: 25px;
          }
        }
      }
    }

    &__picture {
      height: calc((710/768 * 100vw) * 400/710);
    }

    &__bottom-wrap {
      border-top: unset;
    }
  }
}

@include respond-down('small') {
  .quiz-final {
    padding-top: 32px;

    &__content {
      &_image {
        .quiz-final {
          &__text-wrap {
            flex-direction: column;
            padding: 15px 20px 35px;
          }

          &__text {
            margin-top: 25px;
          }

          &__description {
            margin-top: 18px;
          }

          &__link-wrap {
            margin-top: 30px;
          }
        }
      }

      &_logo {
        .quiz-final {
          &__text-wrap {
            width: 100%;
            flex-direction: column;
            padding: 20px 20px 50px;
          }

          &__logo-icon {
            width: 100%;
            height: auto;
          }

          &__title {
            margin-top: 30px;
          }

          &__description {
            margin-top: 18px;
          }
        }
      }
    }

    &__picture {
      height: calc((320/375 * 100vw) * 180/320);
    }

    &__button {
      &.button {
        padding: 15px 20px;
      }
    }
  }
}