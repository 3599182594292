/**

Задаёт фиксированные отступы по краям контейнера

Example:

$grid: (
  small: (
    from: 0,
    to: 977,
    spacer: 40
  ),
  large: (
    from: 978,
    spacer: 20
  )
);

*/

$grid: () !default;

/**
Этот параметр изменяемый, например на 'vw', если сайт тянется
По умолчанию - 'px'
 */
$spacer-unit: 'vw';

.container {
  //max-width: calc(var(--max-row-width) * 1px);
  margin: 0 auto;
}

@each $name, $data in $grid {
  @if map-has_key($data, spacer) {
    @include respond($name) {
      .container {
        &_spacer {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-up($name) {
      .container {
        &_spacer-#{$name}-up {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-down($name) {
      .container {
        &_spacer-#{$name}-down {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }
  }
}