$gap-large: 25px;
$gap-medium: 15px;
$gap-small: 13px;

.production-items {
  &::-webkit-scrollbar {
    display: none;
  }

  &__items {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    cursor: pointer;
    user-select: none;

    &::after {
      content: '';
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    position: relative;
    display: flex;
    overflow: hidden;
    background-color: #fff;
  }

  &__picture {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__number {
    position: absolute;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--opacity-numbers-color);
    font-family: var(--h-font);
  }

  &__name {
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text-color-dark);
    font-family: var(--h-font);
  }

  &__description {
    font-weight: normal;
    line-height: 1.75em;
    color: var(--text-color);
  }

  &__content {
    display: block;
  }
}

@include respond-up('large') {
  .production-items {
    &__main {
      padding: 100px 0 123px;
    }

    &__items {
      grid-gap: $gap-large;
      padding: 0 var(--spacer-large);
      &::after {
        width: calc(var(--spacer-large) - #{$gap-large});
      }
    }

    &__item {
      width: 45vw;
      padding: 57px 75px 157px 117px;
    }

    &__number {
      top: 49px;
      left: -24px;
    }

    &__name {
      font-size: 15px;
      line-height: 1.85em;
    }

    &__description {
      font-size: 16px;
    }

    &__name {
      margin-bottom: 16px;
    }

    &__title {
      margin-bottom: 57px;
    }
  }
}

@include respond-up('medium') {
  .production-items {
    &__number {
      font-size: 75px;
      line-height: 0.78em;
    }

    &__picture {
      width: 352px;
      height: 99px;
    }
  }
}

@include respond('medium') {
  .production-items {
    &__main {
      padding: 50px 0;
    }

    &__items {
      grid-gap: $gap-medium;
      padding: 0 var(--spacer-medium);
      &::after {
        width: calc(var(--spacer-medium) - #{$gap-medium});
      }
    }

    &__item {
      width: 76vw;
      padding: 38px 30px 163px 121px;
    }

    &__number {
      top: 30px;
      left: -23px;
    }
  }
}

@include respond-down('medium') {
  .production-items {
    &__name {
      font-size: 14px;
      line-height: 1.55em;
    }

    &__name {
      margin-bottom: 12px;
    }

    &__description {
      font-size: 15px;
    }

    &__title {
      margin-bottom: 22px;
    }
  }
}

@include respond-down('small') {
  .production-items {
    &__main {
      padding: 30px 0;
    }

    &__items {
      grid-gap: $gap-small;
      padding: 0 var(--spacer-small);
      &::after {
        width: calc(var(--spacer-small) - #{$gap-small});
      }
    }

    &__item {
      width: 78vw;
      padding: 95px 20px 179px;
    }

    &__number {
      font-size: 45px;
      line-height: 1em;
      top: 25px;
      left: 15px;
    }

    &__picture {
      width: 297px;
      height: 84px;
    }
  }
}