.cooperation {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);

  &__head {
    display: flex;
    background-color: var(--background-color);
  }

  &__assortments, &__production {
    border-top: 1px solid var(--border-color);
  }

  &__production {
    .producer__production-items {
      border-bottom: none;
    }
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__quote {
    position: relative;
    font-size: 15px;
    line-height: 30px;
    font-family: var(--h-font);

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 30px;
      height: 21px;
      background: {
        image: url('../svg/quote.svg');
        size: contain;
        repeat: no-repeat;
      };
    }

    &::after {
      position: absolute;
      content: '';
      left: 60px;
      top: 0;
      width: 1px;
      background-color: var(--slider-line-bg-color);
    }
  }

  &__image {
    width: 100%;
  }

  &__description {
    color: var(--text-color);
  }

  &__list {
    display: grid;
    counter-reset: count;
  }

  &__item {
    &::before {
      display: block;
      counter-increment: count;
      content: '0' counter(count) '.';
      font-family: var(--h-font);
      font-weight: 500;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 2;
    }
  }

  &__implementation-description-title {
    line-height: 175%;
  }

  &__implementation-description {
    color: var(--text-color);
    line-height: 175%;
  }

  &__implementation {
    border-bottom: 1px solid var(--border-color);

  }

  &__item-name {
    display: block;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 15px;
    line-height: 185%;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }

  &__conditions {
    display: flex;
    border-top: 1px solid var(--border-color);
  }

  &__implementation-level {
    border-top: 1px solid var(--border-color);
  }
}

@include respond-up('large') {
  .cooperation {
    &__head {
      padding-bottom: 80px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 157px 0 0 17/1675*100vw;
      width: 674/1675*100vw;
    }

    &__implementation-title {
      margin-bottom: 48px;
    }

    &__implementation-mobile-link {
      display: none;
    }

    &__implementation-slider {
      width: calc(100vw - 7px);
      margin-bottom: 100px;
      padding-top: 90px;

      .slider-images__slide {
        max-width: 876/1675*100vw;
        padding-right: var(--spacer-large) !important;
      }

      .tns-inner {
        margin: 0 0 0 var(--spacer-large) !important;
      }

    }

    &__implementation-description {
      font-size: 16px;
      max-width: 740px;
    }

    &__implementation {
      display: flex;
      justify-content: space-between;
    }

    &__implementation-title-wrapper {
      flex: 0 0 33.333%;
      border-right: 1px solid var(--border-color);
      padding: 100px 111px var(--spacer-large);

    }

    &__implementation-description-title {
      font-size: 19px;
      margin-bottom: 25px;
    }

    &__description-container {
      padding: 100px var(--spacer-large) 111px;
      flex: 0 0 66.666%;
    }

    &__quote-wrapper {
      margin-left: 250px;
      display: flex;
      justify-content: center;
    }

    &__quote {
      max-width: 680px;
      padding-left: 100px;
      padding-bottom: 83px;

      &::after {
        height: 64px;
      }
    }


    &__subtitle {
      margin-bottom: 32px;
    }

    &__picture {
      padding-bottom: 128px;
    }

    &__advantages {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 57/1675*100vw;
    }

    &__advantage {
      padding-bottom: 145px;
    }

    &__item {
      &::before {
        padding-bottom: 30px;
      }
    }

    &__item-name {
      font-size: 15px;
      padding-bottom: 16px;
    }

    &__conditions-title {
      flex: 0 0 calc(100% / 3);
      padding: 100px var(--spacer-large) 124px;
    }

    &__conditions-list {
      grid-template-columns: repeat(2, 1fr);
      border-left: 1px solid var(--border-color);
      padding: 100px var(--spacer-large) 124px 80/1685*100vw;
      grid-gap: 70px 108/1675*100vw;
    }
  }
}

@include respond('medium') {
  .cooperation {
    &__picture {
      padding-bottom: 73px;
    }

    &__head {
      padding-bottom: 70px;
    }

    &__assortments {
      .assortment {
        &__bar {
          margin-top: 50px;
        }
      }
    }

    &__quote {
      padding: 0 0 50px 100px;

      &::after {
        left: 60px;
        height: 55px;
      }
    }

    &__implementation-link {
      display: none;
    }

    &__wrapper {
      padding: 25px var(--spacer-medium) 0;

    }

    &__implementation {
      padding: 50px var(--spacer-medium);
    }

    &__implementation-title {
      margin-bottom: 22px;

    }

    &__slider {
      padding: 30px 0 42px;

      .slider-images__slide {
        max-width: 473/768*100vw;
        padding-right: var(--spacer-medium) !important;
      }

      .tns-inner {
        margin: 0 0 0 var(--spacer-medium) !important;
      }
    }

    &__implementation-slider {

    }

    &__implementation-description-title {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__implementation-description {
      font-size: 15px;
      margin-bottom: 30px;
    }

    &__subtitle {
      margin-bottom: 20px;
    }

    &__advantages {
      padding-bottom: 50px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 60px 43/768*100vw;
    }

    &__item {
      &::before {
        padding-bottom: 20px;
      }
    }

    &__item-name {
      font-size: 14px;
    }

    &__conditions {
      padding: 50px var(--spacer-medium);
    }

    &__conditions-title {
      padding-bottom: 40px;
    }
  }
}

@include respond-down('medium') {
  .cooperation {
    &__head {
      flex-direction: column;
    }

    &__description {
      font-size: 15px;
    }

    &__item-name {
      padding-bottom: 14px;
    }

    &__conditions {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .cooperation {
    &__head {

    }

    &__wrapper {
      padding: 20px var(--spacer-small) 30px;

    }

    &__implementation {
      padding: 30px var(--spacer-small);
    }

    &__implementation-title {
      margin-bottom: 22px;
    }

    &__implementation-description-title {
      margin-bottom: 20px;
      font-size: 16px;
    }

    &__implementation-description {
      margin-bottom: 30px;
      font-size: 15px;
    }

    &__implementation-link {
      display: none;
    }

    &__assortments {
      .assortment {
        &__bar {
          margin-top: 30px;
        }
      }
    }

    &__subtitle {
      margin-bottom: 20px;
    }

    &__quote {
      line-height: 175%;
      margin-bottom: 40px;
      padding-left: 68px;

      &::before {
        width: 27px;
        height: 17px;
      }

      &::after {
        left: 47px;
        height: 100%;
      }
    }

    &__picture {
      padding-bottom: 50px;
    }

    &__advantages {
      padding-bottom: 30px;
    }

    &__list {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &__item {
      &::before {
        padding-bottom: 15px;
      }
    }

    &__item-name {
      font-size: 13px;
    }

    &__conditions {
      padding: 30px var(--spacer-small);
    }

    &__conditions-title {
      padding-bottom: 22px;
    }

    &__slider {
      padding: 30px 0;

      .slider-images__slide {
        max-width: 432/375*100vw;
        padding-right: var(--spacer-small) !important;
      }

      .tns-inner {
        margin: 0 0 0 var(--spacer-small) !important;
      }
    }
  }
}