.color-answer {
  background: #fff;

  &__wrap {
    display: flex;

    .colors {
      &__label {
        margin-top: 0;
      }

      &__name {
        width: fit-content;
        color: #fff;
        background: var(--slider-line-color);

        font-family: var(--h-font);
        font-weight: 500;
        line-height: 155%;
        text-transform: uppercase;
      }
    }
  }

  &__list {
    display: grid;
  }

  &__item {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      border: 2px solid var(--slider-line-color);
      transition: opacity var(--default-transition);
      opacity: 0;
      pointer-events: none;
    }

    &._active {
      &::before {
        opacity: 1;
      }
    }

    input {
      display: none;
    }
  }

  &__color {
    pointer-events: auto;
  }
}

@include respond-up('large') {
  .color-answer {
    padding: 50px 54px 50px 50px;

    &_with-image {
      .color-answer {
        &__wrap {
          flex-direction: column;
          row-gap: 40px;

          .colors {
            &__label,
            &__display {
              width: 314px;
              height: 216px;
            }
          }
        }

        &__list {
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 18px;
        }

        &__color {
          width: 84px;
          height: 84px;
        }
      }
    }

    &__wrap {
      column-gap: 118px;

      .colors {
        &__label,
        &__display {
          width: 321px;
          height: 272px;
        }

        &__name {
          padding: 8px 16px;
          font-size: 15px;
        }
      }
    }

    &__list {
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 20px;
    }

    &__color {
      width: 126px;
      height: 126px;
    }

    &__item {
      &::before {
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
      }
    }
  }
}

@include respond-up('medium') {
  .color-answer {
    &__wrap {
      align-items: flex-start;
    }
  }
}

@include respond('medium') {
  .color-answer {
    padding: 22px;
    margin-top: 35px;

    &__wrap {
      column-gap: 45px;
    }

    &__list {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;
    }

    &__color {
      width: 90px;
      height: 90px;
    }
  }
}

@include respond-down('medium') {
  .color-answer {
    &__wrap {
      .colors {
        &__label,
        &__display {
          width: 213px;
          height: 140px;
        }

        &__name {
          padding: 6px 15px;
          font-size: 13px;
        }
      }
    }

    &__item {
      &::before {
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
      }
    }
  }
}

@include respond-down('small') {
  .color-answer {
    padding: 16px;
    margin-top: 20px;

    &__wrap {
      flex-direction: column;
      row-gap: 20px;
    }

    &__list {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 12px;
    }

    &__item,
    &__color {
      width: 63px;
      height: 63px;
    }
  }
}
