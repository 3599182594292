.print-wrapper {
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  height: 285mm;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  size: A4;
}

.header-print {
  justify-content: space-between;
  display: flex;
  padding-top: 10.6mm;
  padding-bottom: 14.5mm;

  &__logo-wrap {
    display: flex;
    flex: 0 0 29mm;
  }

  &__contact-icon {
    display: flex;
    margin-right: 2.8mm;
    margin-left: 6.2mm;
  }

  &__contacts {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__text {
    font-family: var(--font);
    font-size: 7.5pt;
    font-weight: 400;
  }
}
