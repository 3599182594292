.cover-input {
  position: relative;

  &__error {
    position: absolute;
    background: var(--status-error-bg-color);
    color: var(--status-error-text-color);
    line-height: 1.55;
    opacity: 0;
    transition: opacity var(--default-transition);
    pointer-events: none;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(7, 17, 40, 0.52);
  }

  &__nav {
    position: absolute;
  }
}

@include respond-up('large') {
  .cover-input {
    &__error {
      margin: 15px;
      padding: 15px;
    }
  }
}

@include respond-up('medium') {
  .cover-input {
    &__nav {
      top: 20px;
      right: 20px;
    }

    &__error {
      top: 0;
      left: 0;
      right: 0;
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .cover-input {
    &__error {
      margin: 10px;
      padding: 10px 15px 10px;
    }
  }
}

@include respond-down('small') {
  .cover-input {
    &__nav {
      top: 12px;
      right: 18px;
    }

    &__error {
      top: 80%;
      left: calc(100% - 50vw);
      padding: 8px 10px 7px;
      width: 180px;
      font-size: 12px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 35px;
        border: 6px solid var(--t);
        border-bottom: 5px solid var(--status-error-bg-color);
      }
    }
  }
}