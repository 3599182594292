.load-link {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    display: flex;
    margin-right: 8px;
  }

  &__text {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }
}