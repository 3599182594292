.profile-block {
  &__description {
    font-size: 15px;
  }

  &__fields {
    width: 100%;

    .add-field {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &._hide-remover {
      .add-field {
        &:first-child {
          .add-field {
            &__remover {
              display: none;
              pointer-events: none;
            }
          }
        }
      }
    }
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 14px 16px 15px;
    border: 1px solid var(--border-color);
    background-color: var(--t);
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--text-color-dark);

    svg {
      display: flex;
      margin-right: 9px;
    }
  }
}

@include respond-up('large') {
  .profile-block {
    &__main {
      margin: 80px 0;
      padding: 0 80/1675*100vw;
    }

    &__content {
      padding-left: 130px;
    }

    &__file-field {
      margin-top: 70px;
    }

    &__field-row {
      grid-gap: 65px;
    }
  }
}

@include respond-up('medium') {
  .profile-block {
    &__main {
      display: flex;
    }

    &__bar {
      flex: 0 0 214px;
    }

    &__description {
      margin-top: 10px;
    }

    &__content {
      flex: 1;
    }

    &__fields {
      .floating-field {
        &:not(:first-child) {
          margin-top: 30px;
        }

        textarea {
          height: 63px;
        }
      }
    }

    &__field-row {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .floating-field {
        margin-top: 30px;
      }
    }

    &__add {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .profile-block {
    &__main {
      margin: 50px 0;
    }

    &__content {
      padding-left: 32px;
    }

    &__file-field {
      margin-top: 50px;
    }

    &__field-row {
      grid-gap: 24px;
    }
  }
}

@include respond-down('small') {
  .profile-block {
    &__main {
      margin: 30px 0;
    }

    &__description {
      margin-top: 5px;
    }

    &__fields {
      margin-top: 16px;

      .floating-field {
        &:not(:first-child) {
          margin-top: 18px;
        }

        textarea {
          height: 55px;
        }
      }
    }

    &__field-row {
      .floating-field {
        margin-top: 18px;
      }
    }

    &__file-field {
      margin-top: 30px;
    }

    &__add {
      margin-top: 20px;
    }
  }
}