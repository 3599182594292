.vacancy-item {
    display: grid;
    grid-template-columns: 72px auto;
    grid-template-rows: 24px repeat(5, auto);
    align-items: start;
    align-content: start;
    grid-template-areas:
    "name name"
    "name name"
    "fee fee"
    "text-block text-block"
    "link link"
   ;
    &__avatar {
        grid-area: avatar;
    }

    &__photo {
        width: 100%;
        height: 72px;
        border-radius: 100px;
        background: {
            position: center;
            repeat: no-repeat;
            size: cover;
        };
    }

    &__char {
        width: 100%;
        height: 72px;
        background-color: var(--none-avatar-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 28px;
        line-height: 34/28*1em;
        text-transform: uppercase;
        color: white;
        border-radius: 100px;
    }

    &__fee {
        grid-area: fee;
        font-weight: 500;
        font-size: 12px;
        line-height: 10/12*1em;
        color: var(--text-color);
        font-family: var(--h-font);
    }

    &__name {
        grid-area: name;
        color: var(--text-color-dark);
        font-weight: 500;
        font-size: 15px;
        line-height: 1.75em;
        text-transform: uppercase;
        font-family: var(--h-font);
    }

    &__text {
        grid-area: text-block;
        em {
            font-style: italic;
        }
        strong {
            font-weight: bold;
        }
    }

    &__link{
        grid-area: link;
        max-width: fit-content;
    }

    &__original {
        grid-area: original;
    }

    &__project {
        grid-area: project;
        background-color: white;
        display: flex;
        padding: 8px;
        align-items: center;
        width: 251px;
        margin-top: 30px;
    }

    &__project-picture {
        flex: 0 0 35px;
        height: 35px;
        background: {
            position: center;
            size: cover;
            repeat: no-repeat;
            color: black;
        };
    }

    &__project-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__project-label {
        flex: 1 1 auto;
        text-align: center;
        font-family: var(--h-font);
        font-weight: 500;
        font-size: 11px;
        line-height: 1.33em;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--text-color-dark);
    }
}

@include respond-up('large') {
    .vacancy-item{
        padding: 80px var(--spacer-large);

        &__fee{
            margin-top: 20px;
            //padding-left: 35px;
        }

        &__name{
            //padding-left: 35px;
        }

        &__text{
            padding-top: 36px;
        }

        &__original{
            margin-top: 50px;
        }

        &__link{
            margin-top: 30px;
        }
    }
}

@include respond-up('medium'){
    .vacancy-item{
        height: 100%;
    }
}

@include respond('medium'){
    .vacancy-item{
        padding: 50px var(--spacer-medium);
    }
}

@include respond-down('medium'){
    .vacancy-item{
        &__fee{
            //padding-left: 25px;
            margin-top: 20px;
        }

        &__name{
            //padding-left: 25px;
        }

        &__text{
            padding-top: 30px;
        }

        &__original{
            margin-top: 40px;
        }

        &__link{
            margin-top: 20px;
        }
    }
}

@include respond('small'){
    .vacancy-item {
        padding: 30px var(--spacer-small);
    }
}

@include respond-down('x-small'){
    .vacancy-item {
        padding: 30px var(--spacer-smaller);
    }
}