.form-file-field {
  position: relative;

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    width: max-content;
    height: 0;

    input[type="file"] {
      padding: 10px 14px;
      color: transparent;
      //position: absolute;
      //top: 0;
      //left: 0;
      width: max-content;
      height: 100%;

      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;

      span {
        color: red;
      }

      &::-webkit-file-upload-button {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: max-content;
        height: 100%;
      }
    }
  }

  &__label {
    label {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: max-content;
      height: max-content;
      font-family: var(--h-font);
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 133%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      padding-left: 22px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 14px;
        height: 14px;
        transform: translate3d(0, -50%, 0);
        background: {
          image: url("../images/icons/clip.svg");
          position: center;
          size: contain;
          repeat: no-repeat;
        };
      }
    }
  }

  &__input-logo {
    opacity: 0;
    pointer-events: none;
  }

  &__remove {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  &._filled {
    padding-top: 35px;

    input[type="file"] {
      color: var(--text-color-dark);
    }

    .form-file-field {
      &__input {
        background-color: #fff;
      }

      &__input-logo,
      &__remove {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

@include respond-up('large') {
  .form-file-field {
    &._filled {
      .form-file-field {
        &__input {
          width: 150px;
          height: 56px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .form-file-field {

  }
}

@include respond('medium') {
  .form-file-field {

  }
}

@include respond-down('medium') {
  .form-file-field {
    &._filled {
      .form-file-field {
        &__input {
          width: 215px;
          height: 56px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .form-file-field {

  }
}