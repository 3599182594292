.text {
  color: var(--text-color);
  font-weight: 400;
  line-height: 1.75;

  &_dark {
    color: var(--text-color-dark);
  }

  &_light {
    color: var(--slide-text-color);
  }

  &_red {
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .text {
    font-size: 16px;

    &_content {
      max-width: var(--text-content-width);
    }

    &_biggest {
      font-size: 22px;
    }

    &_big {
      font-size: 19px;
    }

    &_medium {
      font-size: 17px;
    }

    &_small {
      font-size: 14px;
    }
  }
}

@include respond-down('medium') {
  .text {
    font-size: 15px;

    &_biggest {
      font-size: 19px;
    }

    &_big {
      font-size: 16px;
    }

    &_medium {
      font-size: 15px;
    }

    &_small {
      font-size: 13px;
    }
  }
}