.link {
  display: inline-block;
  position: relative;
  font-family: var(--h-font);
  font-weight: 500;
  font-size: 11px;
  line-height: 1.33;
  letter-spacing: 0.08em;
  color: #000;
  text-transform: uppercase;
  padding-bottom: 7px;

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    position: absolute;
    background-color: #000;
    bottom: 0;
    transform-origin: right;
    transition: transform .2s ease-out;
  }

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    position: absolute;
    background-color: #000;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform .2s ease-out;
  }

  &_white {
    color: #fff;

    &::before {
      background-color: #fff;
    }

    &::after {
      background-color: #fff;
    }
  }
}

@include respond-up('large') {
  .link {
    &:hover {
      &::after {
        transition: transform .3s var(--default-bezier) .33s;
        transform: scaleX(1);
      }

      &::before {
        transition: transform .3s var(--default-bezier);
        transform: scaleX(0);
      }
    }
  }
}