.animation-block {
  &.need-animation {
    .animation-block {
      &__text-wrap-inner {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }
  }

  &__text-wrap-inner {
    display: block;
    transition: {
      property: opacity, transform;
      duration: .9s;
      timing-function: var(--default-bezier);
    };

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: calc(((#{$i} - 1) * .2s));
      }
    }

    &_delay {
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          transition-delay: calc(((#{$i} - 1 + 0.9) * .2s));
        }
      }
    }
  }
}