.producer-projects {
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item {
    &:nth-child(5n + 1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-child(5n + 2) {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &:nth-child(5n + 3) {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &:nth-child(5n + 4) {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &:nth-child(5n + 5) {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}

@include respond-up('large') {
  .producer-projects {
    padding: 100px 0 0;

    &__title {
      width: 62.5vw;
    }

    &__items {
      grid-column-gap: calc(var(--spacer-large) * 2);
      align-items: end;
      margin-top: 69px;
    }

    &__item {
      margin-bottom: 137px;

      &:first-child {
        align-self: stretch;
        display: flex;
        align-items: flex-end;
        background: var(--gradient-to-top);
      }

      //&:nth-child(1), &:nth-child(6n) {
      //  width: calc((986/1675) * 100vw);
      //}

      &:nth-child(2) {
        margin-top: -290px;
      }

      //&:nth-child(2), &:nth-child(7n) {
      //  width: calc((429/1675) * 100vw);
      //}

      &:nth-child(5n + 3) {
        //width: calc((1082/1675) * 100vw);
        margin: 0 auto 139px;
      }
    }
  }
}

@include respond-up('medium') {
  .producer-projects {

  }
}

@include respond('medium') {
  .producer-projects {
    padding: 50px 0 0;

    &__items {
      grid-column-gap: calc(var(--spacer-medium));
      align-items: end;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    &__item {
      margin-bottom: 20px;
      align-self: stretch;
      display: flex;
      align-items: flex-end;
      /*background: var(--gradient-to-top);*/

      //&:nth-child(1), &:nth-child(6n) {
      //  width: calc((452/765) * 100vw);
      //}
      //
      //&:nth-child(2), &:nth-child(7n) {
      //  width: calc((196/765) * 100vw);
      //}

      &:nth-child(5n + 3) {
        //width: calc((505/765) * 100vw);
        margin: 0 auto 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .producer-projects {

  }
}

@include respond-down('small') {
  .producer-projects {
    padding: 40px 0 0;

    &__items {
      grid-column-gap: calc(var(--spacer-small) * 2);
      align-items: end;
      margin-top: 22px;
      margin-bottom: 20px;
    }

    &__item {
      grid-column-start: 1 !important;
      grid-column-end: 4 !important;
      margin-bottom: 40px;

      //&:nth-child(2n+1) {
      //  width: calc((320/375) * 100vw);
      //}

      &:nth-child(2n) {
        width: calc((245/375) * 100vw);
        margin: 0 auto 40px;
      }
    }
  }
}