.preloader{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  z-index: 999999;
  opacity: 1;
  pointer-events: none;
  transition: {
    delay: .2s;
    duration: .3s;
    property: opacity;
    timing-function: ease-in-out;
  };

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__inner{
    position: absolute;
    margin: auto;
    left: 50vw;
    top: 0;
    bottom: 0;
    transform: translateX(-125px);
    display: flex;
    z-index: 100;
    opacity: 1;
    width: 250px;
    height: 109px;
    transition: {
      property: opacity;
      duration: .2s;
      timing-function: ease-in-out;
    };
  }

  &__lines {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    align-self: end;
    height: 109px;
    position: relative;
    width: 100px;
    padding-left: 55px;
    animation: preloaderLinesScale forwards 0.5s 1.375s;
  }

  &__line {
    width: 40px;
    height: 0;

    &_first, &_second, &_third {
      position: relative;
      align-self: end;

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        transform: scaleY(0);
        animation: preloaderLinesToTropezoid forwards 0.3s 1.075s;
      }

      &::before {
        top: -6px;
        border: 5px solid transparent;
        border-right: 35px solid;
        border-bottom: 1px solid;
        transform-origin: bottom;
      }

      &::after {
        bottom: -6px;
        border: 5px solid transparent;
        border-top: 1px solid;
        border-right: 35px solid;
        transform-origin: top;
      }
    }

    &_first {
      background-color: #DF2C00;
      animation: preloaderLinesAppearance 0.375s 0.5s forwards,
      preloaderLinesFlash 1.6s ease-in-out 2.175s infinite;
     ;

      &::before {
        border-right-color: #DF2C00;
        border-bottom-color: #DF2C00;
      }

      &::after {
        border-top-color: #DF2C00;
        border-right-color: #DF2C00;
      }
    }

    &_second {
      background-color: #D06123;
      animation: preloaderLinesAppearance 0.375s 0.6s forwards,
      preloaderLinesFlash 1.6s ease-in-out 2.275s infinite;

      &::before {
        border-right-color: #D06123;
        border-bottom-color: #D06123;
      }

      &::after {
        border-top-color: #D06123;
        border-right-color: #D06123;
      }
    }

    &_third {
      background-color: #FFDE3A;
      animation: preloaderLinesAppearance 0.375s 0.7s forwards,
      preloaderLinesFlash 1.6s ease-in-out 2.375s infinite;

      &::before {
        border-right-color: #FFDE3A;
        border-bottom-color: #FFDE3A;
      }

      &::after {
        border-top-color: #FFDE3A;
        border-right-color: #FFDE3A;
      }
    }
  }

  &__logo-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &._active {

    }

    svg{
      width: 249px;
      height: 34px;
      transform: translateX(20px);
      overflow: hidden;

      path {
        transform: translateX(-105%);
        animation: preloaderSVGtranstate 0.7s 1.475s forwards;
      }
    }
  }

  &._hide{
    overflow: hidden;
    transition: opacity 0.6s cubic-bezier(0.49, 0.19, 0.12, 0.73);
    opacity: 0;

    .preloader__container {
      transition: opacity 0.6s cubic-bezier(0.49, 0.19, 0.12, 0.73);
      opacity: 0;
    }
  }
}

@keyframes preloaderLinesAppearance {
  0%{
    height: 0;
  }

  100% {
    height: 109px;
  }
}

@keyframes preloaderLinesToTropezoid {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes preloaderLinesScale {
  100% {
    transform: translateX(-75px) scale(0.28);
  }
}

@keyframes preloaderSVGtranstate {
  100% {
    transform: translateX(0);
    align-self: center;
  }
}

@keyframes preloaderLinesFlash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
