.back-link {
  &__main {
    display: flex;
    align-items: center;
  }

  &__icon {

  }

  &__name {
    margin-left: 9px;
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 11px;
    line-height: 1.33em;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--text-color-dark);
  }
}