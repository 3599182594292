.article-video-item {
    &__video {
        position: relative;
        display: flex;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #04112F80;
        }
    }

    &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}

@include respond-up('large') {
    .article-video-item {
        &__video {
            height: calc((496/1675 * 100vw) * 280/496);
        }
    }
}

@include respond('medium') {
    .article-video-item {
        &__video {
            height: calc((312/768 * 100vw) * 176/312);
        }
    }
}

@include respond-down('small') {
    .article-video-item {
        &__video {
            height: calc((320/375 * 100vw) * 180/320);
        }
    }
}