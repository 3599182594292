.content-header {
  background-color: var(--background-color);

  &_no-bg {
    background-color: unset;
  }

  &_small {
    width: 63%;
  }
}

@include respond-up('large') {
  .content-header {
    &_trimmed {
      display: inline-block;
      min-width: 50vw;
    }

    &_indent {
      padding-bottom: 77px;
    }

    &__heading {
      padding-top: 100px;
    }
  }
}

@include respond('medium') {
  .content-header {
    &_indent {
      padding-bottom: 30px;
    }

    &__heading {
      padding-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .content-header {
    &__breadcrumbs {
      padding-top: 30px;
    }
  }
}

@include respond-down('small') {
  .content-header {
    &_indent {
      padding-bottom: 30px;
    }

    &__breadcrumbs {
      padding-top: 25px;
    }

    &__heading {
      padding-top: 30px;
    }
  }
}

@media print{
  .content-header {
    display: none;
  }
}
