.youtube-video-block {
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-top: 9/16*100%;
  }

  &__youtube-framed {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .youtube-video-block {

  }
}

@include respond-down('medium') {
  .youtube-video-block {

  }
}

