.image-answer {
  &__image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  label {
    border: unset;

    &.image-answer__image-wrap {
      padding: 0;

      &::before {
        content: unset;
      }
    }
  }
}

@include respond-up('large') {
  .image-answer {
    &__picture {
      height: calc(430/945 * 100vh);
    }
  }
}

@include respond-up('medium') {
  .image-answer {

  }
}

@include respond('medium') {
  .image-answer {
    &__picture {
      height: calc((344/768 * 100vw) * 194/344);
    }
  }
}

@include respond-down('medium') {
  .image-answer {

  }
}

@include respond-down('small') {
  .image-answer {
    &__picture {
      height: calc((335/375 * 100vw) * 189/335);
    }
  }
}