.contacts {
  &__schema-name {
    display: none;
  }
  &__top {
    display: flex;
  }

  &__right-block {
    display: flex;
  }

  &__info-name {
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.75;
    text-transform: uppercase;
  }
}

@include respond-up('large') {
  .contacts {
    padding-top: 75px;

    &__top {
      border-top: 1px solid var(--border-color);
      justify-content: flex-end;
    }

    &__left-block {
      flex: 0 0 calc(100%/3);
      padding: 80px 80px 80px var(--spacer-large);
    }

    &__left-block-text {
      font-size: 22px;
      margin-bottom: 35px;
    }

    &__right-block {
      flex: 0 0 calc(100%*2/3);
      border-left: 1px solid var(--border-color);
      padding: 80px 0 125px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__info-name {
      padding-bottom: 20px;
    }

    &__info-text {
      padding-bottom: 75px;
      padding-right: 133/1675*100vw;
    }

    &__socials {
      align-items: flex-start;
    }
  }
}

@include respond-up('medium') {
  .contacts {
    &__info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'arrdess worktime'
                           'phone email';
    }

    &__info-block {
      display: flex;
      flex-direction: column;

      &:nth-of-type(1) {
        grid-area: arrdess;
      }

      &:nth-of-type(2) {
        grid-area: phone;
      }

      &:nth-of-type(3) {
        grid-area: worktime;
      }

      &:nth-of-type(4) {
        grid-area: email;
      }
    }
  }
}

@include respond('medium') {
  .contacts {

    &__top {
      padding-top: 30px;
    }

    &__left-block {
      padding: 30px var(--spacer-medium);
      align-items: flex-start;
    }

    &__left-block-text {
      font-size: 18px;
      width: calc((344 / 768) * 100vw);
      margin-right: calc((82 / 768) * 100vw);
    }

    &__info-text {
      margin-bottom: 60px;
      padding-right: 67/768*100vw;
    }

    &__right-block {
      padding: 50px var(--spacer-medium);
    }
  }
}

@include respond-down('medium') {
  .contacts {
    &__top {
      flex-direction: column-reverse;
    }

    &__left-block {
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      display: flex;
    }

    &__button {
      white-space: nowrap;
    }

    &__right-block {
      flex-direction: column;
      border-top: 1px solid var(--border-color);
    }

    &__info-name {
      padding-bottom: 18px;
    }
  }
}

@include respond-down('small') {
  .contacts {
    &__top {
      padding-top: 20px;
    }

    &__left-block {
      padding: 30px var(--spacer-small);
      flex-direction: column;
      align-items: flex-start;
    }

    &__left-block-text {
      font-size: 16px;
      width: 100%;
      //max-width: 236px;
      margin-bottom: 20px;
    }

    &__info-text {
      margin-bottom: 30px;
    }

    &__right-block {
      padding: 30px var(--spacer-small) 40px;
    }
  }
}