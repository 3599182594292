.product-property {
  &__body {
    background-color: #fff;
    display: flex;
  }

  &__item {
    display: grid;
    align-items: center;
    grid-template-areas: 'icon name'
                         'void description';
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 1.85;
    text-transform: uppercase;
  }

  &__description {
    grid-area: description;
  }
}

@include respond-up('large') {
  .product-property {
    padding-bottom: 100px;

    &__head {
      padding: 0 80/1675*100vw 100px;
      width: 753/1675*100vw;
      box-sizing: content-box;
    }

    &__body {
      margin-left: 80/1675*100vw;
      padding-left: 56/1675*100vw;
    }

    &__items {
      padding: 56/1675*100vw 0;
    }

    &__item {
      &:not(:last-of-type) {
        padding-bottom: 68px;
      }
    }

    &__name {
      font-size: 15px;
    }

    &__description {
      padding-top: 11px;
    }

    &__image-wrapper {
      flex: 0 0 430/1675*100vw;
      margin-left: 82/1675*100vw;
    }

    &__container {
      padding: 0;
    }
  }
}

@include respond-up('medium') {
  .product-property {
    &__item {
      grid-template-columns: 85px 1fr;
    }

    &__body {
      justify-content: space-between;
    }

    &__image-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include respond('medium') {
  .product-property {
    padding-bottom: 60px;

    &__head {
      padding-bottom: 60px;
    }

    &__body {
      padding-left: 28/768*100vw;
    }

    &__items {
      padding: 28/768*100vw 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__item {
      &:not(:last-of-type) {
        padding-bottom: 41px;
      }
    }

    &__image-wrapper {
      flex: 0 0 304/768*100vw;
      margin-left: 39/768*100vw;
    }
  }
}

@include respond-down('medium') {
  .product-property {
    &__head {
      font-size: 16px;
    }

    &__name {
      font-size: 14px;
    }

    &__description {
      padding-top: 7px;
    }
  }
}

@include respond-down('small') {
  .product-property {
    padding-bottom: 50px;

    &__head {
      padding: 0 var(--spacer-small) 96px;
    }

    &__body {
      flex-direction: column;
      align-items: center;
      padding: 29px 30/375*100vw 0 ;
    }

    &__item {
      grid-template-columns: 71px 1fr;

      &:not(:last-of-type) {
        padding-bottom: 31px;
      }

      &:last-of-type {
        padding-bottom: 50px;
      }
    }
  }
}