.used-projects {
  &__title {
    background-color: var(--background-color);
  }

  &__list {
    border-top: none;

    .projects__list-item {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@include respond-up('large') {
  .used-projects {
    &__title {
      padding-top: 100px;
      padding-bottom: 69px;
      max-width: 1000/1675*100vw;
    }
  }
}

@include respond('medium') {
  .used-projects {
    &__title {
      padding-top: 70px;
      padding-bottom: 40px;
      max-width: 470/768*100vw;
    }

    &__list {
      .projects__list {
        margin-top: 0;
      }
    }
  }
}

@include respond-down('small') {
  .used-projects {
    &__title {
      padding-top: 40px;
      padding-bottom: 22px;
    }

    &__list {
      padding: 0;
      margin: 0;

      .projects__list {
        margin: 0;

      }
    }
  }
}