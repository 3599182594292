.term-link {
  position: relative;
  border-bottom: 1px solid #b3b3b4;
}

@include respond-up('large') {
  .term-link {
    &:hover {
      .term-link {
        &__pop {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &__pop {
      display: block;
      position: absolute;
      top: calc(100% + 10px);
      left: 10px;
      padding: 12px 18px;
      background-color: #fff;
      opacity: 0;
      pointer-events: none;
      transform: translateY(20px);
      transition: opacity var(--default-transition), transform var(--default-transition);
      width: 320px;
      line-height: 1.1em;
      z-index: 10;
      box-shadow: 1px 1px 40px -20px rgba(0, 0, 0, 0.2);
    }

    &__name {
      font-family: var(--h-font);
      font-weight: 500;
      font-size: 12px;
      color: var(--text-color-dark);
    }

    &__text {
      font-size: 12px;
      color: var(--text-color);
    }
  }
}

@include respond-down('medium') {
  .term-link {
    &__pop {
      display: none;
    }
  }
}