.partner {
  &__cover {
    position: relative;
    background: {
      size: cover;
    };
  }

  &__avatar {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    overflow: hidden;
  }

  &__avatar-image {
    height: 100%;
    width: 100%;
  }

  &__avatar-picture {
    display: flex;
  }

  &__char {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 34px;
    font-size: 28px;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: var(--none-avatar-color);
  }

  &__filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(7, 17, 40, 0.52);
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &__data {
    display: grid;
  }

  &__specialization {
    white-space: nowrap;
    line-height: 175%;
    color: var(--text-color-dark);
  }

  &__phone {
    white-space: nowrap;
  }

  &__city {
    white-space: nowrap;
  }

  &__phone, &__city {
    display: flex;
    align-items: center;

    svg {
      margin-right: 14px;
    }
  }

  &__body {
    display: flex;
    border-top: 1px solid var(--border-color);
  }

  &__content {
    background-color: var(--background-color);
  }

  &__certificates {
    display: flex;
    overflow: hidden;

    &::after {
      content: '';
    }
  }

  &__certificate {
    cursor: pointer;
  }

  &__certificate-link {
    display: block;
  }

  &__sertificate-picture {
    display: flex;
  }

  &__certificate-link, &__certificate-picture, &__sertificate-image {
    user-select: none;
    -webkit-user-drag: none;
  }

  &__bottom {
    border-top: 1px solid var(--border-color);

    .projects-item__description {
      display: none;
    }
  }
}

@include respond-up('large') {
  .partner {
    &__head {
      padding-top: 60px;
    }

    &__cover {
      height: 400/1675*100vw;
    }

    &__name {
      bottom: 35px;
      left: 287/1675*100vw;
    }

    &__avatar {
      border: 8/1675*100vw solid var(--background-color);
      width: 164/1675*100vw;
      height: 164/1675*100vw;
      bottom: -82/1675*100vw;
      left: 70/1675*100vw;
    }

    &__data {
      grid-template-columns: 1fr min-content min-content 234px;
      padding: 37px 0 84px;
    }

    &__specialization {
      margin-left: 287/1675*100vw;
      font-size: 23px;
    }

    &__phone {
      margin-right: calc((100vw - 1200px)/(1675 - 1200) * (50 - 20) + 20px);
    }

    &__city {
      margin-right: calc((100vw - 1200px)/(1675 - 1200) * (80 - 30) + 30px);
    }

    &__body {

    }

    &__sticky-text {
      font-size: 22px;
      max-width: 300px;
    }

    &__bar {
      flex: 0 0 calc(100% / 3 + 1px);
    }

    &__sticky {
      position: sticky;
      top: 80px;
      left: 0;
      padding: 80px var(--spacer-large) 145px;
    }

    &__sticky-text {
      padding-bottom: 32px;
    }

    &__content {
      padding: 80px var(--spacer-large) 145px 80/1675*100vw;
    }

     &__description {
       padding-bottom: 10px;
     }

    &__title {
      padding-top: 60px;
    }

    &__post {
      margin-top: 28px;
      width: var(--text-content-width);
    }

    &__certificates {
      margin-top: 80px;
      margin-right: calc(0px - var(--spacer-large));

      &::after {
        flex: 0 0 var(--spacer-large);
        height: 10px;
      }
    }

    &__certificate {
      flex: 0 0 208px;
      height: 295px;


      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    &__services {
      margin-top: 28px;
    }

    &__bottom {
      padding: 0 var(--spacer-large);
      padding-bottom: 145px;
    }
  }
}

@include respond-up('medium') {
  .partner {
    &__name {
      position: absolute;
      z-index: 1;
      color: #fff;

      &_mobile {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .partner {
    &__head {
      padding-top: 45px;
    }

    &__cover {
      height: 184/768*100vw;
    }

    &__name {
      bottom: 19px;
      left: 159/768*100vw;
    }

    &__avatar {
      border: 6/768*100vw solid var(--background-color);
      width: 109/768*100vw;
      height: 109/768*100vw;
      bottom: -55/768*100vw;
      left: 30/768*100vw;
    }

    &__data {
      grid-template-columns: 226px 1fr 234px;
      grid-template-areas: 'specialization specialization specialization'
      'phone city share';
      padding: 19px 0 50px;
    }

    &__specialization {
      margin-left: 159/768*100vw;
      padding-bottom: 29/768*100vw;
      grid-area: specialization;
    }

    &__sticky {
      padding: 30px var(--spacer-medium);
      align-items: center;
    }

    &__sticky-text {
      font-size: 18px;
      margin-right: 45/768*100vw;
    }

    &__content {
      padding: 50px var(--spacer-medium);
    }

    &__post {
      margin-top: 22px;
    }

    &__certificates {
      margin-right: calc(0px - var(--spacer-medium));

      &::after {
        flex: 0 0 var(--spacer-medium);
        height: 10px;
      }
    }

    &__certificate {
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    &__services {
      margin-top: 22px;
    }

    &__bottom {
      padding: 0 var(--spacer-medium);
      padding-bottom: 50px;
    }
  }
}

@include respond-down('medium') {
  .partner {
    &__specialization {
      font-size: 18px;
    }

    &__body {
      flex-direction: column-reverse;
    }

    &__sticky {
      display: flex;
      border-top: 1px solid var(--border-color);
      background-color: var(--background-color);
    }

    &__description {
      padding-bottom: 40px;
    }

    &__post {
      margin-bottom: 40px;
    }

    &__services {
      margin-bottom: 40px;
    }

    &__certificate {
      flex: 0 0 176px;
    }
  }
}

@include respond-down('small') {
  .partner {
    &__head {
      padding-top: 36px;
    }

    &__cover {
      height: 104/375*100vw;
    }

    &__name {
      padding-top: 73px;
      padding-bottom: 4px;

      &_desktop {
        display: none;
      }
    }

    &__avatar {
      border: 6px solid var(--background-color);
      width: 109px;
      height: 109px;
      bottom: -55px;
      left: 25px;
    }

    &__data {
      padding-bottom: 30px;
    }

    &__specialization, &__city {
      padding-bottom: 25px;
    }

    &__phone {
      padding-bottom: 8px;
    }

    &__sticky {
      flex-direction: column;
      padding: 30px var(--spacer-small);
      align-items: flex-start;
    }

    &__sticky-text {
      font-size: 16px;
      padding-bottom: 20px;
    }

    &__content {
      padding: 40px var(--spacer-small);
    }

    &__post {
      margin-top: 20px;
    }

    &__certificates {
      margin-right: calc(0px - var(--spacer-small));

      &::after {
        flex: 0 0 var(--spacer-small);
        height: 10px;
      }
    }

    &__certificate {
      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }

    &__services {
      margin-top: 20px;
    }

    &__bottom {
      padding: 0 var(--spacer-small);
      padding-bottom: 30px;
    }
  }
}