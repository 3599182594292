.catalog {
  &__title-container {
    display: inline-block;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__list-item {
    &:nth-child(1), &:nth-child(6n) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-child(2), &:nth-child(7n) {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &:nth-child(3), &:nth-child(8n) {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &:nth-child(4), &:nth-child(9n) {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &:nth-child(5), &:nth-child(10n) {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }

  &__big-description {
    border-top: 1px solid var(--border-color);
    background-color: var(--background-color);
  }

  &__big-text {
    position: relative;
    margin-top: 0;
    overflow: hidden;

    h1, h2, h3, h4, h5, h6 {
      &:first-child {
        margin-top: 0;
      }
    }

    &_show {
      height: 100%!important;

      &::before {
        display: none;
      }

      & + a {
        display: none;
      }
    }
  }
}

@include respond-up('large') {
  .catalog {
    &__title-container {
      background-color: var(--background-color);
      padding-bottom: 102px;
      width: 50vw;
    }

    &__description {
      display: none;
    }

    &__list {
      grid-column-gap: calc((130/1675) * 100vw);
      align-items: end;
    }

    &__list-item {
      margin-bottom: 144px;

      &_description {
        height: 100%;
      }

      &:nth-child(1) {
        align-self: stretch;
        display: flex;
        align-items: flex-end;
        background: var(--gradient-to-top);
      }

      &:nth-child(1), &:nth-child(6n) {
        width: calc((986/1675) * 100vw);
      }

      &:nth-child(2) {
        margin-top: -290px;

        &_description {
          margin-top: -140px;
          margin-bottom: 0;
        }
      }

      &:nth-child(2), &:nth-child(7n) {
        width: calc((429/1675) * 100vw);
      }

      &:nth-child(3), &:nth-child(8n) {
        width: calc((1082/1675) * 100vw);
        margin: 0 auto 144px;
      }
    }

    &__big-description {
      padding: 120px 215px;
    }

    &__toggle-link {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .catalog {
    &__title-container {
      background-color: var(--background-color);
      padding-bottom: 50px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__description {
      font-size: 15px;
    }

    &__list-wrapper {
      margin: 0 calc(var(--spacer-medium) * -1);
      padding: 0 var(--spacer-medium);
      border-top: 1px solid var(--border-color);
    }

    &__list {
      grid-column-gap: calc((130 / 1675) * 100vw);
      align-items: end;
      padding-top: 59px;
      margin-bottom: 20px;
    }

    &__list-item {
      margin-bottom: 70px;
      align-self: stretch;
      display: flex;
      align-items: flex-end;

      &:nth-child(1) {
        align-self: stretch;
        display: flex;
        align-items: flex-end;
        background: var(--gradient-to-top);
      }

      &:nth-child(1), &:nth-child(6n) {
        width: calc((452 / 768) * 100vw);
      }

      &:nth-child(2), &:nth-child(7n) {
        width: calc((196 / 768) * 100vw);
      }

      &:nth-child(3), &:nth-child(8n) {
        width: calc((505 / 768) * 100vw);
        margin: 0 auto 70px;
      }
    }

    &__big-description {
      padding: 50px var(--spacer-medium);
    }
  }
}

@include respond-down('medium') {
  .catalog {
    &__list-item {
      &_description {
        display: none;
      }
    }

    &__toggle-link {
      margin-top: 15px;
    }
  }
}


@include respond-down('small') {
  .catalog {
    &__title-container {
      background-color: var(--background-color);
      padding-bottom: 40px;
    }

    &__title {
      margin-bottom: 25px;
    }

    &__description {
      font-size: 15px;
    }

    &__list {
      grid-column-gap: calc((130 / 1675) * 100vw);
      align-items: end;
      margin-bottom: 20px;
      grid-template-columns: 1fr;
    }

    &__list-item {
      grid-column-start: 1 !important;
      grid-column-end: 4 !important;
      margin-bottom: 40px;

      &:nth-child(2n+1) {
        width: calc((320 / 375) * 100vw);
      }

      &:nth-child(2n) {
        width: calc((245 / 375) * 100vw);
        margin: 0 auto 40px;
      }
    }

    &__big-description {
      padding: 30px var(--spacer-small) 40px;
    }
  }
}