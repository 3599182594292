.links-block {
  overflow: hidden;
  &__list {
    display: flex;
  }

  &__link {
    position: relative;
    display: flex;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(7, 17, 40, 0.4);
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 5;
    color: #fff;
    font-family: var(--h-font);
    font-weight: 500;
    line-height: 155%;
    text-transform: uppercase;
  }
}

@include respond-up('large') {
  .links-block {
    &__list {
      column-gap: 4px;
    }

    &__picture {
      width: calc(835/1675*100vw);
      height: calc((835/1675*100vw) * 420/835);
    }

    &__title {
      font-size: 40px;
    }

    &__image {
      transition: transform .8s ease-in-out;
    }

    &__link {
      &:hover {
        .links-block {
          &__image {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .links-block {
    &__picture {
      width: calc(384/768*100vw);
      height: calc((384/768*100vw) * 193/384);
    }

    &__title {
      font-size: 22px;
    }
  }
}

@include respond-down('medium') {
  .links-block {
    &__list {
      gap: 2px;
    }
  }
}

@include respond-down('small') {
  .links-block {
    &__list {
      flex-direction: column;
    }

    &__picture {
      width: 100vw;
      height: calc(100vw * 188/375);
    }

    &__title {
      font-size: 20px;
    }
  }
}