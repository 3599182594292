.product-view {
  display: flex;
  flex-direction: column;

  &__menu {
    border: none;
  }

  &__list {
    background-color: #fff;
    display: grid;
    grid-template-areas: 'one';
    position: relative;
  }

  &__item {
    grid-area: one;
    position: absolute;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity .5s, transform .5s;

    &._active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .5s .3s, transform .5s .3s;
      z-index: 1;
    }
  }
}

@include respond-up('large') {
  .product-view {
    padding-left: 80/1675*100vw;

    &__menu {
      margin-top: 39px;
      padding-bottom: 32px;
    }

    &__list {
      min-height: 304px;
    }

    &__item {
      padding: 44px 0 47px 60/1675*100vw;
    }
  }
}

@include respond-up('medium') {
  .product-view {
    &__head {
      overflow: hidden;
    }
  }
}

@include respond('medium') {
  .product-view {
    &__menu {
      padding-bottom: 40px;
    }

    &__list {
      margin-right: calc(0px - var(--spacer-medium));
    }

    &__item {
      padding: 44px 0 49px 28/768*100vw;
    }
  }
}

@include respond-down('small') {
  .product-view {
    padding: 0 var(--spacer-small);
  }
}