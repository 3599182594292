.quiz-form {
  &__title {
    color: var(--button-color);
  }

  &__text {
    margin-top: 18px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
  }

  &__send-to {
    position: relative;
    label.required.label {
      display: none;
    }

    .radio-list {
      display: grid;

      input[type="radio"] {
        &:checked + label {
          border-color: var(--text-color-dark);

          &::before {
            background-color: unset;
          }
        }
      }

      #BidForm_send_to-10 + label {
        &::before {
          background-image: url('../svg/telegram.svg');
        }
      }

      #BidForm_send_to-20 + label {
        &::before {
          background-image: url('../svg/whatsapp.svg');
        }
      }

      #BidForm_send_to-30 + label {
        &::before {
          background-image: url('../svg/viber.svg');
        }
      }

      #BidForm_send_to-40 + label {
        &::before {
          background-image: url('../svg/email.svg');
        }
      }

      label {
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid var(--border-color);
        transition: border-color var(--default-transition);

        &::before {
          background-size: 24px 24px;
          background-repeat: no-repeat;
          background-position: center;
          width: 24px;
          height: 24px;
          border: unset;
          border-radius: unset;
          position: static;
          padding: 0;
        }
      }
    }
  }

  &__answers-data {
    display: none;
  }

  &__bottom-wrap {
    display: grid;
  }
}

@include respond-up('large') {
  .quiz-form {
    &__form {
      display: grid;
      grid-template-columns: repeat(16, 1fr);
      grid-gap: 20px;
    }

    &__left-wrap {
      grid-column: 2/8;
    }

    &__send-to-wrap {
      margin-top: calc(60/945 * 100vh);
    }

    &__send-to {
      margin-top: calc(25/945 * 100vh);

      .radio-list {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 16px;

        label {
          padding: 14px 16px;
        }
      }
    }

    &__fields-wrap {
      grid-column: 10/16;
    }

    &__fields {
      row-gap: calc(50/945 * 100vh);
    }

    &__button {
      &.button {
        margin-top: calc(75/945 * 100vh);
      }
    }

    &__policy {
      margin-top: calc(30/945 * 100vh);
    }

    &__bottom-wrap {
      grid-template-columns: repeat(16, 1fr);
      grid-column-gap: 20px;
    }

    &__progress-bar-wrap {
      grid-column: 1/14;
    }
  }
}

@include respond-up('medium') {
  .quiz-form {
    &__send-to {
      .radio-list {
        label {
          column-gap: 16px;
        }
      }
    }
  }
}

@include respond('medium') {
  .quiz-form {
    &__fields {
      row-gap: 28px;
    }

    &__send-to-wrap {
      margin-top: 40px;
    }

    &__send-to {
      margin-top: 20px;

      .radio-list {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 11px;

        label {
          padding: 10px 16px;
        }
      }
    }

    &__button {
      &.button {
        margin-top: 40px;
      }
    }

    &__policy {
      margin-top: 18px;
    }

    &__bottom-wrap {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 22px;
    }

    &__progress-bar-wrap {
      grid-column: 1/8;
    }
  }
}

@include respond-down('small') {
  .quiz-form {
    &__fields {
      row-gap: 12px;
    }

    &__send-to-wrap {
      margin-top: 20px;
    }

    &__send-to {
      margin-top: 15px;

      .radio-list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;

        label {
          column-gap: 12px;
          padding: 10px;
        }
      }
    }

    &__button {
      &.button {
        margin-top: 30px;
      }
    }

    &__policy {
      margin-top: 20px;
    }

    &__progress-bar-wrap {
      width: 135px;
    }
  }
}